import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { compose } from 'core/libs/recompose';

import withoutSSR from 'core/components/withoutSSR';
import withTheme from 'core/components/theme';
import bindProps from 'core/components/bindProps';
import Button from 'core/components/Button';
import { notification } from 'core/components/Notification';

import withBatId from 'site/components/BatId/withBatId';
import BaseForm from 'site/components/BaseForm';

import Close from 'site/icons/Close.svg';

import {
  emailValidation,
  firstNameValidation,
  termsAndConditionsValidation,
} from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';
import batIdPropTypes from 'site/utils/prop-types/batId';

import { METRICS } from 'site/constants';
import {
  subscriptionIsVerified,
  setSubscriptionIsHiddenByUser,
  subscriptionIsHiddenByUser,
} from 'site/utils/subscription';
import { setMetric } from 'site/utils';

import SubscriptionForm from './Form';
import styles from './index.styl';

const SubscriptionFormForNotification = bindProps({ isInsideNotification: true })(SubscriptionForm);

const validate = {
  email: emailValidation,
  first_name: firstNameValidation,
  terms_and_conditions: termsAndConditionsValidation,
};

const initialValues = {
  email: '',
  first_name: '',
  terms_and_conditions: false,
};

const Subscription = (props) => {
  const {
    theme,
    batId,
    isInsideNotification,
    refs,
    handleSuccessSubmit,
  } = props;
  const [errorName, setErrorName] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [hiddenByUser, setHiddenByUser] = useState(subscriptionIsHiddenByUser() || subscriptionIsVerified());
  const [hideForm, setHideForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [heightToZero, setHeightToZero] = useState(false);

  if (hiddenByUser) return null;

  const handleHideSubscription = () => {
    // запускаем анимацию схлопывания формы
    setHeightToZero(true);
    setSubscriptionIsHiddenByUser();
    setMetric(METRICS.subscribe_popup_closed);
    setTimeout(() => {
      // полностью скрываем после завершения анимации схлопывания
      setHiddenByUser(true);
    }, 400);
  };

  const submitCallback = values => {
    return batId.createSubscription(values)
      .then(() => {
        // скрываем форму
        setHideForm(true);
        setTimeout(() => {
          // через 300мс после скрытия формы показываем сообщение, что подписка активирована
          setMetric(isInsideNotification ? METRICS.subscribe_form_submit : METRICS.inpagesubscribe_form_submit);
          setSuccess(true);
          if (refs.length) {
            refs.forEach(ref => ref.current.remove());
          }
        }, 500);
        handleSuccessSubmit && handleSuccessSubmit();
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        setErrorName(errors.error);
        setFieldsErrors(errors.fieldsErrors);
      });
  };

  return (
    <div className={cx(heightToZero && styles.heightToZero)}>
      <div className={cx(styles.container, isInsideNotification && styles.notificationContainer)}>
        <style jsx>{`
          .${styles.container}
            border-top 1px solid ${theme.colors.grey1}
            border-bottom 1px solid ${theme.colors.grey1}
            font-family ${theme.fonts.display}
            &.${styles.notificationContainer}
              border none

          .${styles.close}
            color ${theme.colors.grey2}

            &:hover
              color ${theme.colors.primary}
        `}</style>
        <div className={cx(styles.hiddenMsg, success && styles.show, isInsideNotification && styles.insideNotification)}>
          {!isInsideNotification && <div className={styles.hiddenMsgTitle}>Проверьте вашу почту!</div>}
          <div>Мы выслали на вашу почту письмо со ссылкой на подтверждение адреса</div>
          {isInsideNotification && success && (
            <Button
              onClick={() => notification.dismiss()}
              className={styles.dismissBtn}
            >
              Отлично
            </Button>
          )}
        </div>
        <div className={cx(hideForm && styles.hide)}>
          {!isInsideNotification && (
            <div className={styles.title}>
              Подпишись, чтобы получать самое интересное от YourChoice
              <div className={styles.close} onClick={handleHideSubscription}>
                <Close />
              </div>
            </div>
          )}
          <BaseForm
            form={isInsideNotification ? SubscriptionFormForNotification : SubscriptionForm}
            submitCallback={submitCallback}
            initialValues={initialValues}
            validate={validate}
            serverErrorName={errorName}
            serverFieldsErrors={fieldsErrors}
          />
        </div>
      </div>
    </div>
  );
};

Subscription.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  batId: batIdPropTypes,
  isInsideNotification: PropTypes.bool,
  refs: PropTypes.arrayOf(PropTypes.object),
  /**
   * Обработчик, с помощью которого можно выполнять функцию после успешного submit
   */
  handleSuccessSubmit: PropTypes.func,
};

const SubscriptionWithHOCs = compose(
  withoutSSR,
  withTheme,
  withBatId,
)(Subscription);

SubscriptionWithHOCs.displayName = 'Subscription';

export default SubscriptionWithHOCs;
export { Subscription as StorybookComponent };
