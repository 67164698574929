export default {
  mainTitle: (theme) => {
    return {
      font: `700 34px/1.15 ${theme.fonts.display}`,
      mobile: {
        font: `700 26px/1.15 ${theme.fonts.display}`,
      },
    };
  },
  cardTitle: (theme) => {
    return {
      font: `700 18px/1.2 ${theme.fonts.display}`,
      mobile: {
        font: `700 18px/1.2 ${theme.fonts.display}`,
      },
    };
  },
  cardTitleItalic: (theme) => {
    return {
      font: `700 italic 18px/1.2 ${theme.fonts.text}`,
      mobile: {
        font: `700 italic 18px/1.2 ${theme.fonts.text}`,
      },
    };
  },
  fullScreenPopupTitle: (theme) => {
    return {
      font: `500 21px/1.1 ${theme.fonts.display}`,
      mobile: {
        font: `500 21px/1.1 ${theme.fonts.display}`,
      },
    };
  },
  fullScreenPopupDescription: (theme) => {
    return {
      font: `500 12px/1.6 ${theme.fonts.display}`,
      mobile: {
        font: `500 12px/1.6 ${theme.fonts.display}`,
      },
    };
  },
  rubricName: (theme) => {
    return {
      font: `600 10px/1.2 ${theme.fonts.display}`,
      mobile: {
        font: `600 10px/1.2 ${theme.fonts.display}`,
      },
    };
  },
  lead: (theme) => {
    return {
      font: `500 17px/1.35 ${theme.fonts.display}`,
      mobile: {
        font: `500 17px/1.35 ${theme.fonts.display}`,
      },
    };
  },
  description: (theme) => {
    return {
      font: `500 12px/1.6 ${theme.fonts.display}`,
      mobile: {
        font: `500 12px/1.6 ${theme.fonts.display}`,
      },
    };
  },
  readMoreCardTitle: (theme) => {
    return {
      font: `700 15px/1.3 ${theme.fonts.display}`,
      mobile: {
        font: `700 15px/1.3 ${theme.fonts.display}`,
      },
    };
  },
  fieldName: (theme) => {
    return {
      font: `500 15px/1.3 ${theme.fonts.display}`,
    };
  },
  linkSmall: (theme) => {
    return {
      font: `500 13px/1.4 ${theme.fonts.display}`,
    };
  },
  linkMedium: (theme) => {
    return {
      font: `500 15px/1.4 ${theme.fonts.display}`,
    };
  },
  errorMessage: (theme) => {
    return {
      font: `500 15px/1.4 ${theme.fonts.display}`,
      mobile: {
        font: `500 13px/1.4 ${theme.fonts.display}`,
      },
    };
  },
  errorMessageSmall: (theme) => {
    return {
      font: `500 11px/1.4 ${theme.fonts.display}`,
    };
  },
  errorNumber: (theme) => {
    return {
      font: `600 72px/1.15 ${theme.fonts.display}`,
    };
  },
  ageWallTitle: theme => {
    return {
      font: `600 22px/1.15 ${theme.fonts.display}`,
      mobile: {
        font: `600 20px/1.15 ${theme.fonts.display}`,
      },
    };
  },
  ageWallDescription: theme => {
    return {
      font: `14px/1.6 ${theme.fonts.display}`,
      mobile: {
        font: `12px/1.6 ${theme.fonts.display}`,
      },
    };
  },
  ageWallAttention: theme => {
    return {
      font: `14px/1.6 ${theme.fonts.display}`,
      mobile: {
        font: `10px/1.6 ${theme.fonts.display}`,
      },
    };
  },
  ageWallAgreement: theme => {
    return {
      font: `10px/1.6 ${theme.fonts.display}`,
      mobile: {
        font: `8px/1.5 ${theme.fonts.display}`,
      },
    };
  },
};
