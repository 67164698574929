import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import topicProvider from 'core/resolver/data/topic';
import resolve from 'core/resolver/resolve';
import { topicsFetcher } from 'core/fetchers/topics';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Topic from 'core/components/Topic';

import { PageIndent } from 'site/components/Wrappers';
import RegWall from 'site/components/RegWall';
import withBatId from 'site/components/BatId/withBatId';
import batIdPropTypes from 'site/utils/prop-types/batId';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';
import { Fragment } from 'react';


const relationships = resolveRelationships(...[
  ['rubric', 'content'],
  {},
  { rubric: {}, content: {} },
]);

function topicRenderer(topic, enhancedTopic, batId, additionalTopics, list3Topics) {
  const {
    attributes: {
      is_premium: isPremium,
    },
  } = topic;

  const lockForUnauthorized = isPremium && !batId.isAuthorized;

  return (
    <>
      <TopicHeader content={topic} />
      <TopicContent
        content={topic}
        hideContent={lockForUnauthorized}
        list3Topics={list3Topics}
      />
      {lockForUnauthorized ? <RegWall /> : <TopicFooter additionalTopics={additionalTopics} content={topic} />}
    </>
  );
}

function TopicPage({ content, additionalTopics, batId, list3Topics }) {
  return (
    <Fragment>
      <Billboard />
      <PageIndent>
        <Topic content={content}>
          {(topic, enhancedTopic) => topicRenderer(topic, enhancedTopic, batId, additionalTopics, list3Topics)}
        </Topic>
      </PageIndent>
    </Fragment>
  );
}

TopicPage.propTypes = {
  content: PropTypes.object,
  additionalTopics: PropTypes.array,
  readMoreContent: PropTypes.object,
  batId: batIdPropTypes,
  list3Topics: PropTypes.object,
};

const additionalProvider = resolve({
  additionalTopics: ({ bebopApi, content, isMobile }) => {
    const { id } = content;
    const {
      rubric: {
        slug: rubricSlug,
      },
    } = relationships(content);

    return bebopApi
      .getTopics({
        rubric_root: rubricSlug,
        include: 'image,rubric,authors',
        limit: isMobile ? 4 : 8,
        excluded_ids: id,
      })
      .then(denormalizeData);
  },

  list3Topics: topicsFetcher({
    list: 'main_middle_slider3',
    sort: 'list',
    include: 'image,rubric',
  }),
});

const composedProvider = compose(topicProvider, additionalProvider);

export default withBreakpoint(withPageHocs(composedProvider)(withBatId(TopicPage)));
