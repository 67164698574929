import PrivateRoute from 'core/components/BaseRoutes/PrivateRoute';
import withBatId from 'site/components/BatId/withBatId';
import batIdPropTypes from 'site/utils/prop-types/batId';

function SitePrivateRoute(props) {
  const {
    batId,
    ...otherProps
  } = props;

  return <PrivateRoute {...otherProps} user={batId} />;
}

SitePrivateRoute.propTypes = {
  batId: batIdPropTypes,
};

export default withBatId(SitePrivateRoute);
