import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import cx from 'classnames';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Link from 'core/components/Link';

import { pageLinkConstructor } from 'core/utils/url-helper';

import paginator from 'site/icons/paginator.png';

import styles from './index.styl';

// функция для формирования массива номеров страниц с троеточиями
function getPages(active, total, pageDelta) {
  const left = active - pageDelta;
  const right = active + pageDelta + 1;

  const range = [];
  for (let i = 1; i <= total; i++) {
    if (i === 1 || i === total || i >= left && i < right) {
      range.push(i);
    }
  }

  const rangeWithDots = [range[0]];
  for (let i = 1; i < range.length; i++) {
    const currentPage = range[i];
    const prevPage = range[i - 1];
    if (currentPage - prevPage === 2) {
      // если дырка между двумя ближайшими номерами = 2, то нет смысла ставить точки и добавляем просто промежуточную страницу
      rangeWithDots.push(prevPage + 1);
    } else if (currentPage - prevPage > 2) {
      // если дырка между двумя ближайшими номерами > 2, добавляем точки
      rangeWithDots.push('...');
    }
    rangeWithDots.push(currentPage);
  }

  return rangeWithDots;
}


const Pagination = (props) => {
  const {
    total,
    active,
    pageDelta,
    isMobile,
    location,
  } = props;

  const pages = getPages(active, total, isMobile ? 2 : pageDelta);

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles._active}
          background-image url('${paginator}')
      `}</style>

      {pages.map((page, key) => {
        const Wrapper = (page === '...' || page === active) ? 'div' : Link;
        return (
          <Wrapper
            to={pageLinkConstructor(page, location)}
            type='tertiary'
            className={cx(styles.link, page === active && styles._active)}
            key={key}
          >
            {page}
          </Wrapper>
        );
      })}
    </div>
  );
};

Pagination.propTypes = {
  /** Сколько отображать страниц по сторонам от активной */
  pageDelta: PropTypes.number.isRequired,
  /** Активная страница */
  active: PropTypes.number.isRequired,
  /** Общее количество страниц */
  total: PropTypes.number.isRequired,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};


Pagination.defaultProps = {
  pageDelta: 3,
};

const PaginationWithHOCs = withRouter(withBreakpoint(Pagination));
PaginationWithHOCs.displayName = 'Pagination';

export default PaginationWithHOCs;
export { Pagination as StorybookComponent };
