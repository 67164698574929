import color from 'core/libs/color';

export const colors = {
  primary: '#000',
  primaryOnHover: '#363636',
  layout: '#FFF',
  content: '#FFF',
  input: '#FFF',
  active1: '#1FAEED',
  active2: '#1276BE',
  active3: '#09509E',
  error: '#FF2E00',
  grey1: '#D2D2D2',
  grey2: '#9A9A9A',
  grey3: '#e3e3e3',
  blue1: '#132C62',
  success: '#ADFB70',
  get divider() {
    return this.grey1;
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.blue1; // цвет темы, meta theme-color, tile-color, safari-pinned-tab
  },
};

const shapkaLink = {
  idle: {
    color: colors.primary,
  },

  hover: {
    color: colors.active1,
  },

  current: {
    color: colors.primary,
  },
};

export default {
  colors,
  controls: {
    input: {
      const: {
        color: colors.primary,
      },
    },
    link: {
      tertiary: {
        idle: {
          color: colors.primary,
          decoration: 'none',
        },
        hover: {
          color: colors.active1,
          decoration: 'none',
        },
        active: {
          color: colors.active1,
          decoration: 'none',
        },
        visited: {
          color: colors.primary,
          decoration: 'none',
        },
        current: {
          color: colors.primary,
          decoration: 'none',
        },
      },
    },
    shapka: {
      controls: shapkaLink,
      link: shapkaLink,
      dropdown: {
        link: shapkaLink,
      },

      tagline: {
        color: colors.grey2,
      },
    },

    button: {
      primary: {
        idle: {
          color: colors.content,
          background: colors.active1,
          border: `1px solid ${colors.active1}`,
        },

        hover: {
          color: colors.content,
          background: colors.active2,
          border: `1px solid ${colors.active2}`,
        },

        active: {
          color: colors.content,
          background: colors.active3,
          border: `1px solid ${colors.active3}`,
        },

        disabled: {
          color: color(colors.primary).alpha(0.7).toString(),
          background: color(colors.grey2).alpha(0.2).toString(),
          border: `1px solid ${color(colors.grey2).alpha(0.2).toString()}`,
        },
      },

      secondary: {
        idle: {
          color: colors.active1,
          background: 'transparent',
          border: `1px solid ${colors.active1}`,
        },

        hover: {
          color: color(colors.active1).alpha(0.8).toString(),
          background: 'transparent',
          border: `1px solid ${color(colors.active1).alpha(0.8).toString()}`,
        },

        active: {
          color: color(colors.active1).alpha(0.8).toString(),
          background: 'transparent',
          border: `1px solid ${color(colors.active1).alpha(0.8).toString()}`,
        },

        disabled: {
          color: color(colors.grey1).alpha(0.8).toString(),
          background: 'transparent',
          border: `1px solid ${color(colors.grey1).alpha(0.8).toString()}`,
        },
      },
    },
  },
};
