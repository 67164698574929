import color from 'core/libs/color';

export const colors = {
  primary: '#FFF',
  layout: '#FFF',
  get content() { return this.blue1; },
  input: '#FFF',
  active1: '#FFF',
  active2: '#09509E',
  active3: '#1FAEED',
  error: '#FF2E00',
  grey1: '#A6A6A6',
  grey2: '#9A9A9A',
  blue1: '#132C62',
  success: '#ADFB70',
  modalBaseBg: '#50ADE7',
  modalSpecBg: '#001034',
  get modalBaseOverlay() {
    return color(this.modalBaseBg).alpha(0.4).string();
  },
  get modalSpecOverlay() {
    return color(this.modalSpecBg).alpha(0.4).string();
  },
  get divider() {
    return this.grey1;
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.active1; // цвет темы, meta theme-color, tile-color, safari-pinned-tab
  },
};

const shapkaLink = {
  idle: {
    color: colors.primary,
  },
  hover: {
    color: colors.active3,
  },
  current: {
    color: colors.primary,
  },
};

export default {
  colors,
  controls: {
    input: {
      const: {
        color: colors.blue1,
      },
    },
    link: {
      tertiary: {
        idle: {
          color: colors.primary,
          decoration: 'none',
        },
        hover: {
          color: colors.active3,
          decoration: 'none',
        },
        active: {
          color: colors.primary,
          decoration: 'none',
        },
        visited: {
          color: colors.primary,
          decoration: 'none',
        },
      },
    },
    shapka: {
      controls: shapkaLink,
      link: shapkaLink,

      tagline: {
        color: colors.primary,
      },
    },

    button: {
      primary: {
        idle: {
          color: colors.content,
          background: colors.active1,
          border: `1px solid ${colors.active1}`,
        },

        hover: {
          color: colors.active1,
          background: colors.active2,
          border: `1px solid ${colors.active2}`,
        },

        active: {
          color: colors.active1,
          background: colors.active3,
          border: `1px solid ${colors.active3}`,
        },

        disabled: {
          color: color(colors.grey1).alpha(0.7).toString(),
          background: color(colors.grey1).alpha(0.2).toString(),
          border: `1px solid ${color(colors.grey1).alpha(0.2).toString()}`,
        },
      },

      secondary: {
        idle: {
          color: color(colors.primary).alpha(0.5).toString(),
          background: 'transparent',
          border: `1px solid ${color(colors.primary).alpha(0.5).toString()}`,
        },

        hover: {
          color: colors.primary,
          background: 'transparent',
          border: `1px solid ${colors.primary}`,
        },

        active: {
          color: colors.primary,
          background: 'transparent',
          border: `1px solid ${colors.primary}`,
        },

        disabled: {
          color: color(colors.primary).alpha(0.4).toString(),
          background: 'transparent',
          border: `1px solid ${color(colors.primary).alpha(0.4)}`,
        },
      },
    },
  },
};
