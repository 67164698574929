import Link from 'core/components/Link';
import H1 from 'core/components/H1';
import H2 from 'core/components/H2';

import { Indent, PageIndent } from 'site/components/Wrappers';


export default function AboutPage() {
  return (
    <PageIndent>
      <H1>О платформе</H1>
      <p>
        Платформа YourChoice расскажет совершеннолетним потребителям продуктов с никотином о возможностях минимизировать вред от привычки, поможет сделать осознанный выбор во многих сферах жизни и внести свой вклад в создание лучшего будущего для общества.
      </p>
      <p>
        Новое медиа соответствует ключевым интересам потребителей продуктов с никотином. Они не только изучают влияние привычки на здоровье, но и следят за гаджетами, средствами ухода, ходят в популярные бары и рестораны, интересуются автомобилями, тенденциями в моде, опытом других людей. YourChoice развеет мифы, окружающие совершеннолетних потребителей, а также расскажет, как с помощью применения современных технологий снизить вред от привычки и повысить качество жизни.
      </p>
      <Link to='/press_release'>Пресс релиз</Link>
      <Indent top={40} />
      <H2>Контакты</H2>
      <Indent top={10} />
      <Link to='mailto:info@yourchoice.ru'>info@yourchoice.ru</Link>
    </PageIndent>
  );
}
