import PropTypes from 'prop-types';

import { AgeWallAttention } from 'site/components/Texts';

export default function Attention({ className }) {
  return (
    <AgeWallAttention className={className}>
      Данное предложение доступно<br />
      только совершеннолетним потребителям табака
    </AgeWallAttention>
  );
}

Attention.propTypes = {
  className: PropTypes.string,
};
