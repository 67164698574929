import { Route, Switch } from 'core/libs/router';

import { PageIndent } from 'site/components/Wrappers';

import Registration from './Registration';
import PhoneConfirm from './PhoneConfirm';
import EmailConfirm from './EmailConfirm';
import Success from './Success';
import VerifyAccountResend from './VerifyAccountResend';
import VerifyPhoneResend from './VerifyPhoneResend';

const signupRoutes = [
  {
    exact: true,
    path: '/signup',
    component: Registration,
  }, {
    exact: true,
    path: '/signup/phone_confirm',
    component: PhoneConfirm,
  }, {
    exact: true,
    path: '/signup/email_confirm',
    component: EmailConfirm,
  }, {
    exact: true,
    path: '/signup/success',
    component: Success,
  }, {
    exact: true,
    path: '/signup/verify_account_resend',
    component: VerifyAccountResend,
  }, {
    exact: true,
    path: '/signup/verify_phone_resend',
    component: VerifyPhoneResend,
  },
];

export default function Profile() {
  return (
    <PageIndent>
      <Switch>
        {signupRoutes.map((routeParams, i) => (
          <Route key={i} {...routeParams} />
        ))}
      </Switch>
    </PageIndent>
  );
}
