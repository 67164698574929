import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import Image from 'core/components/Image';
import Swipe, { Slide } from 'core/components/Swipe';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import { MainTitle, RubricName } from 'site/components/Texts';
import { NegativeMobile } from 'site/components/Wrappers';

import ArrowLeft from 'site/icons/ArrowLeft.svg';
import ArrowRight from 'site/icons/ArrowRight.svg';

import styles from './index.styl';

const relationships = resolveRelationships(['image', 'rubric'], {}, { image: { versions: {} } });

const TopicsGalleryBlue = (props) => {
  const {
    content,
    isMobile,
    theme,
  } = props;

  const swipeEl = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = ({ index }) => setActiveIndex(index);
  const goToHandler = index => {
    if (index < 0) return swipeEl.current.goto(content.length - 1);
    if (index === content.length) return swipeEl.current.goto(0);
    return swipeEl.current.goto(index);
  };

  if (!content.length) {
    return null;
  }

  return (
    <NegativeMobile>
      <style jsx>{`
        .${styles.control}
          &:hover
            color ${theme.colors.active1}

        .${styles.slideContent}
          background ${theme.colors.blue1}
        `}</style>
      <div className={styles.swiperContainer}>
        <div className={cx(styles.control, styles.prev)} onClick={() => goToHandler(activeIndex - 1)}>
          <div className={styles.arrow}>
            <ArrowLeft />
          </div>
        </div>
        <div className={cx(styles.control, styles.next)} onClick={() => goToHandler(activeIndex + 1)}>
          <div className={styles.arrow}>
            <ArrowRight />
          </div>
        </div>
        <Swipe
          ref={swipeEl}
          onActiveIndexChange={onActiveIndexChange}
          effect='fade'
          speed={150}
        >
          {content.map((topic, index) => {
            const {
              rubric: {
                title: rubricTitle,
              },
              image: {
                versions: {
                  slider: cover,
                },
              },
            } = relationships(topic);

            const {
              link,
              headline,
            } = topic.attributes;

            return (
              <Slide width='100%' key={index}>
                <Link
                  to={link}
                  className={cx(styles.link)}
                  type='card'
                >
                  <div className={styles.slideContent}>
                    <div className={styles.imageContainer}>
                      <Image
                        src={cover}
                        maxWidth={490}
                        height={isMobile ? 280 : 370}
                      />
                      <div className={styles.overlay} />
                    </div>
                    <div className={styles.titleContainer}>
                      <MainTitle>
                        {headline}
                      </MainTitle>
                      <RubricName className={styles.rubric}>
                        {rubricTitle}
                      </RubricName>
                    </div>
                  </div>
                </Link>
              </Slide>
            );
          })}
        </Swipe>
        <Mobile>
          <div className={styles.dots}>
            {content.map((topic, index) =>(
              <div key={index} className={cx(
                styles.dot,
                index === activeIndex ? styles._active : null
              )}
              />
            ))}
          </div>
        </Mobile>
      </div>
    </NegativeMobile>
  );
};

TopicsGalleryBlue.propTypes = {
  /** Список топиков для отображения */
  content: PropTypes.array.isRequired,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

TopicsGalleryBlue.defaultProps = {
  content: [],
};

const TopicsGalleryBlueWithHOCs = withBreakpoint(withTheme(TopicsGalleryBlue));
TopicsGalleryBlueWithHOCs.displayName = 'TopicsGalleryBlue';

export default TopicsGalleryBlueWithHOCs;
export { TopicsGalleryBlue as StorybookComponent };

