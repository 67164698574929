import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, branch } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';
import Shapka from 'core/components/Shapka';
import withTheme from 'core/components/theme';
import ThemeSwitch from 'core/components/ThemeSwitch';
import themeSwitcher from 'core/components/themeSwitcher';

import SearchModal from 'site/components/SearchModal';

import SearchIcon from 'site/icons/Search';

import { checkIsPhoneSpec, checkisOzonSpec } from 'site/utils';

// import Authentication from './Authentication';

import styles from './index.styl';

class Shlyapa extends PureComponent {
  state = {
    searchIsOpen: false,
    dropdownMenuIsOpen: false,
  };

  toggleSearchModal = () => {
    this.setState(prevState => ({ searchIsOpen: !prevState.searchIsOpen }));
  };

  handleDropdownMenu = isOpen => {
    this.setState({ dropdownMenuIsOpen: isOpen });
  };

  render() {
    const {
      theme,
      isDesktop,
      location,
    } = this.props;

    const shapkaAtoms = theme.controls.shapka;

    const {
      searchIsOpen,
      // dropdownMenuIsOpen,
    } = this.state;

    const isOzonSpec = checkisOzonSpec(location.pathname);
    const isPhoneSpec = checkIsPhoneSpec(location.pathname);
    // const isSpecProject = isOzonSpec || isPhoneSpec;

    return (
      <div className={styles.shapka}>
        <style jsx>{`
          .${styles.shapkaSearch}
            color ${shapkaAtoms.controls.idle.color}
            &:hover
              color ${shapkaAtoms.controls.hover.color}
        `}</style>
        <Shapka
          handleChangeOpenState={this.handleDropdownMenu}
          // {...(!isDesktop && dropdownMenuIsOpen && !isSpecProject) && {
          //   extra: <Authentication />,
          // }}
          {...isDesktop && {
            additional: (
              <div className={styles.shapkaAdditional}>
                {location.pathname !== '/search' && (
                  <span onClick={this.toggleSearchModal} className={styles.shapkaSearch}>
                    <SearchIcon />
                  </span>
                )}
                {/* {!isSpecProject && <Authentication />} */}
              </div>
            ),
          }}
        />
        <ThemeSwitch
          name='light'
          {...isOzonSpec && { name: 'purple' }}
          {...isPhoneSpec && { name: 'blue' }}
        >
          <SearchModal
            isOpen={searchIsOpen}
            toggleModal={this.toggleSearchModal}
          />
        </ThemeSwitch>
      </div>
    );
  }
}

Shlyapa.propTypes = {
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.object,
};

export default compose(
  withRouter,
  branch(
    ({ location }) => location.pathname === '/',
    themeSwitcher('dark')
  ),
  withTheme,
  withBreakpoint
)(Shlyapa);
