import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';

import Adfox from 'core/components/Ad/Adfox';

import { SHTORKA_SHOWED, BRANDING_CLASSNAME } from 'core/site/constants';


function Ad(props) {
  const { name } = props;
  const onRender = () => {
    if (name === 'Shtorka') {
      PubSub.publish(SHTORKA_SHOWED);
    }
    if (name === 'Background') {
      document.body.classList.add(BRANDING_CLASSNAME);
    }
    if (name === 'Footer') {
      document.body.classList.add(BRANDING_CLASSNAME);
    }
  };

  return (
    <Adfox {...props} onRender={onRender} />
  );
}

Ad.propTypes = {
  name: PropTypes.string,
};

export default Ad;
