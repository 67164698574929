import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';

import Card2 from 'site/cards/Card2';


export default function Boroda({ content }) {
  return (
    <Feed
      content={content}
      card={Card2}
      interitemSpacing={30}
      columns={4}
      grid
    />
  );
}

Boroda.propTypes = {
  content: PropTypes.array,
};
