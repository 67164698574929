import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import { withRouter, Redirect } from 'core/libs/router';
import skip from 'core/resolver/skip';

import { requireValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import BaseForm from 'site/components/BaseForm';
import withBatId from 'site/components/BatId/withBatId';
import batIdPropTypes from 'site/utils/prop-types/batId';

import PhoneConfirmForm from './Form';

import { setMetric } from 'site/utils';
import { METRICS } from 'site/constants';

const validate = {
  code: requireValidation,
};


function RegistrationPhoneConfirm({ history, location, batId }) {
  const { phone } = location.state || {};

  const initialValues = {
    key: '',
    phone,
  };

  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  const submitCallback = useCallback(values => {
    return batId.verifyPhone(values)
      .then(batId.setSession)
      .then(() => {
        setMetric(METRICS.reg_form_phone_confirm);
        history.push('/signup/success');
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [batId, history]);

  if (!phone) {
    return <Redirect to='/signup' />;
  }

  return (
    <BaseForm
      form={PhoneConfirmForm}
      submitCallback={submitCallback}
      initialValues={initialValues}
      validate={validate}
      serverErrorName={errorName}
      serverFieldsErrors={fieldsErrors}
    />
  );
}

RegistrationPhoneConfirm.contextTypes = {
  authorizationApi: PropTypes.object,
};

RegistrationPhoneConfirm.propTypes = {
  /** @ignore */
  location: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  batId: batIdPropTypes,
};

export default compose(
  skip,
  withRouter,
  withBatId,
)(RegistrationPhoneConfirm);
