import { useState, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Checkbox from 'core/components/Checkbox';
import { Mobile, Desktop } from 'core/components/breakpoint';

import Input from 'site/components/Input';
import Error from 'site/components/Error';
import Datepicker from 'site/components/Datepicker';
import { Indent } from 'site/components/Wrappers';
import { FieldName, ErrorMessage, LinkSmall, LinkMedium } from 'site/components/Texts';

import { passwordsEqualValidation } from 'site/utils/formValidations';

import { VERTICAL_INDENT } from 'site/constants';
import styles from './index.styl';

export default function RegistrationForm({
  handleInputChange,
  handleBlur,
  handleSubmit,
  errors,
  touched,
  values,
  serverErrorName,
  serverErrorStatus,
}) {
  const [passwordsReadyForCompare, setPasswordsReadyForCompare] = useState(false);
  const passwordHasError = touched.password && errors.password;
  const passwordConfirmHasError = touched.password_confirm && errors.password_confirm;

  const handlePasswordsReadyForCompare = useCallback(() => {
    // оба поля пароля были отредактированы
    const passwordsTouched = touched.password && touched.password_confirm;

    setPasswordsReadyForCompare(passwordsTouched);
  }, [touched.password, touched.password_confirm]);

  const isForbidden = serverErrorStatus === 403;
  const resendPhoneVerify = isForbidden && errors.phone;
  const resendEmailVerify = isForbidden && errors.email;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='text'
          label='Имя'
          name='first_name'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.first_name}
          error={touched.first_name && errors.first_name ? errors.first_name : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='text'
          label='Фамилия'
          name='last_name'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.last_name}
          error={touched.last_name && errors.last_name ? errors.last_name : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='email'
          label='E-mail:'
          name='login'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.login}
          error={touched.login && errors.login ? errors.login : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Пароль'
          name='password'
          onChange={handleInputChange}
          onBlur={event => {
            handleBlur(event);
            handlePasswordsReadyForCompare();
          }}
          value={values.password}
          {...passwordsReadyForCompare && {
            error: passwordsEqualValidation(values.password, values.password_confirm),
          }}
          {...passwordHasError && { error: errors.password }}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Пароль еще раз'
          name='password_confirm'
          onChange={handleInputChange}
          onBlur={event => {
            handleBlur(event);
            handlePasswordsReadyForCompare();
          }}
          value={values.password_confirm}
          {...passwordsReadyForCompare && {
            error: passwordsEqualValidation(values.password, values.password_confirm),
          }}
          {...passwordConfirmHasError && { error: errors.password_confirm }}
        />
      </div>
      <div className={cx(styles.row, styles.phoneGroup)}>
        <InputMask
          mask='+7 (999) 999-99-99'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.phone}
        >
          <Input
            type='tel'
            label='Ваш телефон'
            name='phone'
            className={styles.phone}
            error={touched.phone && errors.phone ? errors.phone : ''}
          />
        </InputMask>
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Datepicker
          value={values.birthdate}
          onChange={handleInputChange}
          onBlur={handleBlur}
          label='Дата рождения'
          name='birthdate'
          error={touched.birthdate && errors.birthdate ? errors.birthdate : ''}
          className={styles.date}
        />
      </div>
      <div className={styles.row}>
        <Checkbox
          defaultChecked={values.terms_and_conditions}
          name='terms_and_conditions'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.terms_and_conditions}
        >
          <FieldName>Подтверждаю&nbsp;
            <Link
              type='secondary'
              target='_blank'
              to='/agreement_reg'
            >
              согласие на обработку персональных данных
            </Link>
          </FieldName>
        </Checkbox>
        <Error error={errors.terms_and_conditions} />
      </div>

      <Indent top={VERTICAL_INDENT} />
      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit'>Зарегистрироваться</Button>
        <div className={styles.errorMessage}>
          {serverErrorName && <ErrorMessage>{serverErrorName}</ErrorMessage>}
          {resendPhoneVerify && (
            <Link
              type='secondary'
              className={styles.verifyLink}
              to={{
                pathname: '/signup/verify_phone_resend',
                state: { phone: values.phone },
              }}
            >
              <Desktop>
                <LinkMedium>Отправить смс повторно</LinkMedium>
              </Desktop>
              <Mobile>
                <LinkSmall>Отправить смс повторно</LinkSmall>
              </Mobile>
            </Link>
          )}
          {resendEmailVerify && (
            <Link
              type='secondary'
              to={{
                pathname: '/signup/verify_account_resend',
                state: { login: values.login },
              }}
              className={styles.verifyLink}
            >
              <Desktop>
                <LinkMedium>Отправить email повторно</LinkMedium>
              </Desktop>
              <Mobile>
                <LinkSmall>Отправить email повторно</LinkSmall>
              </Mobile>
            </Link>
          )}
        </div>
      </div>
    </form>
  );
}

RegistrationForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
  serverErrorStatus: PropTypes.number,
};
