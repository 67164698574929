import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { Desktop, Mobile } from 'core/components/breakpoint';

import { Neon } from 'site/components/Wrappers';

import Logos from '../components/Logos';
import Buttons from '../components/Buttons';
import Attention from '../components/Attention';
import Agreement from '../components/Agreement';
import Description from '../components/Description';
import Title from '../components/Title';

import styles from './index.styl';

function ContentPhone({ theme, yesAnswerHandler, noAnswerHandler }) {
  return (
    <Neon>
      <div className={styles.container}>
        <style jsx>{`
          .${styles.container}
            color ${theme.colors.primary}
        `}</style>
        <Mobile><Attention className={styles.attention} /></Mobile>
        <Desktop><Logos className={styles.logos} /></Desktop>
        <Title className={styles.title} />
        <Buttons
          className={styles.buttons}
          noAnswerHandler={noAnswerHandler}
          yesAnswerHandler={yesAnswerHandler}
        />
        <Desktop><Attention className={styles.attention} /></Desktop>
        <Description className={styles.description} />
        <Agreement className={styles.agreement} />
        <Mobile><Logos /></Mobile>
      </div>
    </Neon>
  );
}

ContentPhone.propTypes = {
  theme: PropTypes.object,
  noAnswerHandler: PropTypes.func.isRequired,
  yesAnswerHandler: PropTypes.func.isRequired,
};

export default withTheme(ContentPhone);
