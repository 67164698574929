import { colors } from './dark';

export default {
  colors,
  controls: {
    shapka: {
      backgroundOpened: colors.active2,
      dropdown: {
        background: colors.active2,
      },
    },
  },
};
