import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

const purpleBgImage = require('site/images/purple_background.jpg');
const purpleBgImageMobile = require('site/images/purple_background_mobile.jpg');

function NeonBody({ location, theme }) {
  const isRegistrationPage = !!~location.pathname.search(/^\/spec-registration-ozon100ftb\/?$/);
  const isSignedOrSuccessPage = !!~location.pathname.search(/^\/spec-registration-ozon100ftb\/(success|signed)+\/?/);

  const {
    colors: {
      layout: layoutColor,
    },
  } = theme;

  if (!isRegistrationPage && !isSignedOrSuccessPage) return null;

  const scopedBgStyles = resolveScopedStyles(
    <scope>
      <style global jsx>{`
        body
          &.desktop
          &.mobile
            background-color ${layoutColor}
      `}</style>
      <style global jsx>{`
        body
          background-position center top
          background-size 100%
          background-repeat no-repeat

          &:before
            display none

          &.desktop
            @media screen and (max-width: 1280px)
              background-size cover

          &.mobile
            background-position 50% -10px

            @media screen and (max-width: 350px)
              background-size cover
      `}</style>
    </scope>
  );

  let scopedBgImageStyles;

  if (isRegistrationPage) {
    scopedBgImageStyles = resolveScopedStyles(
      <scope>
        <style global jsx>{`
          body
            background-image linear-gradient(180deg, transparent 25%, ${layoutColor} 55%, ${layoutColor} 100%),
              url(${purpleBgImage})

            &.desktop
              @media screen and (max-width: 1600px)
                background-image linear-gradient(180deg, transparent 40%, ${layoutColor} 50%, ${layoutColor} 100%),
                  url(${purpleBgImage})

              @media screen and (max-width: 1410px)
                background-image linear-gradient(180deg, transparent 30%, ${layoutColor} 40%, ${layoutColor} 100%),
                  url(${purpleBgImage})

            &.mobile
              background-image linear-gradient(180deg, transparent 40%, ${layoutColor} 50%, ${layoutColor} 100%),
                url(${purpleBgImageMobile})
        `}</style>
      </scope>
    );
  }

  if (isSignedOrSuccessPage) {
    scopedBgImageStyles = resolveScopedStyles(
      <scope>
        <style global jsx>{`
          body
            background-image linear-gradient(180deg, transparent 55%, ${layoutColor} 90%, ${layoutColor} 100%),
              url(${purpleBgImage})

            &.desktop
              @media screen and (max-width: 1600px)
                background-image linear-gradient(180deg, transparent 55%, ${layoutColor} 80%, ${layoutColor} 100%),
                  url(${purpleBgImage})

              @media screen and (max-width: 1400px)
                background-image linear-gradient(180deg, transparent 55%, ${layoutColor} 70%, ${layoutColor} 100%),
                  url(${purpleBgImage})

            &.mobile
              background-image linear-gradient(180deg, transparent 55%, ${layoutColor} 80%, ${layoutColor} 100%),
                url(${purpleBgImageMobile})
        `}</style>
      </scope>
    );
  }

  return (
    <>
      <scopedBgStyles.styles />
      {scopedBgImageStyles && <scopedBgImageStyles.styles />}
    </>
  );
}

NeonBody.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(withBreakpoint(withRouter(NeonBody)));
