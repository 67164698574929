import { Route, Switch } from 'core/libs/router';

import { PageIndent } from 'site/components/Wrappers';

import Login from './Login';
import ResetPassword from './ResetPassword';
import ResetPasswordByEmail from './ResetPasswordByEmail';
import ResetPasswordCheckEmail from './ResetPasswordCheckEmail';
import ResetPasswordSuccess from './ResetPasswordSuccess';

export const loginRoutes = [
  {
    exact: true,
    path: '/login',
    component: Login,
  }, {
    exact: true,
    path: '/login/reset_password_by_email',
    component: ResetPasswordByEmail,
  }, {
    exact: true,
    path: '/login/reset_password_check_email',
    component: ResetPasswordCheckEmail,
  }, {
    exact: true,
    path: '/login/reset_password',
    component: ResetPassword,
  }, {
    exact: true,
    path: '/login/reset_password_success',
    component: ResetPasswordSuccess,
  },
];

export default function LoginPage() {
  return (
    <PageIndent>
      <Switch>
        {loginRoutes.map((routeParams, i) => (
          <Route key={i} {...routeParams} />
        ))}
      </Switch>
    </PageIndent>
  );
}
