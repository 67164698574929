import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { withBreakpoint } from 'core/components/breakpoint';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { RubricName, CardTitleItalic } from 'site/components/Texts';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric', 'authors'];

const relationships = resolveRelationships(['image', 'rubric', 'authors'], {}, {
  image: {
    versions: {},
  },
});

function Card3(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
  } = content.attributes;

  const {
    link_attrs: linkAttrs,
  } = content.extra || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    authors,
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.name}
          font 700 13px/16px ${theme.fonts.display}

        .${styles.card}
          border 1px solid ${theme.colors.grey1}

        .${styles.jobTitle}
          color ${theme.colors.grey2}
      `}</style>
    </scope>
  );

  return (
    <div className={scoped.wrapClassNames(styles.card)}>
      <Link
        to={link}
        type='card'
        {...linkAttrs && {
          innerRef: node => node && linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value)),
        }}
      >
        <div className={styles.imageContainer}>
          <Image
            src={cover}
            previewSrc={previewCover}
            height={100}
            maxWidth={100}
          />
        </div>
        <CardTitleItalic
          className={styles.quoteSign}
          style={{ fontSize: '36px', lineHeight: '48px' }}
        >
          “
        </CardTitleItalic>
        <div className={styles.quoteTextContainer}>
          <CardTitleItalic className={styles.quoteText}>
            {headline}
          </CardTitleItalic>
        </div>
        <div className={scoped.wrapClassNames(styles.name)}>
          {authors.length && authors[0].attributes.name}
        </div>
        <RubricName className={scoped.wrapClassNames(styles.jobTitle)}>
          {authors.length && (authors[0].attributes.job_title || ' ')}
        </RubricName>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card3.propTypes = {
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const Card = cardHOC(withBreakpoint(Card3));

Card.requiredPayloadImports = requiredPayloadImports;
Card.displayName = 'Card3';

export default Card;
export { Card3 as StorybookComponent };
