import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { checkIsPhoneSpec, checkisOzonSpec } from 'site/utils';

import OzonSpec from './Ozon';
import PhoneSpec from './Phone';

function NeonBody({ location }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);

  if (isOzonSpec) {
    return <OzonSpec />;
  }

  if (isPhoneSpec) {
    return <PhoneSpec />;
  }

  return null;
}

NeonBody.propTypes = {
  location: PropTypes.object,
};

export default withRouter(NeonBody);
