import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import { Mobile, Desktop } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { Description } from 'site/components/Texts';
import { PageLayout } from 'site/components/Wrappers';
import withBatId from 'site/components/BatId/withBatId';

import styles from './index.styl';

function Layout({ theme, pageName, children, batId }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .logoutLink
          color ${theme.colors.primary}
          &:hover
            color ${theme.colors.active1}
            text-decoration none
          &:active
            color ${theme.colors.active3}
            text-decoration none
          &:visited
            color ${theme.colors.primary}

        .pageName
          color ${theme.colors.grey2}
      `}</style>
    </scope>
  );

  return (
    <>
      <Desktop>
        <PageLayout
          leftSidebar={(
            <Description className={scope.wrapClassNames('pageName')}>
              {pageName}
            </Description>
          )}
          rightSidebar={(
            <Description>
              <Link
                type='secondary'
                onClick={batId.logout}
                to='/'
              >
                Выйти
              </Link>
            </Description>
          )}
        >
          {children}
        </PageLayout>
      </Desktop>

      <Mobile>
        {children}
        <Description className={styles.logout}>
          <Link
            className={scope.wrapClassNames('logoutLink')}
            type='secondary'
            onClick={batId.logout}
            to='/'
          >
            Выйти
          </Link>
        </Description>
      </Mobile>
      <scope.styles />
    </>
  );
}

Layout.propTypes = {
  theme: PropTypes.object,
  pageName: PropTypes.string,
  batId: PropTypes.object,
};

export default withTheme(withBatId(Layout));
