import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';
import color from 'core/libs/color';
import Link from 'core/components/Link';
import Logo from 'core/components/Logo';
import withTheme from 'core/components/theme';
import withMenu from 'core/components/withMenu';

import { compose } from 'core/libs/recompose';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { menuPropTypes } from 'core/utils/prop-types/relationships';

import Socials from 'site/components/Socials';

import styles from './index.styl';

function SpecFooter({ theme, menu }) {
  const {
    colors: colorAtoms,
    fonts: {
      display: fontDisplay,
    },
  } = theme;

  const menuItems = get(menu, 'data.attributes.menu_items', []);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.logo}
          :global(svg)
            color ${colorAtoms.primary}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.footer}>
      <style jsx>{`
        .${styles.footer}
          background ${color(colorAtoms.black).alpha(0.3).string()}
          color ${colorAtoms.primary}
          font 500 12px/19px ${fontDisplay}

          &:before
          &:after
            background ${color(colorAtoms.black).alpha(0.3).string()}
      `}</style>
      <ul className={styles.menu}>
        <div className={styles.menuItem}>
          &copy; АО &laquo;МУМТ&raquo;
        </div>
        {menuItems.map((item, i) => (
          <li key={i} className={styles.menuItem}>
            <Link
              type='primary'
              className={styles.link}
              to={item.link}
            >
              {item.content}
            </Link>
          </li>
        ))}
      </ul>
      <Socials isMonochrome isFooter />
      <Logo className={scope.wrapClassNames(styles.logo)} atomsName='scooter' />
      <scope.styles />
    </div>
  );
}

SpecFooter.propTypes = {
  theme: PropTypes.object,
  /** Главное меню */
  menu: menuPropTypes(),
};

export default compose(
  withMenu('footer-menu-spec-ozon'),
  withTheme,
)(SpecFooter);
