import PropTypes from 'prop-types';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';
import { Mobile, Desktop } from 'core/components/breakpoint';

import Gallery from 'site/components/Gallery';

import styles from './index.styl';

export default function PhotoGallery(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  return (
    <>
      <Desktop>
        <style jsx>{`
          .${styles.container}
              border 1px solid ${theme.colors.grey1}
          `}</style>
        <div className={styles.container}>
          <Gallery photoGallery={block} />
        </div>
      </Desktop>

      <Mobile>
        <Gallery photoGallery={block} />
      </Mobile>
    </>
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
