import PropTypes from 'prop-types';
import cx from 'classnames';

import { MaxWidthWrapper } from 'site/components/Wrappers';

import styles from './index.styl';

/**
 * Унифицированная шапка страницы.
 * Сетка в три колонки.
 * Контент ограничен по ширине CONTENT_AREA определенной для сайта.
 * Предполагается использование в Desktop версии.
 */
export default function PageLayout({ leftSidebar, rightSidebar, children }) {
  return (
    <div className={styles.pageLayout}>
      <div className={cx(styles.sidebar, styles._left)}>{leftSidebar}</div>
      <MaxWidthWrapper className={styles.content}>
        {children}
      </MaxWidthWrapper>
      <div className={cx(styles.sidebar, styles._right)}>{rightSidebar}</div>
    </div>
  );
}

PageLayout.propTypes = {
  leftSidebar: PropTypes.node,
  rightSidebar: PropTypes.node,
};
