import PropTypes from 'prop-types';

import Button from 'core/components/Button';
import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import LoginModal from 'site/components/LoginModal';

import { setMetric } from 'site/utils';
import { METRICS } from 'site/constants';
import { BRANDING_CLASSNAME } from 'core/site/constants';

import styles from './index.styl';

const handleMoreMaterialsBtnClick = () => {
  setMetric(METRICS.regwall_more_material_click);
};

function RegWall({ theme }) {
  return (
    <div className={styles.regWall}>
      <style jsx>{`
        .${styles.title}
          font 500 15px/20px ${theme.fonts.display}

        :global(.${BRANDING_CLASSNAME}.desktop) .${styles.regWall}:before
            left -${theme.layout.indents.left}px
            right -${theme.layout.indents.right}px
      `}</style>
      <div className={styles.title}>
        Чтобы продолжить чтение необходимо зарегистрироваться или авторизоваться
      </div>
      <div className={styles.controls}>
        <div className={styles.control}>
          <LoginModal
            buttonText='Авторизоваться'
            modalTitle={<span>Авторизация</span>}
            metricsType='regwall_registration_click'
          />
        </div>
        <Link
          onClick={handleMoreMaterialsBtnClick}
          to='https://www.myglo.ru/about-glo/science?utm_source=yourchoice&utm_medium=reg-wall&utm_campaign=about-glo-science'
          className={styles.control}
        >
          <Button type='secondary'>Больше материалов</Button>
        </Link>
      </div>
    </div>
  );
}

RegWall.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(RegWall);
