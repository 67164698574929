import { useCallback } from 'react';
import Socializator from 'core/components/Socializator';
import { Description } from 'site/components/Texts';
import { METRICS } from 'site/constants';
import { setMetric } from 'site/utils';

import styles from './index.styl';

// TODO: Настройки сделать как были на старом сайте, особенно utm
const settings = {
  'buttons': [
    'vkontakte',
    'odnoklassniki',
  ],
  'vkontakte': {
    'utm': 'utm_source=vksharing&utm_medium=social',
  },
  'odnoklassniki': {
    'utm': 'utm_source=oksharing&utm_medium=social',
  },
};

function SocialShare() {
  const shareCallback = useCallback(() => {
    setMetric(METRICS.subscribe_popup_showed);
  }, []);

  return (
    <div className={styles.socialShare}>
      <Description tag='span' className={styles.socialShareTitle}>
        Поделиться:
      </Description>
      <div className={styles.socialShareSocials}>
        <Socializator
          shareCallback={shareCallback}
          {...settings}
        />
      </div>
    </div>
  );
}

export default SocialShare;
