import PropTypes from 'prop-types';
import { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'core/libs/router';
import { compose } from 'core/libs/recompose';

import themeSwitcherHOC from 'core/components/themeSwitcher';

import { getCookie, setCookie } from 'core/utils/cookie-helper';

import { Fullscreen } from 'site/components/Ad/AdByBreakpoint';
import ModalAgeWall from 'site/components/Modals/Wall';

import ContentMain from './ContentMain';
import ContentOzon from './ContentOzon';
import ContentPhone from './ContentPhone';

import { checkisOzonSpec, checkIsPhoneSpec, setMetric } from 'site/utils';
import {
  IS_ADULT,
  METRICS,
} from 'site/constants';

function AgeWall({ location }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const isSpecProject = isOzonSpec || isPhoneSpec;

  const [isOpen, setIsOpen] = useState(false);
  const [fullscreenIsReady, setFullscreenIsReady] = useState(false);

  const yesAnswerHandler = () => {
    const expiryDate = new Date();
    expiryDate.setMonth(new Date().getMonth() + 1);
    setCookie(IS_ADULT, true, { expires: expiryDate });
    /**
     * Показываем рекламное место fullscreen
     * через 10 секунд после ageWall
     */
    setTimeout(() => {
      setFullscreenIsReady(true);
    }, 10000);
    setMetric(METRICS.agegate_over18);
    setIsOpen(false);
  };

  const noAnswerHandler = () => {
    setMetric(METRICS.agegate_under18);
    window.location = 'https://www.google.com/';
  };

  const contentProps = {
    yesAnswerHandler,
    noAnswerHandler,
  };

  useEffect(() => {
    if (getCookie(IS_ADULT)) {
      setFullscreenIsReady(true);
    } else if (/isAdult=true/.test(location.search)) {
      yesAnswerHandler();
    } else {
      setIsOpen(true);
    }
  }, [location.search]);

  return (
    <>
      <ModalAgeWall
        isOpen={isOpen}
        hideCloseBtn
      >
        {!isSpecProject && <ContentMain {...contentProps} />}
        {isOzonSpec && <ContentOzon {...contentProps} />}
        {isPhoneSpec && <ContentPhone {...contentProps} />}
      </ModalAgeWall>
      {fullscreenIsReady && <Fullscreen />}
    </>
  );
}

AgeWall.propTypes = {
  location: PropTypes.object,
};

export default compose(
  themeSwitcherHOC('dark'),
  withRouter,
)(AgeWall);
