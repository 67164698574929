import Result from 'site/components/Result';

export default function LoginChange() {
  return (
    <Result
      title='Письмо отправлено'
      subTitle={(
        <>
          Мы отправили на указанную вами почту письмо для подтверждения <span style={{ whiteSpace: 'nowrap' }}>e-mail</span>.
          <br />
          Эту страницу можно закрыть.
        </>
      )}
    />
  );
}
