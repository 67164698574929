import { differenceInYears } from 'core/libs/date-fns';
/**
 * Регулярные выражения взяты отсюда.
 * При условии что имя и фамилия это только буквы.
 * https://github.com/validatorjs/validator.js/blob/master/src/lib/alpha.js
 * @type {{'ru-RU': RegExp, 'en-US': RegExp}}
 */
const alpha = {
  'en-US': /^[A-Z]+$/i,
  'ru-RU': /^[А-ЯЁ]+$/i,
};

export const requireValidation = (fieldName, fieldValue) => {
  if (fieldValue.trim() === '') {
    return `Поле "${fieldName}" обязательное`;
  }
  return null;
};

export const nameValidation = (fieldName, fieldValue) => {
  if (fieldValue.trim() === '') {
    return `Поле "${fieldName}" обязательное`;
  }

  // Пересечение русских и английских символов вместе недопустимо.
  if (!alpha['en-US'].test(fieldValue) && !alpha['ru-RU'].test(fieldValue)) {
    return 'Недопустимые символы';
  }

  if (fieldValue.trim().length < 2) {
    return `Для поля "${fieldName}" нужно по крайней мере два символа`;
  }
  return null;
};

/**
 * Если потребуется полная валидация, можно рассмотреть эту:
 * https://github.com/validatorjs/validator.js/blob/master/src/lib/isEmail.js
 */
export const emailValidation = email => {
  if (email.trim() === '') {
    return 'Поле "E-mail" обязательное';
  }

  return null;
};

export const firstNameValidation = firstname => {
  if (firstname.trim() === '') {
    return 'Поле "Ваше имя" обязательное';
  }

  return null;
};

/**
 * Если потребуется строгая валидация пароля, можно рассмотреть эту:
 * https://github.com/validatorjs/validator.js/blob/master/src/lib/isStrongPassword.js
 */
export const passwordValidation = password => {
  const PASSWORD_SIZE = 8;

  if (!password) {
    return 'Поле "Пароль" обязательное';
  }

  if (password.length < PASSWORD_SIZE) {
    return `Минимальная длина пароля ${PASSWORD_SIZE} символов`;
  }

  return null;
};

export const passwordsEqualValidation = (password1, password2) => {
  if (password1 !== password2) {
    return 'Пароли не совпадают';
  }
  return null;
};

export const birthdateValidation = dateString => {
  const today = new Date();
  const birthDate = new Date(dateString);
  const age = differenceInYears(today, birthDate);

  if (!dateString || typeof age !== 'number') {
    return 'Поле "Дата рождения" обязательное';
  }
  if (age < 18) {
    return 'Вам должно исполниться 18 лет';
  }

  if (age > 99) {
    return 'Возраст должен быть меньше 99 лет';
  }
  return null;
};

export const phoneValidation = phone => {
  if (!phone || phone.trim() === '') {
    return 'Поле "Телефон" обязательное';
  }

  // приводим телефон к формату +7XXXXXXXXXX
  const phoneValue = '+' + phone.replace(/[^\d]/g, '');

  if (phoneValue.length !== 12) {
    return 'Телефон не валидный';
  }
  return null;
};

export const termsAndConditionsValidation = value => {
  if (!value) {
    return 'Вы должны согласиться с пользовательским соглашением';
  }
  return null;
};

export const policyAndRulesValidation = value => {
  if (!value) {
    return 'Вы должны дать согласие на обработку персональных данных и согласиться с правилами участия';
  }
  return null;
};

export const autoSuggestValidation = (inputName, value, options) => {
  let error = requireValidation(inputName, value);

  if (!error && !options.some(suggestion => suggestion.value === value)) {
    error = `Некорректное значение для поля "${inputName}"`;
  }

  return error;
};
