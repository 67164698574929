import Button from 'core/components/Button';
import Link from 'core/components/Link';
import { Indent } from 'site/components/Wrappers';
import { ReadMoreCardTitle, ErrorNumber } from 'site/components/Texts';

import styles from './index.styl';

export default function NotFoundPage() {
  return (
    <div className={styles.container}>
      <ErrorNumber>404</ErrorNumber>
      <Indent top={20} />
      <ReadMoreCardTitle>Страница не найдена</ReadMoreCardTitle>
      <Indent bottom={50} />
      <Link to={'/'}>
        <Button>На главную страницу</Button>
      </Link>
    </div>
  );
}
