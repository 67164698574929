import PropTypes from 'prop-types';

import CoreIncut from 'core/components/Incut';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import Link from 'core/components/Link';
import { withBreakpoint } from 'core/components/breakpoint';

import LinkWithModal from 'site/components/LinkWithModal';

import styles from './index.styl';

function Incut({ block, parentProps, isMobile }) {
  const { theme } = parentProps;
  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.incutWrapper}:before
          color ${theme.colors.active1}
          font 400 48px/1 ${theme.fonts.nuance}
      `}</style>
    </scope>
  );

  return (
    <div className={scoped.wrapClassNames(styles.incutWrapper)}>
      <CoreIncut>
        <MarkdownWrapper
          components={{
            a({ href, children }) {
              if (isMobile && (href.includes('myglo.ru') || href.includes('vuse.ru'))) {
                return (
                  <LinkWithModal href={href}>
                    {children}
                  </LinkWithModal>
                );
              }
              return <Link to={href}>{children}</Link>;
            },
          }}
        >
          {block.attributes.body}
        </MarkdownWrapper>
      </CoreIncut>
      <scoped.styles />
    </div>
  );
}

Incut.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Incut);
