import cx from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { Desktop, Mobile } from 'core/components/breakpoint';

import Wave from 'site/images/wave';
import WaveMobile from 'site/images/wave_mobile.svg';
import SmallWave from 'site/images/small_wave.svg';
import SmallWaveMobile from 'site/images/small_wave_mobile.svg';
import WaveTravelAskTop from 'site/images/wave_travelask_top.svg';

import { checkIsPhoneSpec, checkisOzonSpec } from 'site/utils';

import styles from './index.styl';

function Waves({ location }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const isSpecProject = isOzonSpec || isPhoneSpec;

  if (isSpecProject) {
    return <Desktop><WaveTravelAskTop className={cx(styles.waves, styles._specPage)} /></Desktop>;
  }

  const withLightTheme = location.pathname === '/';

  return (
    <>
      <Desktop>
        {withLightTheme ? <Wave className={styles.waves} /> : <SmallWave className={styles.waves} />}
      </Desktop>
      <Mobile>
        {withLightTheme ? <WaveMobile className={styles.waves} /> : <SmallWaveMobile className={styles.waves} />}
      </Mobile>
    </>
  );
}

Waves.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Waves);
