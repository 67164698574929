export default {
  0: {
    imageHeight: 210,
    mobile: {
      imageHeight: 240,
      showBorder: true,
    },
  },
  1: {
    showTime: true,
    imageHeight: 210,
    mobile: {
      imageHeight: 240,
      showBorder: true,
    },
  },
  2: {
    readMore: true,
    showTime: true,
    imageHeight: 150,
    imageWidth: 200,
    noSidePaddings: true,
  },
};
