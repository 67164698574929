import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { prepareWidgetsBlock } from 'core/utils/content';

import bindProps from 'core/components/bindProps';
import TopicContent from 'core/components/TopicContent';
import Link from 'core/components/Link';

import LinkWithModal from 'site/components/LinkWithModal';
import { SetWidthLimitToContentWidth, NegativeMobile } from 'site/components/Wrappers';
import { Billboard as MobileBillboard, Slider } from 'site/components/Ad/mobile';
import { Billboard as DesktopBillboard } from 'site/components/Ad/desktop';
import TopicsGalleryBlue from 'site/components/TopicsGalleryBlue';
import AdsWrapper from 'site/components/Wrappers/AdsWrapper';

import * as siteWidgets from 'site/widgets';

const blocks = prepareWidgetsBlock(siteWidgets);

const relationships = resolveRelationships(['content'], {});

const ads = [0, 1].map((e, i) => {
  const isFirst = i === 0;
  return {
    before: isFirst ? 1800 : 2500,
    after: 200,
    showOnlyOnce: isFirst,
  };
});

const desktopAds = ads.map(item => {
  return {
    ...item,
    renderer: () => (
      <SetWidthLimitToContentWidth>
        <DesktopBillboard />
      </SetWidthLimitToContentWidth>
    ),
  };
});

const partners = [
  'myglo.ru',
  'vuse.ru',
];

function SiteTopicContent(props) {
  const {
    content,
    hideContent,
    list3Topics,
  } = props;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const BindedList3 = bindProps({ content: list3Topics })(TopicsGalleryBlue);

  const mobileAds = ads.map(item => {
    return {
      ...item,
      renderer: ({ index }) => {
        return (
          <NegativeMobile>
            {index === 1 ? (
              <AdsWrapper>
                <Slider
                  fallback={BindedList3}
                  placeholder={BindedList3}
                />
              </AdsWrapper>
            ) : (
              <MobileBillboard />
            )}
          </NegativeMobile>
        );
      },
    };
  });

  return (
    <TopicContent
      content={hideContent ? [widgets[0]] : widgets}
      desktopAds={desktopAds}
      mobileAds={mobileAds}
      blocks={blocks}
      markdownComponents={{
        a({ href, children }) {
          const isPartnerLink = partners.some(partner => href.includes(partner));

          if (isPartnerLink) {
            return (
              <LinkWithModal href={href}>
                {children}
              </LinkWithModal>
            );
          }
          return <Link to={href}>{children}</Link>;
        },
      }}
    />
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  hideContent: PropTypes.bool,
  theme: PropTypes.object,
  list3Topics: PropTypes.object,
};

export default SiteTopicContent;

