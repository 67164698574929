import PropTypes from 'prop-types';

function Indent(props) {
  const {
    children,
    top,
    bottom,
    left,
    right,
    tag: Tag,
  } = props;

  return (
    <Tag
      style={{
        ...top && { marginTop: `${top}px` },
        ...bottom && { marginBottom: `${bottom}px` },
        ...left && { marginLeft: `${left}px` },
        ...right && { marginRight: `${right}px` },
      }}
    >
      {children}
    </Tag>
  );
}

Indent.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  tag: PropTypes.string,
};


Indent.defaultProps = {
  tag: 'div',
};

export default Indent;
