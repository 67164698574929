import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { buildRubricUrl } from 'core/utils/url-helper';
import { topicDateFormat } from 'core/utils/dates';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Link from 'core/components/Link';
import Lead from 'core/components/Lead';
import Image from 'core/components/Image';

import { MaxWidthWrapper, NegativeMobile } from 'site/components/Wrappers';
import {
  RubricName,
  MainTitle,
} from 'site/components/Texts';

import {
  LAYOUT_MAX_WIDTH_MOBILE,
  CONTENT_AREA,
} from 'site/constants';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['rubric', 'image'],
  {},
  { image: { versions: {} } },
);

function TopicHeader(props) {
  const {
    content,
    isMobile,
    theme,
  } = props;

  const {
    attributes: {
      announce,
      headline,
      published_at: published,
    },
  } = content;

  const {
    image: {
      versions,
      caption,
    },
    rubric: {
      title: rubricTitle,
      slug: rubricSlug,
      root_slug: rubricRoot,
    },
  } = relationships(content);

  const vertical = isMobile ? 15 : 20;
  const maxWidth = isMobile ? LAYOUT_MAX_WIDTH_MOBILE : CONTENT_AREA;
  const maxHeight = isMobile ? 360 : 480;
  const altAndTitle = caption || headline;

  const CoverWrapper = isMobile ? NegativeMobile : MaxWidthWrapper;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.topicHeader}
          margin-bottom ${vertical}px

        .${styles.media}
        .${styles.announce}
          margin-top ${vertical}px
          margin-bottom ${vertical}px

        .${styles.media}
          &:before
            background ${theme.colors.primary}

        .${styles.rubricTitle}
          text-decoration none
          color ${theme.colors.primary}
          &:hover
            color ${theme.colors.active1}
          &:active
            color ${theme.colors.active3}
          &:visited
            color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <>
      <div className={scoped.wrapClassNames(styles.topicHeader)}>
        <Link
          className={scoped.wrapClassNames(styles.rubricTitle)}
          to={buildRubricUrl(rubricSlug, rubricRoot)}
          type='blank'
        >
          <RubricName tag='span'>
            {rubricTitle}
          </RubricName>
        </Link>

        <RubricName tag='time' className={styles.published}>
          {topicDateFormat(published)}
        </RubricName>

        <MaxWidthWrapper>
          <MainTitle tag='h1'>
            {headline}
          </MainTitle>
        </MaxWidthWrapper>
      </div>

      <MaxWidthWrapper className={scoped.wrapClassNames(styles.announce)}>
        <Lead>{announce}</Lead>
      </MaxWidthWrapper>

      <div className={scoped.wrapClassNames(styles.media)}>
        <CoverWrapper>
          <figure className={styles.image}>
            <Image
              versions={versions}
              widthFromSrc
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              placement='contain'
              alt={altAndTitle}
              title={altAndTitle}
              fetchPriority='high'
              instant
            />
          </figure>
        </CoverWrapper>
      </div>
      <scoped.styles />
    </>
  );
}

TopicHeader.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(TopicHeader));
