import { Route, Switch } from 'core/libs/router';

import { PageIndent } from 'site/components/Wrappers';

import ProfileLayout from './Layout';
import Personal from './Personal';
import Edit from './Edit';
import ChangePassword from './ChangePassword';
import LoginChange from './LoginChange';
import LoginChangeSent from './LoginChangeSent';
import LoginChangeConfirm from './LoginChangeConfirm';

export const profileRoutes = [
  {
    exact: true,
    path: '/profile',
    render: routeParams => (
      <ProfileLayout pageName='Личный кабинет' {...routeParams}>
        <Personal />
      </ProfileLayout>
    ),
  }, {
    exact: true,
    path: '/profile/edit',
    render: routeParams => (
      <ProfileLayout pageName='Редактирование данных' {...routeParams}>
        <Edit />
      </ProfileLayout>
    ),
  }, {
    exact: true,
    path: '/profile/change_password',
    render: routeParams => (
      <ProfileLayout pageName='Смена пароля' {...routeParams}>
        <ChangePassword />
      </ProfileLayout>
    ),
  }, {
    exact: true,
    path: '/profile/change_email',
    render: routeParams => (
      <ProfileLayout pageName='Смена e-mail' {...routeParams}>
        <LoginChange />
      </ProfileLayout>
    ),
  }, {
    exact: true,
    path: '/profile/change_email_sent',
    render: routeParams => (
      <ProfileLayout pageName='Смена e-mail' {...routeParams}>
        <LoginChangeSent />
      </ProfileLayout>
    ),
  }, {
    exact: true,
    path: '/profile/login_change_confirm',
    render: routeParams => (
      <ProfileLayout pageName='Смена e-mail' {...routeParams}>
        <LoginChangeConfirm />
      </ProfileLayout>
    ),
  },
];


export default function Profile() {
  return (
    <PageIndent>
      <Switch>
        {profileRoutes.map((routeParams, i) => (
          <Route key={i} {...routeParams} />
        ))}
      </Switch>
    </PageIndent>
  );
}
