import { Fragment, useCallback, useState } from 'react';
import { compose } from 'core/libs/recompose';

import H2 from 'core/components/H2';
import { withRouter } from 'core/libs/router';

import skip from 'core/resolver/skip';
import resolve from 'core/resolver/resolve';

import BaseForm from 'site/components/BaseForm';
import withBatId from 'site/components/BatId/withBatId';
import { Indent } from 'site/components/Wrappers';

import { profilePropTypes } from 'site/utils/prop-types/model';
import batIdPropTypes from 'site/utils/prop-types/batId';
import { emailValidation, passwordValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import { VERTICAL_INDENT } from 'site/constants';

import Form from './Form';
import PropTypes from 'prop-types';

const initialValues = {
  password: '',
  login: '',
};

const validate = {
  login: emailValidation,
  password: passwordValidation,
};

function LoginChange({ batId, profile, history }) {
  const {
    first_name: firstName,
    last_name: lastName,
  } = profile;

  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  const submitCallback = useCallback(values => {
    return batId.changeLogin(values)
      .then(batId.setSession)
      .then(() => history.push('/profile/change_email_sent'))
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [history, batId]);


  return (
    <>
      <H2 is='h1'>{firstName + ' ' + lastName}</H2>
      <Indent top={VERTICAL_INDENT} />
      <BaseForm
        form={Form}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </>
  );
}

LoginChange.propTypes = {
  batId: batIdPropTypes,
  profile: profilePropTypes,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default compose(
  skip,
  withBatId,
  withRouter,
  resolve('profile', ({ batId }) => {
    return batId.getProfile();
  }),
)(LoginChange);
