import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactAutoSuggest from 'react-autosuggest';

import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { Description, FieldName } from 'site/components/Texts';
import Error from 'site/components/Error';

import styles from './index.styl';


class AutoSuggest extends Component {
  state = {
    value: this.props.value,
    suggestions: [],
  };

  getSuggestions = value => {
    const { options } = this.props;
    const escapedValue = value.trim();

    if (escapedValue === '') return [];

    const regex = new RegExp('^' + escapedValue, 'i');
    return options.filter(language => regex.test(language.label));
  };

  handleChange = (event, { newValue }) => {
    this.props.onChange({ name: this.props.name, value: newValue });
    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const {
      label,
      name,
      placeholder,
      icon: Icon,
      disabled,
      theme,
      onBlur,
      error,
      size,
      hint,
    } = this.props;

    const {
      colors: colorAtoms,
      controls: {
        autoSuggest: autoSuggest,
        autoSuggest: {
          const: {
            sizes: {
              [size]: autoSuggestSizeAtoms,
            },
          },
          basic: autoSuggestStateAtoms,
          highlight: autoHighlightStateAtoms,
        },
      },
    } = theme;

    const { value, suggestions } = this.state;

    const scope = resolveScopedStyles(
      <scope>
        <style jsx>{`
          .text
          .${styles.hint}
            color ${colorAtoms.primary}
        `}</style>
      </scope>
    );

    return (
      <label className={cx(styles.autoSuggest, error && '_error')}>
        <style jsx>{`
          ._error
            :global(.react-autosuggest__input)
              border ${autoSuggestStateAtoms.error.border}
              background-color ${autoSuggestStateAtoms.error.background}
              box-shadow ${autoSuggestStateAtoms.error.boxShadow}

            :global(.react-autosuggest__suggestions-container--open)
              border ${autoSuggestStateAtoms.error.border}
              border-top none

            :global(.react-autosuggest__suggestion--highlighted)
              background ${autoHighlightStateAtoms.error.background}
        `}</style>
        <style global jsx>{`
          .react-autosuggest__input
            color ${autoSuggest.const.color}
            font ${autoSuggestSizeAtoms.font}
            height ${autoSuggestSizeAtoms.height}
            border-radius ${autoSuggest.const.radius}
            border ${autoSuggestStateAtoms.idle.border}
            background-color ${autoSuggestStateAtoms.idle.background}
            padding ${autoSuggestSizeAtoms.padding}
            transition all ${theme.animations.hover}
            box-shadow ${autoSuggestStateAtoms.idle.boxShadow}
            
            @media screen and (-webkit-min-device-pixel-ratio: 0)
              // disable page zoom with input:focus on iOS devices
              font-size 16px

            &[disabled]
              color ${autoSuggestStateAtoms.disabled.color || autoSuggest.const.color}
              border ${autoSuggestStateAtoms.disabled.border}
              background-color ${autoSuggestStateAtoms.disabled.background}

              &::placeholder
                color ${autoSuggestStateAtoms.disabled.color || autoSuggest.const.color}
              &:-ms-input-placeholder
                color ${autoSuggestStateAtoms.disabled.color || autoSuggest.const.color}
          
          .react-autosuggest__suggestions-container--open
            border ${autoSuggestStateAtoms.idle.border}
            border-top none
            background-color ${autoSuggestStateAtoms.idle.background}
            font ${autoSuggestSizeAtoms.font}
            
          .react-autosuggest__suggestion--highlighted
            background ${autoHighlightStateAtoms.idle.background}
        `}</style>
        {label && <FieldName className={scope.wrapClassNames(styles.name)}>{label}</FieldName>}
        <div className={styles.input}>
          <ReactAutoSuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={suggestion => suggestion.value}
            renderSuggestion={suggestion => <span>{suggestion.label}</span>}
            inputProps={{
              placeholder,
              disabled,
              name,
              value,
              onChange: this.handleChange,
              onBlur,
              autoComplete: 'none',
            }}
          />
          {Icon && <span className={styles.icon}><Icon /></span>}
        </div>
        {hint && <Description className={scope.wrapClassNames(styles.hint)}>{hint}</Description>}
        <Error error={error} />
        <scope.styles />
      </label>
    );
  }
}

AutoSuggest.defaultProps = {
  size: 'medium',
};

AutoSuggest.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  theme: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  icon: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * Подсказка, что делает это поле
   */
  hint: PropTypes.string,
};

export default withTheme(AutoSuggest);
