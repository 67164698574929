import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';

import withoutSSR from 'core/components/withoutSSR';

import { withRouter } from 'core/libs/router';


function YaCaptcha(props) {
  const {
    location: { search },
  } = props;

  const [success, setSuccess] = useState(false);
  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);
  const handleChallengeHidden = useCallback(() => {
    if (success) return;
    window.location = '/bot-landing';
  }, [success]);

  const captchaTest = Boolean(new URL(`https://yc.mock/${search}`).searchParams.get('captchaTest'));

  return (
    <InvisibleSmartCaptcha
      sitekey='ysc1_mCJxr5KduL245od2jv8EG61sBntRVlJwXZEFTg2z7788add8'
      test={captchaTest}
      visible={!success}
      onSuccess={handleSuccess}
      onChallengeHidden={handleChallengeHidden}
      hideShield
    />
  );
}

YaCaptcha.propTypes = {
  location: PropTypes.object,
};

export default withoutSSR(withRouter(YaCaptcha));
