import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import MasonryFeed from 'site/components/MasonryFeed';

import { Ad280x430 } from 'site/components/Ad/desktop';
import { Ad320x492, Billboard } from 'site/components/Ad/mobile';

function List2({ content, isMobile }) {
  return (
    <MasonryFeed
      content={content}
      columns={4}
      interitemSpacing={20}
      cardsReplacement={{
        1: replaceCardWithAd(isMobile ? Ad320x492 : Ad280x430),
      }}
      cardsMobileAdditional={{
        4: Billboard,
      }}
    />
  );
}

List2.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(List2);
