import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';

import { withBreakpoint } from 'core/components/breakpoint';

import { ru } from 'core/libs/date-fns';

import Calendar from 'site/icons/Calendar';
import Input from 'site/components/Input';

import './datepicker.styl';

registerLocale('ru', ru);

function Datepicker({
  value,
  onChange,
  onBlur,
  error,
  label,
  name,
  isMobile,
  className,
  disabled,
}) {
  if (isMobile) {
    return (
      <Input
        type='date'
        label={label}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        className={className}
        disabled={disabled}
      />
    );
  }

  const onChangeDate = (date) => {
    const offsetBirthdate = new Date();
    offsetBirthdate.setTime(date.getTime() + Math.abs(date.getTimezoneOffset()) * 60 * 1000);
    onChange({ value: !date.getHours() ? offsetBirthdate : date, name, type: 'datepicker' });
  };

  return (
    <>
      <DatePicker
        selected={value}
        onChange={onChangeDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        maxDate={new Date()}
        locale='ru'
        dateFormat='dd.MM.yyyy'
        disabled={disabled}
        customInput={(
          <InputMask
            mask='99.99.9999'
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={className}
            name={name}
            label={label}
            error={error}
          >
            <Input icon={Calendar} iconPosition='after' />
          </InputMask>
        )}
      />
    </>
  );
}

Datepicker.propTypes = {
  /** Объект даты */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string, // пустая строка
  ]).isRequired,
  /** Коллбек изменения даты */
  onChange: PropTypes.func.isRequired,
  /** Обработчик потери фокуса с элемента */
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.string,
  /** Подпись к полю с датой */
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  className: PropTypes.string,
  /** Свойство, отключающее поле */
  disabled: PropTypes.bool,
};

const DatepickerWithHOCs = withBreakpoint(Datepicker);
DatepickerWithHOCs.displayName = 'Datepicker';

export default DatepickerWithHOCs;
export { Datepicker as StorybookComponent };
