export default {
  0: {
    imageHeight: 430,
    mobile: {
      imageHeight: 490,
    },
  },
  1: {
    showTime: true,
    imageHeight: 430,
    mobile: {
      imageHeight: 490,
      showTime: true,
    },
  },
};
