import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from 'site/components/Input';
import { Indent } from 'site/components/Wrappers';
import { ErrorMessage, LinkSmall } from 'site/components/Texts';
import Link from 'core/components/Link';
import Button from 'core/components/Button';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';


const LoginChangeForm = (props) => {
  const {
    handleInputChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    serverErrorName,
  } = props;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          name='password'
          label='Текущий пароль'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password ? errors.password : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='email'
          name='login'
          label='Новый e-mail'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.login}
          error={touched.login && errors.login ? errors.login : ''}
        />
      </div>

      <Indent top={VERTICAL_INDENT} />
      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit'>Отправить</Button>
        <Link type='secondary' to='/profile'>
          <LinkSmall>Отмена</LinkSmall>
        </Link>
      </div>
      {serverErrorName && <ErrorMessage className={styles.errorMessage}>{serverErrorName}</ErrorMessage>}
    </form>
  );
};

LoginChangeForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
};

export default LoginChangeForm;
