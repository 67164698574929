import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { topicDateFormat } from 'core/utils/dates';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { RubricName, CardTitle, ReadMoreCardTitle } from 'site/components/Texts';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric'];

const relationships = resolveRelationships(['image', 'rubric'], {}, {
  image: {
    versions: {},
  },
});

function Card1(props) {
  const {
    content,
    theme,
    type,
    breakpoint,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: published,
  } = content.attributes;

  const {
    link_attrs: linkAttrs,
  } = content.extra || {};

  const {
    showTime,
    readMore,
    imageHeight,
    showBorder,
    noSidePaddings,
  } = (mapTypes[type] || {})[breakpoint] || mapTypes[type] || {};

  const {
    image: {
      versions,
    },
    rubric: {
      title: rubricTitle,
    },
  } = relationships(content);

  const Headline = readMore ? ReadMoreCardTitle : CardTitle;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.card}
          &.${styles._showBorder}
            border 1px solid ${theme.colors.grey3}

        .rubric
          color ${theme.colors.grey2}
          margin-top 5px

        .${styles.cardTextContent}
          :global(.mobile) &
            padding 10px 15px 15px 15px
            &._noSidePaddings
              padding 10px 0 0

        .${styles.imageContainer}
          border-radius 4px
          overflow hidden
          .${styles._showBorder} &
            border-radius 0
      `}</style>
    </scope>
  );

  return (
    <div className={scoped.wrapClassNames(
      styles.card,
      showBorder && styles._showBorder)}
    >
      <Link
        to={link}
        type='card'
        {...linkAttrs && {
          innerRef: node => node && linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value)),
        }}
      >
        <div className={scoped.wrapClassNames(styles.imageContainer)}>
          {versions['8_small'] ? (
            <Image
              height={imageHeight}
              versions={versions}
              version='8_small'
            />
          ) : (
            <>
              <div className={styles.overlay} />
              <div style={{ height: imageHeight + 'px' }} />
            </>
          )}
        </div>
        <div className={scoped.wrapClassNames(
          styles.cardTextContent,
          noSidePaddings && '_noSidePaddings'
        )}
        >
          <Headline className={scoped.wrapClassNames(styles.title)}>
            {headline}
          </Headline>
          <RubricName className={scoped.wrapClassNames('rubric')}>
            {!showTime && rubricTitle}
            {showTime && topicDateFormat(published)}
          </RubricName>
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card1.defaultProps = {
  type: 0,
};

Card1.propTypes = {
  /** Данные для карточки */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** @ignore */
  breakpoint: PropTypes.string,
};

const Card = cardHOC(withBreakpoint(Card1));

Card.requiredPayloadImports = requiredPayloadImports;
Card.displayName = 'Card1';

export const Card1Type1 = bindProps({ type: 1 })(Card);
export const Card1Type2 = bindProps({ type: 2 })(Card);

export default Card;
export { Card1 as StorybookComponent };
