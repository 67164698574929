import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

const bgImage = require('site/images/spec_phone_background.jpg');

function NeonBody({ theme, location }) {
  const isSignedPage = !!~location.pathname.search(/^\/special-reg-phone100ftb\/signed\/?/);

  const {
    colors: {
      layout: layoutColor,
    },
  } = theme;

  const scopedBgStyles = resolveScopedStyles(
    <scope>
      <style global jsx>{`
        body
          &.desktop
          &.mobile
            background-color ${layoutColor}

          &:before
            display none

      `}</style>
    </scope>
  );

  let scopedBgImageStyles;

  if (isSignedPage) {
    scopedBgImageStyles = resolveScopedStyles(
      <scope>
        <style global jsx>{`
          body
            background-image url(${bgImage})
            background-position center top
            background-repeat no-repeat
            background-size contain
            backdrop-filter blur(64px)

            &.mobile
              backdrop-filter blur(20px)
        `}</style>
      </scope>
    );
  }

  return (
    <>
      <scopedBgStyles.styles />
      {scopedBgImageStyles && <scopedBgImageStyles.styles />}
    </>
  );
}

NeonBody.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
};

export default withRouter(withTheme(NeonBody));
