import PropTypes from 'prop-types';

import Image from 'core/components/Image';

import withTheme from 'core/components/theme';

import styles from './index.styl';

const mapTitles = {
  1: {
    title: <span>Зарегистрируйся<br /> и&nbsp;получи сертификат</span>,
  },
  2: {
    title: <span>Подтверди почту<br /> и&nbsp;получи сертификат</span>,
  },
};

function Header({ theme, type }) {
  const { title } = mapTitles[type] || {};

  return (
    <div className='header'>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}
      `}</style>
      <h1 className={styles.title}>{title}</h1>

      {type === 2 && (
        <div className={styles.envelope}>
          <Image
            url={require('./images/envelope.png')}
            width={124}
            height={80}
          />
        </div>
      )}
      <div className={styles.money}>
        <Image
          url={require('./images/ozon_100.png')}
          width={304}
          height={192}
        />
      </div>
    </div>
  );
}

Header.propTypes = {
  theme: PropTypes.object,
  isSuccessPage: PropTypes.bool,
  type: PropTypes.oneOf([1, 2]),
};

export default withTheme(Header);


