import bindProps from 'core/components/bindProps';

import Ad from '.';

// eslint-disable-next-line import/prefer-default-export
export const Billboard = bindProps({
  name: 'Billboard',
  height: 250,
  ownerId: 264483,
  params: {
    p1: 'cpave',
    p2: 'hfct',
  },
})(Ad);

export const StickyLine = bindProps({
  name: 'Shtorka',
  ownerId: 264483,
  params: {
    p1: 'cpavf',
    p2: 'hfcu',
  },
})(Ad);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  ownerId: 264483,
  params: {
    p1: 'cpctw',
    p2: 'hfhg',
  },
})(Ad);

export const Ad320x492 = bindProps({
  name: 'Ad320x492',
  ownerId: 264483,
  params: {
    p1: 'cpcuq',
    p2: 'hfhj',
  },
})(Ad);

export const Slider = bindProps({
  name: 'Slider',
  ownerId: 264483,
  params: {
    p1: 'cqwpb',
    p2: 'hinx',
  },
})(Ad);

export const BillboardSpec = bindProps({
  name: 'BillboardSpec',
  ownerId: 264483,
  params: {
    p1: 'csbtl',
    p2: 'hfct',
  },
})(Ad);

export const Footer = bindProps({
  name: 'Footer',
  ownerId: 264483,
  params: {
    p1: 'csadw',
    p2: 'hllm',
  },
})(Ad);
