import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import TagTopics from 'core/components/TagTopics';

import { PageIndent } from 'site/components/Wrappers';
import RubricOrTagTopics from 'site/components/RubricOrTagTopics';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

import {
  RUBRICS_OR_TAGS_LIMIT,
  RUBRICS_OR_TAGS_LIMIT_MOBILE,
} from 'site/constants';


function TagPage({ tag, topics, isMobile }) {
  const limit = isMobile ? RUBRICS_OR_TAGS_LIMIT_MOBILE : RUBRICS_OR_TAGS_LIMIT;

  return (
    <>
      <Billboard />
      <PageIndent>
        <TagTopics
          tag={tag}
          topics={topics}
          limit={limit}
        >
          {RubricOrTagTopics}
        </TagTopics>
      </PageIndent>
    </>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: props => topicsFetcher({
    limit: props.isMobile ? RUBRICS_OR_TAGS_LIMIT_MOBILE : RUBRICS_OR_TAGS_LIMIT,
    include: 'image,rubric',
  })(props),
});

export default withBreakpoint(withPageHocs(dataProvider)(TagPage));
