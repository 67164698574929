import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';
import { topicsFetcher } from 'core/fetchers/topics';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import AuthorsPage from 'core/components/AuthorsPage';

import { withBreakpoint } from 'core/components/breakpoint';

import H1 from 'core/components/H1';

import { Indent, PageIndent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { Billboard } from 'site/components/Ad/AdByBreakpoint';

import Card2 from 'site/cards/Card2';

import Boroda from './Boroda';


function Authors({ rawAuthors, borodaTopics, isMobile }) {
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <>
      <Billboard />
      <PageIndent>
        <H1>Авторы</H1>
        <Indent bottom={vertical} />
        <AuthorsPage rawAuthors={rawAuthors} />
        <Indent bottom={vertical} />
        <Boroda content={borodaTopics} />
      </PageIndent>
    </>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
  borodaTopics: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
  borodaTopics: topicsFetcher({
    sort: '-views_count',
    limit: 8,
    card: Card2,
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
