import PropTypes from 'prop-types';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import { withRouter } from 'core/libs/router';

import Input from 'site/components/Input';
import { Indent } from 'site/components/Wrappers';
import { ErrorMessage, LinkSmall, LinkMedium } from 'site/components/Texts';
import Link from 'core/components/Link';
import Button from 'core/components/Button';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';


const LoginChangeForm = (props) => {
  const {
    handleInputChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    location,
    serverErrorName,
    serverErrorStatus,
  } = props;

  const { password } = location.state || {};

  const smsSent = serverErrorStatus === 400;

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <InputMask
          mask='+7 (999) 999-99-99'
          value={values.phone}
          onChange={handleInputChange}
          onBlur={handleBlur}
        >
          <Input
            type='tel'
            label='Телефон'
            name='phone'
            error={touched.phone && errors.phone ? errors.phone : ''}
          />
        </InputMask>
      </div>

      <Indent top={VERTICAL_INDENT} />
      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit'>Отправить</Button>
        <Link type='secondary' to='/profile'>
          <LinkSmall>Отмена</LinkSmall>
        </Link>
      </div>
      {serverErrorName && <ErrorMessage className={styles.errorMessage}>{serverErrorName}</ErrorMessage>}
      {smsSent && (
        <Link
          type='secondary'
          to={{
            pathname: '/signup/phone_confirm',
            state: { phone: values.phone, password },
          }}
        >
          <LinkMedium>Ввести смс код</LinkMedium>
        </Link>
      )}
    </form>
  );
};

LoginChangeForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
  serverErrorStatus: PropTypes.number,
  /** @ignore */
  location: PropTypes.object,
};

export default withRouter(LoginChangeForm);
