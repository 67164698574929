import { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { compose } from 'core/libs/recompose';
import skip from 'core/resolver/skip';
import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';

import H2 from 'core/components/H2';
import Button from 'core/components/Button';

import CustomLink from 'site/components/CustomLink';
import Input from 'site/components/Input';
import withBatId from 'site/components/BatId/withBatId';

import { Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import { LinkSmall } from 'site/components/Texts';
import Timer from 'site/components/Timer';

import { handleResponseErrors } from 'site/utils/formHelpers';
import batIdPropTypes from 'site/utils/prop-types/batId';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';

function PhoneConfirmForm(props) {
  const {
    theme,
    handleSubmit,
    handleInputChange,
    handleBlur,
    errors,
    touched,
    values,
    batId,
  } = props;

  const [sent, setSent] = useState(true);
  const [serverErrorName, setServerErrorName] = useState(props.serverErrorName);

  useEffect(() => {
    setServerErrorName(props.serverErrorName);
  }, [props.serverErrorName]);

  const handleSms = useCallback(event => {
    event.preventDefault();

    return batId.verifyPhoneResend(values)
      .then(() => {
        setServerErrorName(null);
        setSent(true);
      })
      .catch(({ response }) => {
        setServerErrorName(handleResponseErrors(response).error);
        setSent(false);
      });
  }, [batId, values]);

  return (
    <MaxWidthWrapper>
      <style jsx>{`
        .timer
          color ${theme.colors.grey1}
      `}</style>
      <H2 is='h1'>Подтверждение номера телефона</H2>

      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          type='hidden'
          name='phone'
          value={values.phone}
        />
        <div className={cx(styles.row, styles.field)}>
          <InputMask
            mask='9999'
            value={values.key}
            onChange={handleInputChange}
            onBlur={handleBlur}
          >
            <Input
              name='key'
              type='text'
              label='Введите код из СМС'
              autoComplete='one-time-code'
              inputMode='numeric'
              error={touched.key && errors.key || serverErrorName}
            />
          </InputMask>
        </div>
        <LinkSmall className={styles.phoneConfirm}>
          {sent
            ? (
              <div className='timer'>
                Отправить еще раз можно через <Timer resetTimer={() => setSent(!sent)} />
              </div>
            ) : (
              <CustomLink
                type='secondary'
                to='/signup/phone_confirm'
                onClick={handleSms}
              >
                Отправить код на телефон
              </CustomLink>
            )
          }
        </LinkSmall>

        <Indent top={VERTICAL_INDENT} />
        <div className={cx(styles.field, styles.footer)}>
          <Button typeAttribute='submit'>Подтвердить</Button>
        </div>
      </form>
    </MaxWidthWrapper>
  );
}

PhoneConfirmForm.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
  batId: batIdPropTypes,
};

export default compose(
  skip,
  withBatId,
  withRouter,
  withTheme
)(PhoneConfirmForm);
