import { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Modal from 'core/components/Modal';
import Button from 'core/components/Button';
import { withBreakpoint } from 'core/components/breakpoint';

import Login from 'site/pages/login/Login';

import batIdPropTypes from 'site/utils/prop-types/batId';
import { setMetric } from 'site/utils';

import withBatId from 'site/components/BatId/withBatId';
import LoginHeader from 'site/components/LoginHeader';

import { LOGIN_FORM_HASH, METRICS } from 'site/constants';

import styles from './index.styl';


function LoginModal(props) {
  const {
    location,
    history,
    batId,
    buttonText,
    metricsType,
    modalTitle,
    isMobile,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(location.hash === LOGIN_FORM_HASH);
  }, [location]);

  const toggleLoginForm = useCallback(() => {
    const state = { from: location.pathname };
    metricsType && setMetric(METRICS[metricsType]);
    if (isMobile) {
      history.push('/login', state);
      return;
    }

    if (isOpen) {
      history.replace({ hash: '' });
    } else {
      history.replace(LOGIN_FORM_HASH, state);
    }
  }, [isMobile, isOpen, history, location, metricsType]);

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalWindow
          background transparent
      `}</style>
    </scope>
  );

  return (
    <>
      {!batId.isAuthorized && (
        <div className={styles.login}>
          <Button onClick={toggleLoginForm}>{buttonText}</Button>
        </div>
      )}
      <Modal
        hideCloseBtn
        isOpen={isOpen}
        scopedStyles={scopedStyles}
      >
        <Login
          hasContentBg
          titleComponent={(
            <LoginHeader
              title={modalTitle}
              onClose={toggleLoginForm}
            />
          )}
        />
      </Modal>
    </>
  );
}

LoginModal.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  batId: batIdPropTypes,
  buttonText: PropTypes.node,
  modalTitle: PropTypes.node,
  isMobile: PropTypes.bool,
  metricsType: PropTypes.string,
};


export default withRouter(withBatId(withBreakpoint(LoginModal)));
