import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

import { get } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';

import { getCookie, setCookie } from 'core/utils/cookie-helper';

import { setMetric } from 'site/utils';
import { JWT_ACCESS_TOKEN, METRICS } from 'site/constants';

import BatIdContext from '.';
import WingnutApi from './wingnutApi';

const initialState = {
  isAuthorized: false,
  resolved: false,
};

/**
 * Определяем список страниц исключений, на которые не нужно редиректить
 * после успешного логина
 */
const REJECTED_PATHS_FOR_REDIRECT = [
  '/signup/email_confirm',
  '/profile/login_change_confirm',
];

class BatIdProvider extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    wingnutApi: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.wingnutApi = new WingnutApi(props.wingnutApi);
    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    // нет токена
    if (!this.accessToken) {
      this.setState({ // eslint-disable-line
        isAuthorized: false,
        resolved: true,
      });
      return;
    }

    // пустая сессия (при изменении логин, пользователь должен перелогиниться)
    if (this.accessTokenWithEmptySession) {
      this.setState({ // eslint-disable-line
        isAuthorized: false,
        resolved: true,
      });
    }

    // есть просроченый токен
    if (this.tokenTimeWasExpire) {
      this.refreshSession();
      return;
    }

    // есть актуальный токен
    this.setSession();
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
  }

  get accessToken() {
    if (!process.env.BROWSER_RUNTIME) return null;
    return getCookie(JWT_ACCESS_TOKEN);
  }

  get tokenTimeWasExpire() {
    return Date.now() >= this.accessTokenExpirationTime;
  }

  get accessTokenExpirationTime() {
    return jwtDecode(this.accessToken).exp * 1000;
  }

  get hcAuthString() {
    return this.accessToken
      ? jwtDecode(this.accessToken).hc_auth_string
      : null;
  }

  get accessTokenWithEmptySession() {
    /**
     * Проверяем наличие account_id.
     * Если пользователь сменил логин, получаем короткий access_tokent (без account_id).
     */
    return this.accessToken
      ? !jwtDecode(this.accessToken).account_id
      : true;
  }

  setSession = newTokens => {
    /**
     * После успешной авторизации пользователя, получаем jwt-access-token в Base64 формате.
     * tokens.access_token
     *  ключ для получения данных о пользователе (срок действия 30 минут, 3 минуты на стейдж)
     *  expiration time находится внутри токена (парсится утилитой jwtDecode).
     *
     * refresh_token хранится в httpOnly куке (ee невозможно прочитать средствами JS)
     *  передается запросом в request headers.
     *  на клиенте этот ключ никак не обрабатывается.
     */

    if (newTokens) {
      setCookie(JWT_ACCESS_TOKEN, newTokens.access_token, {
        SameSite: 'Lax',
        Secure: true,
      });
    }

    this.wingnutApi.accessToken = this.accessToken;

    this.setState({
      isAuthorized: !this.accessTokenWithEmptySession,
      resolved: true,
    });
    this.startRefreshSessionTimeout();
  };

  loginRedirect = () => {
    const { location, history } = this.props;
    const from = get(location, 'state.from', '/');
    const to = REJECTED_PATHS_FOR_REDIRECT.some(path => path === from.pathname) ? '/' : from;

    history.replace(to);
  };

  login = ({ values }) => {
    return this.wingnutApi.login(values)
      .then(this.setSession)
      .then(() => setMetric(METRICS.signin_success));
  };

  logout = () => {
    return this.wingnutApi.logout()
      .then(() => {
        this.setState({
          isAuthorized: false,
          resolved: true,
        });
        setCookie(JWT_ACCESS_TOKEN, '', { 'Max-Age': -1 });
        clearTimeout(this.refreshTimeout);
      }).catch(() => {
        this.setState({
          resolved: true,
        });
      });
  };

  /**
   * Аутентификация пользователя по refreshToken.
   * Токен хранится в httpOnly куке.
   */
  refreshSession = () => {
    this.wingnutApi.accessToken = this.accessToken;

    return this.wingnutApi.jwtRefresh()
      .then(this.setSession)
      .catch(this.logout);
  };

  startRefreshSessionTimeout = () => {
    // Запрашиваем новый токен за минуту до истечения старого
    const time = this.accessTokenExpirationTime - Date.now() - 60 * 1000;
    this.refreshTimeout = setTimeout(this.refreshSession, time);
  };

  render() {
    return (
      <BatIdContext.Provider
        value={{
          login: this.login,
          loginRedirect: this.loginRedirect,
          logout: this.logout,
          createAccount: this.wingnutApi.createAccount,
          getProfile: this.wingnutApi.getProfile,
          changePassword: this.wingnutApi.changePassword,
          changeLogin: this.wingnutApi.changeLogin,
          updateProfile: this.wingnutApi.updateProfile,
          verifyLoginChange: this.wingnutApi.verifyLoginChange,
          setSession: this.setSession,
          verifyPhone: this.wingnutApi.verifyPhone,
          verifyPhoneResend: this.wingnutApi.verifyPhoneResend,
          verifyAccount: this.wingnutApi.verifyAccount,
          verifyAccountResend: this.wingnutApi.verifyAccountResend,
          resetPassword: this.wingnutApi.resetPassword,
          resetPasswordRequest: this.wingnutApi.resetPasswordRequest,
          createSubscription: this.wingnutApi.createSubscription,
          verifySubscription: this.wingnutApi.verifySubscription,
          createTravelAskSubscription: this.wingnutApi.createTravelAskSubscription,
          verifyTravelAskSubscription: this.wingnutApi.verifyTravelAskSubscription,
          resendPhoneTravelAskSubscription: this.wingnutApi.resendPhoneTravelAskSubscription,
          hcAuthString: this.hcAuthString,
          ...this.state,
        }}
      >
        {this.props.children}
      </BatIdContext.Provider>
    );
  }
}

export default withRouter(BatIdProvider);
