import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';
import { Desktop, Mobile } from 'core/components/breakpoint';

import LogoYC from 'site/icons/LogoYC';

import { AgeWallAttention } from 'site/components/Texts';

import { checkisOzonSpec, checkIsPhoneSpec } from 'site/utils';


function Logos({ className, location, theme }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const isSpecProject = isOzonSpec || isPhoneSpec;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .logos
          display flex
          justify-content space-between

        .attention
          margin 0 10px
          position relative
          left -20px
          opacity 0.8

        .logoYC
          :global([id*="$"])
            opacity 0.5

          :global(.mobile) &
            :global([id*="$"])
              opacity 1

        ._isSpecProject
          :global(.desktop) &
            .logoYC :global([id*="$"])
              opacity 1
              fill #D3D1D1
      `}</style>
      <style jsx>{`
        .logoYC
          :global([id*="$"])
            fill ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <div
      className={scope.wrapClassNames(
        'logos',
        isSpecProject && '_isSpecProject',
        className
      )}
    >
      <Desktop>
        <LogoYC
          className={scope.wrapClassNames('logoYC')}
          width={120}
          height={43}
        />
      </Desktop>
      <Mobile>
        <LogoYC
          className={scope.wrapClassNames('logoYC')}
          width={100}
          height={36}
        />
      </Mobile>
      {isOzonSpec && (
        <Desktop>
          <AgeWallAttention className={scope.wrapClassNames('attention')}>
            Данное предложение доступно<br />
            только совершеннолетним потребителям табака
          </AgeWallAttention>
        </Desktop>
      )}
      <scope.styles />
    </div>
  );
}

Logos.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object,
  theme: PropTypes.object,
};

export default withRouter(withTheme(Logos));
