import color from 'core/libs/color';

import fonts from './fonts';

export const colors = {
  primary: '#FFF',
  primaryOnHover: '#FFF',
  layout: '#1B0529',
  content: '#1B0529',
  input: '#FFF',
  active1: '#FF00A8',
  active2: '#ECB1D1',
  active3: '#E3BEF1',
  active4: '#5BFAFE',
  error: '#FF00A8',
  grey1: '#D2D2D2',
  grey2: '#9A9A9A',
  grey3: '#e3e3e3',
  blue1: '#132C62',
  purple1: '#825D8B',
  purple2: '#EEC8C8',
  purple3: '#770273',
  success: '#ADFB70',
  black: '#000',
  get divider() {
    return this.grey1;
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.blue1; // цвет темы, meta theme-color, tile-color, safari-pinned-tab
  },
};

const input = {
  const: {
    radius: '3px',
    color: colors.content,
  },

  basic: {
    idle: {
      color: colors.content,
      background: colors.input,
      border: `2px solid ${colors.active4}`,
      boxShadow: `0 0 8px ${colors.active4}`,
      placeholderColor: color(colors.primary).alpha(0.3).string(),
    },

    hover: {
      color: colors.content,
      background: colors.input,
      border: `2px solid ${colors.active4}`,
      boxShadow: `0 0 12px ${colors.active4}`,
    },

    focused: {
      color: colors.content,
      background: colors.input,
      border: `2px solid ${colors.active4}`,
      boxShadow: `0 0 15px ${colors.active4}`,
    },

    disabled: {
      color: colors.purple2,
      background: colors.purple1,
      border: `2px solid ${colors.active4}`,
      boxShadow: `0 0 8px ${colors.active4}`,
    },

    error: {
      color: colors.content,
      background: colors.input,
      border: `2px solid ${colors.error}`,
      boxShadow: `0 0 8px ${colors.error}`,
    },
  },
};

const tertiaryLink = {
  idle: {
    color: colors.active3,
    decoration: 'none',
  },
  hover: {
    color: colors.active3,
    decoration: 'underline',
  },
  active: {
    color: colors.active3,
    decoration: 'underline',
  },
  visited: {
    color: colors.active3,
    decoration: 'none',
  },
  current: {
    color: colors.active3,
    decoration: 'underline',
  },
};

export default {
  colors,
  controls: {
    link: {
      primary: {
        idle: {
          color: colors.primary,
          decoration: 'none',
        },
        hover: {
          color: colors.primary,
          decoration: 'underline',
        },
        active: {
          color: colors.primary,
          decoration: 'underline',
        },
        visited: {
          color: colors.primary,
          decoration: 'none',
        },
      },
      secondary: {
        idle: {
          color: colors.active2,
          decoration: 'underline',
        },
        hover: {
          color: colors.active2,
          decoration: 'none',
        },
        active: {
          color: colors.active2,
          decoration: 'none',
        },
        visited: {
          color: colors.active2,
          decoration: 'underline',
        },
      },
      tertiary: tertiaryLink,
    },

    button: {
      const: {
        sizes: {
          small: {
            font: `600 16px/20px ${fonts.display}`,
            width: '160px',
            height: '38px',
            padding: '9px 20px',
          },
          medium: {
            font: `600 16px/20px ${fonts.display}`,
            width: '270px',
            height: '40px',
            padding: '8px 20px 9px',
          },
          large: {
            font: `600 20px/24px ${fonts.display}`,
            width: '200px',
            height: '50px',
            padding: '13px 42px',
          },
        },
        radius: '25px',
        textTransform: 'none',
      },
      primary: {
        idle: {
          color: colors.active1,
          background: 'transparent',
          border: `1px solid ${colors.purple3}`,
        },

        hover: {
          color: colors.input,
          background: 'transparent',
          border: `1px solid ${colors.input}`,
        },

        active: {
          color: colors.input,
          background: 'transparent',
          border: `1px solid ${colors.input}`,
        },

        disabled: {
          color: color(colors.active1).alpha(0.4).string(),
          background: 'transparent',
          border: `1px solid ${color(colors.purple3).alpha(0.4).string()}`,
        },
      },
    },

    input,

    autoSuggest: {
      ...input,
      highlight: {
        idle: {
          background: color(colors.active4).alpha(0.3).toString(),
        },
        error: {
          background: color(colors.error).alpha(0.3).toString(),
        },
      },
    },

    checkbox: {
      basic: {
        idle: {
          fill: color(colors.input).alpha(0.3).string(),
        },
        focus: {
          fill: color(colors.input).alpha(0.3).string(),
        },
        checked: {
          fill: colors.active1,
        },
        disabled: {
          fill: colors.active1,
        },
        checkedDisabled: {
          fill: colors.active1,
        },
      },
    },

    shapka: {
      controls: {
        idle: {
          color: colors.active3,
        },

        hover: {
          color: color(colors.active3).alpha(0.8).string(),
        },
      },

      link: tertiaryLink,
      dropdown: {
        link: tertiaryLink,
      },

      tagline: {
        color: colors.grey2,
      },
    },

    scooter: {
      logo: {
        width: '65px',
        height: '27px',
      },
    },
  },
};
