import PropTypes from 'prop-types';

import color from 'core/libs/color';
import Modal from 'core/components/Modal';
import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { LAYOUT_MAX_WIDTH, SIDE_INDENT } from 'site/constants';

const SearchModalBase = (props) => {
  const {
    children,
    theme,
    ...otherProps
  } = props;

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalOverlay
          background ${color(theme.colors.layout).alpha(0.9).string()}
          backdrop-filter blur(5px)

        .modalWindow
          width 100%
          height 100%
          max-width ${LAYOUT_MAX_WIDTH - SIDE_INDENT * 2}px
          padding-top 37px
          box-sizing border-box
          background transparent

        .closeContainer
          top auto
          right 7px
          height 16px
          line-height 16px
          padding 9px 0 9px 11px

          &:hover
            .closeIcon
              color ${theme.colors.active1}

        .closeIcon
          width 16px
          height 16px
          color ${theme.colors.primary}
          transition color 200ms ease-in-out
      `}</style>
    </scope>
  );

  return (
    <Modal {...otherProps} scopedStyles={scopedStyles}>
      {children}
    </Modal>
  );
};

SearchModalBase.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(SearchModalBase);
