import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import styles from './index.styl';

function Arrows(props) {
  const {
    activeItem,
    totalCount,
    prevHandler,
    nextHandler,
    theme,
  } = props;

  const arrowsAtoms = theme.controls.gallery.arrows;

  const {
    Left,
    Right,
  } = arrowsAtoms;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .zone
          transition opacity ${theme.animations.hover}
          &:hover .arrow :global([id*="$"])
            fill ${arrowsAtoms.hover.fill}
          &:hover .arrow :global([id*="!"])
            stroke ${arrowsAtoms.hover.stroke}
        .arrow
          & :global([id*="$"])
            fill ${arrowsAtoms.idle.fill}
            transition fill ${theme.animations.hover}
          & :global([id*="!"])
            stroke ${arrowsAtoms.idle.stroke}
            transition stroke ${theme.animations.hover}
      `}</style>
    </scope>
  );

  const hidePrevArrow = !activeItem;
  const hideNextArrow = activeItem + 1 === totalCount;

  return (
    <>
      <div
        onClick={hidePrevArrow ? null : prevHandler}
        className={cx(
          'zone',
          styles.zone,
          hidePrevArrow && 'disabledZone',
          hidePrevArrow && styles.disabledZone,
        )}
      >
        <Left className={scope.wrapClassNames('arrow')} />
      </div>
      <div
        onClick={hideNextArrow ? null : nextHandler}
        className={cx(
          'zone',
          styles.zone,
          hideNextArrow && 'disabledZone',
          hideNextArrow && styles.disabledZone,
        )}
      >
        <Right className={scope.wrapClassNames('arrow')} />
      </div>
      <scope.styles />
    </>
  );
}

Arrows.propTypes = {
  activeItem: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  prevHandler: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

export default withTheme(Arrows);
