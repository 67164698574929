import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'core/libs/router';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import ThemeSwitch from 'core/components/ThemeSwitch';
import { Desktop } from 'core/components/breakpoint';

import Layout from 'site/components/Layout';
import BatIdProvider from 'site/components/BatId/BatIdProvider';
import PrivateRoute from 'site/components/PrivateRoute';
import Waves from 'site/components/Waves';
import YaCaptcha from 'site/components/YaCaptcha';
import BotLanding from 'site/components/BotLanding';

import Main from './pages/main';
import Tag from 'site/pages/tag';
import About from './pages/about';
import CookiesPolicy from './pages/cookies-policy';
import Author from 'site/pages/author';
import Authors from 'site/pages/authors';
import Search from './pages/search';
import Topic from 'site/pages/topic';
import Policy from 'site/pages/policy';
import Agreement from 'site/pages/agreement';
import AgreementReg from 'site/pages/agreement-reg';
import AgreementRegSpec from 'site/pages/agreement-reg-spec';
import Rules from 'site/pages/rules';
import PressRelease from 'site/pages/press-release';
import Rubric from 'site/pages/rubric';
import Profile, { profileRoutes } from 'site/pages/profile';
import Signup from 'site/pages/signup';
import TravelAsk from 'site/pages/travel-ask';
import Login, { loginRoutes } from 'site/pages/login';
import NotFound from './pages/not-found';
import VerifySubscription from './pages/verify-subscription';

import AgeWall from 'site/components/AgeWall';
// import NotificationRotator from 'site/components/NotificationRotator';

import { Background } from 'site/components/Ad/desktop';
import { Footer as AdFooter } from 'site/components/Ad/AdByBreakpoint';

import { checkisOzonSpec, checkIsPhoneSpec } from 'site/utils';

const RESTRICTED_PAGES = [
  '/spec-registration-ozon100ftb',
  '/specmobile',
  '/cookies_policy',
];

const REFERRERS = [
  'https://spec.yourchoice.ru',
  'https://privychki.yourchoice.ru',
];

class Site extends Component {
  state = {
    showAgeWallAndPopups: false,
    showPopups: false,
  };

  componentDidMount() {
    const isRestrictedPage = RESTRICTED_PAGES.some(path => this.props.location.pathname.indexOf(path) === 0);
    const showPopups = !isRestrictedPage;
    const showAgeWall = !isRestrictedPage || (isRestrictedPage && !REFERRERS.some(ref => document.referrer.indexOf(ref) === 0));

    if (showPopups || showAgeWall) {
      // eslint-disable-next-line
      this.setState({ showPopups, showAgeWall });
    }
  }

  render() {
    const { showAgeWall  } = this.state;
    const { pathname } = this.props.location;

    if (pathname === '/bot-landing') return <BotLanding />;

    /**
     * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
     * правильно резолвились переменные окружения.
     */
    const config = configResolver();

    const isOzonSpec = checkisOzonSpec(pathname);
    const isPhoneSpec = checkIsPhoneSpec(pathname);

    const isSpec = isOzonSpec || isPhoneSpec;

    return (
      <>
        <Waves />
        <App {...config}>
          <BatIdProvider wingnutApi={config.wingnutApi}>
            <ThemeSwitch
              name='light'
              {...isOzonSpec && { name: 'purple' }}
              {...isPhoneSpec && { name: 'blue' }}
            >
              <Layout>
                <BaseRoutes
                  authors={Authors}
                  author={Author}
                  tags={Tag}
                  main={Main}
                  topic={Topic}
                  about={About}
                  search={Search}
                  rubrics={Rubric}
                  notFound={NotFound}
                >
                  <Route
                    path='/policy'
                    component={Policy}
                    exact
                  />
                  <Route
                    path='/rules'
                    component={Rules}
                    exact
                  />
                  <Route
                    path='/press_release'
                    component={PressRelease}
                    exact
                  />
                  <Route
                    path='/cookies_policy'
                    component={CookiesPolicy}
                    exact
                  />
                  <Route
                    path='/agreement'
                    component={Agreement}
                    exact
                  />
                  <Route
                    path='/agreement_reg'
                    component={AgreementReg}
                  />
                  <Route
                    path='/agreement_reg_spec'
                    component={AgreementRegSpec}
                  />
                  <Route
                    path='/profile/login_change_confirm'
                    component={Profile}
                  />
                  {profileRoutes.map((route, i) => (
                    <PrivateRoute
                      path={route.path}
                      component={Profile}
                      exact
                      key={i}
                    />
                  ))}
                  <Route
                    path='/signup'
                    component={Signup}
                  />
                  {loginRoutes.map((route, i) => (
                    <Route
                      path={route.path}
                      component={Login}
                      exact
                      key={i}
                    />
                  ))}
                  <Route
                    path='/verify-subscription'
                    component={VerifySubscription}
                  />
                  <Route
                    path='/spec-registration-ozon100ftb'
                    component={TravelAsk}
                  />
                  <Route
                    path='/special-reg-phone100ftb'
                    component={TravelAsk}
                  />
                </BaseRoutes>
              </Layout>
              {!isSpec && <Desktop><Background /></Desktop>}
              {!isSpec && <AdFooter />}
            </ThemeSwitch>
            {/* {showPopups && <NotificationRotator />} */}
          </BatIdProvider>
          {showAgeWall && <AgeWall />}
          <YaCaptcha />
        </App>
      </>
    );
  }
}

Site.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Site);
