import deepmerge from 'core/libs/deepmerge';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `500 17px/1.35 ${fonts.display}`,
    },

    body: {
      font: `15px/1.6 ${fonts.text}`,
    },

    title1: {
      font: `700 26px/1.15 ${fonts.display}`,
    },

    title2: {
      font: `700 21px/1.3 ${fonts.display}`,
      color: atoms.colors.primary,
    },

    title3: {
      font: `700 23px/1.4 ${fonts.display}`,
    },

    title4: {
      font: `700 18px/1.3 ${fonts.display}`,
    },

    title5: {
      font: `700 15px/1.5 ${fonts.display}`,
    },

    incut: {
      font: `700 21px/1.3 ${fonts.display}`,
    },
  };

  const scooter = {
    logo: {
      width: '65px',
      height: '27px',
    },
  };

  const socializator = {
    'buttonHeight': 32,
    'iconSize': 16,
    'borderRadius': 16,
    'counterSize': 9,
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const shapkaMobileLink = {
    padding: '15px 10px',
    font: `700 19px/1.15 ${fonts.display}`,
  };

  const shapka = {
    logo: {
      width: 100,
      height: 36,
    },
    padding: `6px ${SIDE_INDENT_MOBILE}px 0`,
    height: 60,
    menu: {
      itemDivider: 'none',
    },
    link: shapkaMobileLink,
    dropdown: {
      link: shapkaMobileLink,
    },
    background: 'transparent',
    stickyBackground: 'transparent',
    backgroundOpened: 'transparent',
  };

  const topicContent = {
    widget: {
      top: 20,
      bottom: 20,
    },
  };

  return deepmerge({
    controls: {
      scooter,
      socializator,
      shapka,
      texts,
      topicContent,
    },
    layout,
  }, atoms);
}
