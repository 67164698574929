import React from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import isNotEmptyReactElementContent from 'core/utils/content';

import mapTypes from './types';

function Text(props) {
  const {
    tag: TagName,
    children,
    theme,
    breakpoint,
    componentName,
    className,
    id,
    style,
  } = props;

  if (!isNotEmptyReactElementContent(children)) return null;

  const textAtoms = mapTypes[componentName](theme);
  const breakpointAtoms = textAtoms[breakpoint] || textAtoms;

  return (
    <>
      <style jsx>{`
        .textElement
          font ${breakpointAtoms.font}
      `}</style>
      <TagName
        className={['textElement', className].join(' ')}
        id={id}
        style={style}
      >
        {children}
      </TagName>
    </>
  );
}

Text.propTypes = {
  className: PropTypes.string,
  // имя компонента текстового блока для подключения стилей из темы
  componentName: PropTypes.string.isRequired,
  tag: PropTypes.string,
  id: PropTypes.string,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
  style: PropTypes.object,
};

Text.defaultProps = {
  tag: 'div',
};

export default withTheme(withBreakpoint(Text));
