import { useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Modal from 'core/components/Modal';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import Button from 'core/components/Button';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import styles from './index.styl';

function LinkWithModal({ children, theme, href, isMobile }) {
  const [isOpen, setIsOpen] = useState(false);

  const linkElement = (
    <Link
      to={href}
      className={styles.partnerLink}
      {...isMobile && {
        onClick: (e) => {
          e.preventDefault();
          setIsOpen(true);
        },
      }}
    >
      {children}
    </Link>
  );

  if (!isMobile) return linkElement;

  const onClose = () => setIsOpen(false);

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalWindow
          border-radius 4px
      `}</style>
    </scope>
  );

  return (
    <>
      {linkElement}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scopedStyles={scopedStyles}
        hideCloseBtn
      >
        <style jsx>{`
          .${styles.modal}
              font 13px/18px ${theme.fonts.display}
        `}</style>
        <div className={styles.modal}>
          Нажимая на ссылку, вы будете перенаправлены на сайт никотиносодержащей продукции и устройств для её
          потребления, который предназначен только для совершеннолетних потребителей табачной и никотиносодержащей
          продукции
          <div className={styles.btns}>
            <Link
              to={href}
              className={styles.externalLink}
            >
              <Button onClick={onClose}>Перейти</Button>
            </Link>
            <Button onClick={onClose} type='ghost'>Отмена</Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

LinkWithModal.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
  href: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(withTheme(LinkWithModal));
