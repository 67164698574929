function BotLanding() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      К сожалению, вы похожи на бота 🤖
      <br />
      Если это не так, вернитесь на предыдущую страницу и попробуйте обновить её.
    </div>
  );
}

export default BotLanding;
