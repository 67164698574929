import { PureComponent } from 'react';
import { Helmet } from 'core/libs/helmet';
import PropTypes from 'prop-types';
import { withRouter, Route, Switch } from 'core/libs/router';
import queryString from 'core/libs/query-string';
import { getCookie, setCookie } from 'core/utils/cookie-helper';

import { withBreakpoint } from 'core/components/breakpoint';
import Loader from 'core/components/Loader';

import { PageIndent } from 'site/components/Wrappers';
import NeonBody from 'site/components/NeonBody';

import { COOKIE_SPEC_GO } from 'site/constants';

import Registration from './Registration';
import Success from './Success';
import Signed from './Signed';

const travelAskRoutes = [
  {
    exact: true,
    path: '/spec-registration-ozon100ftb',
    component: Registration,
  },
  {
    exact: true,
    path: '/spec-registration-ozon100ftb/success',
    component: Success,
  },
  {
    exact: true,
    path: '/spec-registration-ozon100ftb/signed',
    component: Signed,
  },
  {
    exact: true,
    path: '/special-reg-phone100ftb',
    component: Registration,
  },
  {
    exact: true,
    path: '/special-reg-phone100ftb/success',
    component: Success,
  },
  {
    exact: true,
    path: '/special-reg-phone100ftb/signed',
    component: Signed,
  },
];

class TravelAskContest extends PureComponent {
  state = {
    canBeShown: false,
  };

  componentDidMount() {
    this.checkOnCanBeShown();
  }

  componentDidUpdate() {
    this.checkOnCanBeShown();
  }

  checkOnCanBeShown = () => {
    const { location } = this.props;
    const { spec, ...otherSearchParams } = queryString.parse(location.search);
    const hasSpecGo = spec === 'go';

    if (this.hasCanBeShownCookie() && !hasSpecGo) {
      this.setState({ canBeShown: true });
    } else if (!this.hasCanBeShownCookie() && hasSpecGo) {
      this.setCanBeShownCookie();
    } else {
      this.redirectToMainPage();
    }

    /**
     * Редиректим пользователя на ту же страницу, со всеми GET параметрами, за исключением ?spec=go.
     * Сделано это для того, чтобы пользователи не могли делиться ссылкой с друзьями ¯\_(ツ)_/¯.
     */
    if (this.hasCanBeShownCookie() && hasSpecGo) {
      this.redirectToSamePage(otherSearchParams);
    }
  };

  hasCanBeShownCookie = () => {
    return Boolean(getCookie(COOKIE_SPEC_GO));
  };

  setCanBeShownCookie = () => {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    setCookie(COOKIE_SPEC_GO, 1, { expires: expiryDate });
  };

  redirectToMainPage = () => this.props.history.push('/');

  redirectToSamePage = searchParams => {
    const { history, location } = this.props;
    history.replace(location.pathname + '?' + queryString.stringify(searchParams));
  };

  render() {
    const { canBeShown } = this.state;

    return (
      <>
        <Helmet>
          <script>{`
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
            ga('create', 'UA-101980905-12', 'auto');
            ga('require', 'displayfeatures');
            `}
          </script>
        </Helmet>
        <PageIndent>
          {canBeShown ? (
            <Switch>
              {travelAskRoutes.map((routeParams, i) => (
                <Route key={i} {...routeParams} />
              ))}
            </Switch>
          ) : <Loader />}
        </PageIndent>
        <NeonBody />
      </>
    );
  }
}

TravelAskContest.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(withBreakpoint(TravelAskContest));
