import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import LogoIcon from 'site/icons/LogoYC';
import BurgerIcon from 'site/icons/Burger';
import CloseIcon from 'site/icons/Close';

const icons = {
  logo: LogoIcon,
  burger: BurgerIcon,
  close: CloseIcon,
};

/**
 * Перекрашиваем служебные иконки шапки в цвет темы
 */
function Icon({ theme, iconName, ...otherProps }) {
  const ShapkaIcon = icons[iconName];

  return (
    <ShapkaIcon
      color={theme.colors.primary}
      {...otherProps}
    />
  );
}

Icon.propTypes = {
  theme: PropTypes.object,
  iconName: PropTypes.oneOf(['logo', 'burger', 'close']),
};

export default withTheme(Icon);
