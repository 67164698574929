import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function Neon({ theme, children, className }) {
  return (
    <div className={cx(styles.neon, className)}>
      <style jsx>{`
        .${styles.borders}
          span:nth-child(1)
            border 4px solid #438EFF
            filter blur(23px)
        
          span:nth-child(2)
            border 4px solid #FF26C2
            filter blur(8px)
        
          span:nth-child(3)
            border 2px solid #2BF2FF
            filter blur(0.5px)
        
          span:nth-child(4)
            border 1px solid ${theme.colors.primary}
            filter blur(0.3px)
      `}</style>
      <div className={styles.borders}>
        <span />
        <span />
        <span />
        <span />
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

Neon.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string,
};

export default withTheme(Neon);
