import PropTypes from 'prop-types';
import cx from 'classnames';
import { CONTENT_AREA } from 'site/constants';

import styles from './index.styl';

const MaxWidthWrapper = props => {
  const { className, children, maxWidth } = props;
  return (
    <div className={cx(styles.maxWidthWrapper, className)}>
      <style jsx>{`
        .${styles.maxWidthWrapper}
          max-width ${maxWidth || CONTENT_AREA}px
      `}</style>
      {children}
    </div>
  );
};

MaxWidthWrapper.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.number,
};

export default MaxWidthWrapper;
