import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import Button from 'core/components/Button';

import styles from './index.styl';

function NeonButton(props) {
  const {
    theme,
    children,
    ...buttonProps
  } = props;

  const { disabled } = buttonProps;

  return (
    <div className={cx(styles.neonButton, disabled && styles._disabled)}>
      <style jsx>{`
        .${styles.borders}
          span
            transition border-color ${theme.animations.hover}

          span:nth-child(1)
            border 1px solid #FFFFFF
            filter blur(0.3px)

          span:nth-child(2)
            border 2px solid #FB37FF
            filter blur(0.5px)

          span:nth-child(3)
            border 4px solid #FF26C2
            filter blur(8px)

          span:nth-child(4)
            border 3px solid #F32E7C
            filter blur(23px)

          span:nth-child(5)
            margin 0 -20px
            background rgba(255, 55, 128, 0.3)
            mix-blend-mode hard-light
            filter blur(23px)

        .${styles.neonButton}:hover
          .${styles.borders}
            :global(span)
              border-color #fff
      `}</style>
      <Button {...buttonProps}>
        <div className={styles.borders}>
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </Button>
    </div>
  );
}

NeonButton.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(NeonButton);
