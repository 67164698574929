import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import { Indent } from 'site/components/Wrappers';
import MasonryFeed from 'site/components/MasonryFeed';

import { INDENT } from 'site/constants';
import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';

import { Ad280x430 } from 'site/components/Ad/desktop';
import { Ad320x492 } from 'site/components/Ad/mobile';


const cards = [
  { component: Card3, margin: false },
  { component: Card1, margin: false },
  { component: Card2, margin: false },
  { component: Card1, margin: false },
  { component: Card1, margin: false },
  { component: Card2, margin: true },
  { component: Card1, margin: false },
  { component: Card2, margin: true },
];

const types = {
  0: {
    cards,
    cardsReplacementPosition: 2,
  },
  1: {
    cards: undefined,
    cardsReplacementPosition: 1,
  },
};

function AdditionalTopics(props) {
  const {
    content,
    isMobile,
    type,
  } = props;

  const {
    cards: cardsByType,
    cardsReplacementPosition,
  } = types[type];

  return (
    <Indent top={INDENT}>
      <MasonryFeed
        columns={4}
        cards={cardsByType}
        content={content}
        cardsReplacement={{
          [cardsReplacementPosition]: replaceCardWithAd(isMobile ? Ad320x492 : Ad280x430),
        }}
      />
    </Indent>
  );
}

AdditionalTopics.defaultProps = {
  type: 0,
};

AdditionalTopics.propTypes = {
  content: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  /**
   * 0 - сначала большая карточка
   * 1 - сначала маленькая карточка
   */
  type: PropTypes.oneOf([0, 1]),
};

export default withBreakpoint(AdditionalTopics);
