import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { passwordsEqualValidation } from 'site/utils/formValidations';

import Link from 'core/components/Link';
import Button from 'core/components/Button';

import Input from 'site/components/Input';

import { Indent } from 'site/components/Wrappers';
import { LinkSmall } from 'site/components/Texts';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';

export default function ChangePassword(props) {
  const {
    handleInputChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
  } = props;

  const [passwordsReadyForCompare, setPasswordsReadyForCompare] = useState(false);
  const passwordHasError = touched.new_password && errors.new_password;
  const passwordConfirmHasError = touched.password_confirm && errors.password_confirm;

  const handlePasswordsReadyForCompare = useCallback(() => {
    // оба поля пароля были отредактированы
    const passwordsTouched = touched.new_password && touched.password_confirm;

    setPasswordsReadyForCompare(passwordsTouched);
  }, [touched.new_password, touched.password_confirm]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Текущий пароль'
          name='password'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password ? errors.password : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Новый пароль'
          name='new_password'
          onChange={handleInputChange}
          onBlur={event => {
            handleBlur(event);
            handlePasswordsReadyForCompare();
          }}
          value={values.new_password}
          {...passwordsReadyForCompare && {
            error: passwordsEqualValidation(values.new_password, values.password_confirm),
          }}
          {...passwordHasError && { error: errors.new_password }}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Новый пароль еще раз'
          name='password_confirm'
          onChange={handleInputChange}
          onBlur={event => {
            handleBlur(event);
            handlePasswordsReadyForCompare();
          }}
          value={values.password_confirm}
          {...passwordsReadyForCompare && {
            error: passwordsEqualValidation(values.new_password, values.password_confirm),
          }}
          {...passwordConfirmHasError && { error: errors.password_confirm }}
        />
      </div>

      <Indent top={VERTICAL_INDENT} />
      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit'>Сохранить</Button>
        <Link
          type='secondary'
          to='/profile'
          className={styles.cancel}
        >
          <LinkSmall>Отмена</LinkSmall>
        </Link>
      </div>
    </form>
  );
}

ChangePassword.propTypes = {
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
};
