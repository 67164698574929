import color from 'core/libs/color';
import deepmerge from 'core/libs/deepmerge';

import bindProps from 'core/components/bindProps';
import DrumAmp from 'core/components/Drum/Amp';

import ShapkaIcon from 'site/components/Shapka/Icon';
import LogoBat from 'site/icons/LogoBat';
import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';

import { Card1Type2 } from 'site/cards/Card1';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
  CONTENT_AREA,
} from 'site/constants';

import ArrowLeft from 'site/icons/ArrowLeft';
import ArrowRight from 'site/icons/ArrowRight';

import fonts from './fonts';

const Logo = bindProps({ iconName: 'logo' })(ShapkaIcon);
const Burger = bindProps({ iconName: 'burger' })(ShapkaIcon);
const Close = bindProps({ iconName: 'close' })(ShapkaIcon);


const icons = {
  logo: Logo,
  logoBat: LogoBat,
  burger: Burger,
  close: Close,
  Video,
  Photo,
};

export default function baseTheme(atoms) {
  const layout = {
    boxShadow: 'none',
    indents: {
      top: VERTICAL_INDENT,
      right: SIDE_INDENT,
      bottom: VERTICAL_INDENT,
      left: SIDE_INDENT,
    },
  };

  const texts = {
    lead: {
      font: `500 17px/1.35 ${fonts.display}`,
    },

    body: {
      font: `16px/1.6 ${fonts.text}`,
    },

    title1: {
      font: `700 34px/1.2 ${fonts.display}`,
    },

    title2: {
      font: `700 26px/1.3 ${fonts.display}`,
      color: atoms.colors.primary,
    },

    title3: {
      font: `700 23px/1.4 ${fonts.display}`,
    },

    title4: {
      font: `700 18px/1.3 ${fonts.display}`,
    },

    title5: {
      font: `700 15px/1.6 ${fonts.display}`,
    },

    incut: {
      font: `700 21px/1.3 ${fonts.display}`,
      color: atoms.colors.primary,
    },

    bodySmall: {
      font: `500 12px/1.6 ${fonts.display}`,
      color: atoms.colors.primary,
    },
  };

  const link = {
    article: {
      idle: {
        color: atoms.colors.active3,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.active3,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.active3,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    primary: {
      idle: {
        color: atoms.colors.active3,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.active3,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    tertiary: {
      active: {
        color: atoms.colors.active1,
      },
      visited: {
        color: atoms.colors.primary,
      },
    },

    card: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
      hover: {
        color: atoms.colors.primaryOnHover,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.primaryOnHover,
        decoration: 'none',
      },
      visited: {
        color: atoms.colors.primary,
        decoration: 'none',
      },
    },

    underlined: {
      idle: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.primaryOnHover,
        decoration: 'none',
      },
      active: {
        color: atoms.colors.primaryOnHover,
        decoration: 'none',
      },
      visited: {
        color: atoms.colors.primary,
        decoration: 'underline',
      },
    },
  };

  const sizes = {
    extraSmall: {
      font: `700 12px/1.3 ${fonts.display}`,
      width: '100px',
      height: '20px',
      padding: '5px',
    },
    small: {
      font: `700 12px/1.3 ${fonts.display}`,
      width: '60px',
      height: '38px',
      padding: '10px 20px',
    },
    medium: {
      font: `700 12px/1.3 ${fonts.display}`,
      width: '110px',
      height: '38px',
      padding: '10px 15px',
    },

    large: {
      font: `700 12px/1.3 ${fonts.display}`,
      width: '140px',
      height: '38px',
      padding: '10px 15px',
    },
  };

  const button = {
    const: {
      sizes,
      radius: '3px',
      textTransform: 'uppercase',
    },
  };

  const inputSizes = {
    extraSmall: {
      font: `500 15px/1.6 ${fonts.display}`,
      width: '100px',
      height: '20px',
      padding: '6px',
    },
    small: {
      font: `500 15px/1.6 ${fonts.display}`,
      width: '140px',
      height: '38px',
      padding: '6px',
    },
    medium: {
      font: `500 15px/1.6 ${fonts.display}`,
      width: '140px',
      height: '38px',
      padding: '6px',
    },

    large: {
      font: `500 15px/1.6 ${fonts.display}`,
      width: '140px',
      height: '38px',
      padding: '6px',
    },
  };

  const input = {
    const: {
      sizes: { ...inputSizes },
      radius: '3px',
      color: atoms.colors.primary,
    },

    basic: {
      idle: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.grey1}`,
        boxShadow: 'none',
        placeholderColor: atoms.colors.grey1,
      },

      hover: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.grey1}`,
        boxShadow: 'none',
      },

      focused: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.grey2}`,
        boxShadow: 'none',
      },

      disabled: {
        background: atoms.colors.grey1,
        border: `1px solid ${atoms.colors.grey1}`,
        boxShadow: 'none',
      },

      error: {
        background: atoms.colors.input,
        border: `1px solid ${atoms.colors.error}`,
        boxShadow: 'none',
      },
    },
  };

  const autoSuggest = {
    ...input,
    highlight: {
      idle: {
        background: color(atoms.colors.grey1).alpha(0.3).toString(),
      },
      error: {
        background: color(atoms.colors.error).alpha(0.3).toString(),
      },
    },
  };

  const searchInput = {
    withButton: true,
    placeholder: '',
  };


  const shapkaDesktopLink = {
    font: `600 13px/1.2 ${fonts.display}`,
    textTransform: 'lowercase',
    padding: '6px 15px',
  };

  const shapka = {
    sticky: false,
    link: shapkaDesktopLink,
    dropdown: {
      link: shapkaDesktopLink,
    },
    logo: {
      width: 160,
      height: 60,
    },
    height: 110,
    padding: `24px ${SIDE_INDENT}px 26px ${SIDE_INDENT}px`,
    shadow: 'none',
    background: 'transparent',
    backgroundOpened: 'transparent',
    menu: {
      centered: true,
    },
  };

  const scooter = {
    logo: {
      Icon: icons.logoBat,
      width: '100px',
      height: '52px',
    },
  };

  const checkbox = {
    basic: {
      idle: {
        fill: atoms.colors.grey2,
      },
      focus: {
        fill: atoms.colors.grey2,
      },
      checked: {
        fill: atoms.colors.blue1,
      },
      disabled: {
        fill: atoms.colors.grey2,
      },
      checkedDisabled: {
        fill: atoms.colors.grey2,
      },
    },
  };

  const radio = {
    basic: {
      idle: {
        fill: atoms.colors.grey2,
      },
      focus: {
        fill: atoms.colors.grey2,
      },
      checked: {
        fill: atoms.colors.blue1,
      },
      disabled: {
        fill: atoms.colors.grey2,
      },
      checkedDisabled: {
        fill: atoms.colors.grey2,
      },
    },
  };

  const vikontSnippet = {
    description: {
      margin: '10px 0 0',
      font: `12px/1.6 ${fonts.display}`,
      color: atoms.colors.primary,
      textAlign: 'left',
    },
  };

  const vikontImage = {
    textAlign: 'left',
    padding: 0,
    background: 'none',
    margin: '10px 0 0',
    caption: {
      font: `12px/1.6 ${fonts.display}`,
      color: atoms.colors.primary,
    },
    credits: {
      font: `12px/1.6 ${fonts.display}`,
      color: atoms.colors.grey2,
      margin: 0,
    },
  };

  const dropdown = {
    content: {
      boxShadowColor: 'none',
    },
  };

  const gallery = {
    arrows: {
      Left: ArrowLeft,
      Right: ArrowRight,
    },
    imageInfo: {
      captionFont: texts.bodySmall.font,
      captionColor: atoms.colors.primary,

      creditsFont: texts.bodySmall.font,
      creditsColor: atoms.colors.placeholder,

      bullet: {
        idle: {
          background: atoms.colors.placeholder,
        },
        active: {
          background: atoms.colors.active1,
        },
      },
    },
  };

  const counter = {
    ...texts.bodySmall,
  };

  const socializator = {
    fixedPosition: false,
    'buttonHeight': 32,
    'iconSize': 16,
    'borderRadius': 16,
    'counterSize': 9,
  };

  const topicContent = {
    widgetsMaxWidth: CONTENT_AREA,
    widgetsWithMaxWidth: {
      image: true,
      readMore: false,
      snippet: true,
    },
    widgetsWithNegativeMobileIndent: {
      'image': false,
      'photoGallery': true,
      'readMore': false,
    },
    widget: {
      top: 30,
      bottom: 30,
    },
    tableOfContents: {
      borderBottom: 'none',
      margin: '30px auto',
      padding: '0',
      maxWidth: `${CONTENT_AREA}px`,
      listPadding: '0',
      listStyle: 'none',
      title: {
        display: 'none',
      },
      link: {
        color: atoms.colors.active3,
      },
    },
  };

  const vikontReadMore = {
    amp: {
      card: Card1Type2,
      presenter: DrumAmp,
    },
  };

  return deepmerge({
    fonts,
    icons,
    layout,
    texts,
    controls: {
      link,
      button,
      input,
      searchInput,
      shapka,
      checkbox,
      radio,
      vikontSnippet,
      vikontImage,
      dropdown,
      gallery,
      counter,
      scooter,
      socializator,
      autoSuggest,
      topicContent,
      vikontReadMore,
    },
  }, atoms);
}
