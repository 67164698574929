import BatIdContext from '.';

export default function withBatId(Component) {
  return function BatIdConsumer(props) {
    return (
      <BatIdContext.Consumer>
        {batId => {
          return <Component {...props} batId={batId} />;
        }}
      </BatIdContext.Consumer>
    );
  };
}
