import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import cx from 'classnames';

import Input from 'site/components/Input';
import { Indent } from 'site/components/Wrappers';
import { ErrorMessage, LinkSmall } from 'site/components/Texts';
import Link from 'core/components/Link';
import Button from 'core/components/Button';

import { passwordsEqualValidation } from 'site/utils/formValidations';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';


const Form = (props) => {
  const {
    handleInputChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    serverErrorName,
  } = props;

  const [passwordsReadyForCompare, setPasswordsReadyForCompare] = useState(false);
  const passwordHasError = touched.password && errors.password;
  const passwordConfirmHasError = touched.password_confirm && errors.password_confirm;

  const handlePasswordsReadyForCompare = useCallback(() => {
    // оба поля пароля были отредактированы
    const passwordsTouched = touched.password && touched.password_confirm;

    setPasswordsReadyForCompare(passwordsTouched);
  }, [touched.password, touched.password_confirm]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Пароль'
          name='password'
          onChange={handleInputChange}
          onBlur={event => {
            handleBlur(event);
            handlePasswordsReadyForCompare();
          }}
          value={values.password}
          {...passwordsReadyForCompare && { error: passwordsEqualValidation(values.password, values.password_confirm) }}
          {...passwordHasError && { error: errors.password }}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='password'
          label='Пароль еще раз'
          name='password_confirm'
          onChange={handleInputChange}
          onBlur={event => {
            handleBlur(event);
            handlePasswordsReadyForCompare();
          }}
          value={values.password_confirm}
          {...passwordsReadyForCompare && { error: passwordsEqualValidation(values.password, values.password_confirm) }}
          {...passwordConfirmHasError && { error: errors.password_confirm }}
        />
      </div>

      <Indent top={VERTICAL_INDENT} />
      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit'>Сохранить пароль</Button>
        <Link type='secondary' to='/login'>
          <LinkSmall>Отмена</LinkSmall>
        </Link>
      </div>
      {serverErrorName && <ErrorMessage className={styles.errorMessage}>{serverErrorName}</ErrorMessage>}
    </form>
  );
};

Form.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
};

export default Form;
