import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';
import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import skip from 'core/resolver/skip';

import EmptyWrapper from 'core/components/EmptyWrapper';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import NeonButton from 'site/components/NeonButton';
import NeonHeaderOzon from 'site/components/NeonHeaderOzon';
import NeonHeaderPhone from 'site/components/NeonHeaderPhone';
import NeonDecoration from 'site/components/NeonDecoration';
import BaseForm from 'site/components/BaseForm';
import withBatId from 'site/components/BatId/withBatId';
import { MaxWidthWrapper, Neon, Indent } from 'site/components/Wrappers';
import { ErrorMessage } from 'site/components/Texts';

import {
  nameValidation,
  emailValidation,
  birthdateValidation,
  phoneValidation,
  policyAndRulesValidation,
  autoSuggestValidation, requireValidation,
} from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';
import batIdPropTypes from 'site/utils/prop-types/batId';
import { checkIsPhoneSpec, checkisOzonSpec, setMetric } from 'site/utils';

import { CONTENT_AREA_SPEC, METRICS, OZON_SUBSOURCE_MAP, PHONE_SUBSOURCE_MAP } from 'site/constants';

import RegistrationForm from './RegistrationForm';
import PhoneConfirmForm from './PhoneConfirmForm';

import SmsResend from '../components/SmsResend';

import styles from './index.styl';

const validateRegistration = {
  first_name: name => nameValidation('Имя', name),
  email: emailValidation,
  phone: phoneValidation,
  birthdate: birthdateValidation,
  city: (city, { cities }) => autoSuggestValidation('Город', city, cities),
  brand_name: (brand, { marks }) => autoSuggestValidation('Бренд', brand, marks),
  terms_and_conditions: policyAndRulesValidation,
};

const validatePhoneConfirm = {
  code: requireValidation,
};

const initialValuesRegistration = {
  first_name: '',
  email: '',
  phone: '',
  birthdate: '',
  city: '',
  brand_name: '',
  terms_and_conditions: false,
};

function Registration({ location, batId, isMobile, history }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);

  const [registrationErrorName, updateRegistrationErrorName] = useState(null);
  const [smsErrorName, updateSmsErrorName] = useState(null);
  const [registrationErrorStatus, updateRegistrationErrorStatus] = useState(null);
  const [registrationFieldsErrors, updateRegistrationFieldsErrors] = useState(null);
  const [phoneForVerify, updatePhoneForVerify] = useState(null);
  const [smsVerifyErrors, updateSmsVerifyErrors] = useState(null);
  const [phoneVerified, updatePhoneVerifiedState] = useState(false);

  const initialValuesPhoneConfirm = {
    key: '',
    phone: phoneForVerify,
  };

  const resetRegistrationErrors = useCallback(() => {
    updateRegistrationErrorStatus(null);
    updateRegistrationErrorName(null);
    updateRegistrationFieldsErrors(null);
  }, []);

  const resetPhoneConfirmErrors = useCallback(() => {
    updateSmsErrorName(null);
    updateSmsVerifyErrors(null);
  }, []);

  const registrationSubmitCallback = useCallback((values, { token }) => {
    const { utm_source: utmSource } = queryString.parse(location.search);

    values.phone = '+' + values.phone.replace(/[^\d]/g, '');

    if (isOzonSpec) {
      values.subsource_name = utmSource
        ? OZON_SUBSOURCE_MAP[utmSource] || OZON_SUBSOURCE_MAP.defaultFallback
        : 'yc_sp_not-set_ozon';
    }

    if (isPhoneSpec) {
      values.subsource_name = utmSource
        ? PHONE_SUBSOURCE_MAP[utmSource] || PHONE_SUBSOURCE_MAP.defaultFallback
        : 'yc_sp_not-set_phone';
    }

    return batId.createTravelAskSubscription({
      ...values,
      ['g-recaptcha-response']: token,
    })
      .then(() => {
        updatePhoneForVerify(values.phone);
        resetRegistrationErrors();
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateRegistrationErrorStatus(errors.status);
        updateRegistrationFieldsErrors(errors.fieldsErrors);

        const { phone } = errors.fieldsErrors || {};

        if (errors.status === 422 && phone && phone[0] === 'Вы уже участвуете в розыгрыше') {
          if (isOzonSpec) {
            history.replace('/spec-registration-ozon100ftb/signed', { signed: true });
          }

          if (isPhoneSpec) {
            history.replace('/special-reg-phone100ftb/signed', { signed: true });
          }
        }

        if (errors.status === 403) {
          updatePhoneForVerify(values.phone);
          /**
           * Принудительно сбрасываем сообщение об ошибке Всей формы регистрации.
           * Предполагается, что пользователь был зарегистрирован, но не подтвердил номер телефона.
           */
          updateRegistrationErrorName(null);
        } else {
          updateRegistrationErrorName(errors.error);
        }
      });
  }, [batId, history, isOzonSpec, isPhoneSpec, location.search, resetRegistrationErrors]);

  const phoneConfirmSubmitCallback = useCallback(values => {
    return batId.verifyTravelAskSubscription(values)
      .then(() => {
        setMetric(METRICS.submit_reg_bonus);
        updatePhoneVerifiedState(true);
        resetPhoneConfirmErrors();
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        updateSmsErrorName(errors.error);
        updateSmsVerifyErrors(errors.smsVerifyErrors);
      });
  }, [batId, resetPhoneConfirmErrors]);

  const NeonWrapper = isMobile ? EmptyWrapper : Neon;

  return (
    <>
      {isPhoneSpec && <NeonDecoration />}
      <MaxWidthWrapper className={styles.wrapper} maxWidth={CONTENT_AREA_SPEC}>
        {isOzonSpec && <NeonHeaderOzon type={1} />}
        {isPhoneSpec && (
          <Mobile>
            <NeonHeaderPhone type={2} />
          </Mobile>
        )}
        <NeonWrapper className={styles.neonWrapper}>
          {isPhoneSpec && <Desktop><NeonHeaderPhone type={1} /></Desktop>}
          <BaseForm
            form={RegistrationForm}
            submitCallback={registrationSubmitCallback}
            initialValues={initialValuesRegistration}
            validate={validateRegistration}
            serverFieldsErrors={registrationFieldsErrors}
            formStates={{
              accountCreated: Boolean(phoneForVerify),
              phoneVerified,
            }}
          />
          {phoneForVerify && !phoneVerified && (
            <Indent top={20}>
              <BaseForm
                form={PhoneConfirmForm}
                submitCallback={phoneConfirmSubmitCallback}
                initialValues={initialValuesPhoneConfirm}
                validate={validatePhoneConfirm}
                smsVerifyErrors={smsVerifyErrors}
                resetServerErrors={resetPhoneConfirmErrors}
              />
            </Indent>
          )}
          {!phoneVerified && (registrationErrorStatus === 403 || phoneForVerify) && (
            <Indent top={20}>
              <SmsResend phone={phoneForVerify} />
            </Indent>
          )}

          {registrationErrorName && <Indent top={20}><ErrorMessage>{registrationErrorName}</ErrorMessage></Indent>}
          {smsErrorName && <Indent top={20}><ErrorMessage>{smsErrorName}</ErrorMessage></Indent>}
        </NeonWrapper>
        <Indent top={isMobile ? 35 : 25} />
        <div className={styles.button}>
          <NeonButton
            size='large'
            disabled={!phoneVerified}
            onClick={() => {
              if (isOzonSpec) {
                history.push('/spec-registration-ozon100ftb/success', { success: true });
              }

              if (isPhoneSpec) {
                history.push('/special-reg-phone100ftb/success', { success: true });
              }
            }}
          >
            Отправить
          </NeonButton>
        </div>
      </MaxWidthWrapper>
    </>
  );
}

Registration.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }),
  batId: batIdPropTypes,
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default compose(
  skip,
  withRouter,
  withBatId,
  withBreakpoint,
)(Registration);
