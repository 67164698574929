import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import withPageHocs from 'core/components/withPageHocs';
import SearchPage from 'core/components/SearchPage';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { PageLayout, PageIndent } from 'site/components/Wrappers';
import { Description } from 'site/components/Texts';
import Pagination from 'site/components/Pagination';
import FoundTextBlock from 'site/components/FoundTextBlock';

import SearchCard from 'site/cards/SearchCard';

import { Billboard } from 'site/components/Ad/AdByBreakpoint';


/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
const Search = (props) => {
  const {
    rawContent,
    theme,
    isDesktop,
  } = props;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .searchText
          color ${theme.colors.grey2}
      `}</style>
    </scope>
  );

  return (
    <>
      <Billboard />
      <PageIndent bottom={40}>
        <PageLayout
          leftSidebar={isDesktop && (
            <Description className={scope.wrapClassNames('searchText')}>
              Поиск
            </Description>
          )}
        >
          <SearchPage
            rawContent={rawContent}
            card={SearchCard}
            pagination={Pagination}
            foundTextBlock={FoundTextBlock}
          />
          <scope.styles />
        </PageLayout>
      </PageIndent>
    </>
  );
};

Search.propTypes = {
  rawContent: PropTypes.array,
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: SearchCard }),
});

export default withPageHocs(dataProvider)(withBreakpoint(withTheme(Search)));
