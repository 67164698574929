import PropTypes from 'prop-types';
import cx from 'classnames';

import Image from 'core/components/Image';
import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import styles from './index.styl';

const THUMBS_HEIGHT = 50;

function Navigation(props) {
  const {
    images,
    activeItem,
    gotoHandler,
    theme,
  } = props;

  const {
    controls: {
      gallery: {
        thumb: thumbAtoms,
      },
    },
  } = theme;

  return (
    <div className={cx(styles.navigation, activeItem === 0 && styles.firstItem)}>
      <style jsx>{`
        .${styles.thumb}
          &:before
            background ${thumbAtoms.idle.fade}
            transition background ${theme.animations.hover}
          &:hover:before
            background ${thumbAtoms.hover.fade}
      `}</style>
      {images.map((image, i, arr) => {
        const {
          id: imageId,
          attributes: {
            versions: {
              original: cover,
            },
          },
        } = image;

        let index = i === 0 ? activeItem - 1 : activeItem + 1;
        if (activeItem === 0) {
          index = activeItem + 1;
        } else if (arr.length === 1) {
          index = activeItem - 1;
        }

        return (
          <div
            key={imageId}
            className={styles.thumb}
            onClick={gotoHandler.bind(null, index)}
          >
            <Image
              src={cover}
              width={(cover.width / cover.height) * THUMBS_HEIGHT}
              height={THUMBS_HEIGHT}
              instant
            />
          </div>
        );
      })}
    </div>
  );
}

Navigation.propTypes = {
  images: PropTypes.array.isRequired,
  activeItem: PropTypes.number.isRequired,
  gotoHandler: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(Navigation));
