import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import styles from './index.styl';

const MAX_BULLETS = 7;

function ImageInfo(props) {
  const {
    caption,
    credits,
    activeItem,
    totalCount,
    isMobile,
  } = props;

  const {
    counter: counterStyles,
    gallery: {
      imageInfo: imageInfoAtoms,
    },
  } = props.theme.controls;

  const [bullets, setBullets] = useState([...Array(totalCount > MAX_BULLETS ? MAX_BULLETS : totalCount)]
    .map((e, i) => {
      return {
        id: i,
        isActive: i === 0,
      };
    })
  );

  useEffect(() => {
    const newBullets = bullets.map((b) => {
      b.isActive = false;
      if (b.id === activeItem) {
        b.isActive = true;
      }
      return b;
    });
    if (newBullets[newBullets.length - 1].id === activeItem && activeItem + 1 < totalCount) {
      newBullets.shift();
      newBullets.push({
        id: newBullets[newBullets.length - 1].id + 1,
        isActive: false,
      });
    } else if (newBullets[0].id === activeItem && activeItem !== 0) {
      newBullets.pop();
      newBullets.unshift({
        id: newBullets[0].id - 1,
        isActive: false,
      });
    }
    setBullets(newBullets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.counter}
          font ${counterStyles.font}
          color ${counterStyles.color}
        .${styles.caption}
          font ${imageInfoAtoms.captionFont}
          color ${imageInfoAtoms.captionColor}
        .${styles.credits}
          font ${imageInfoAtoms.creditsFont}
          color ${imageInfoAtoms.creditsColor}
        .${styles.bullet}
          background ${imageInfoAtoms.bullet.idle.background}
          &.active
            background ${imageInfoAtoms.bullet.active.background}
      `}</style>
      <div>
        {caption &&
        <div className={styles.caption}>
          <MarkdownWrapper>
            {caption}
          </MarkdownWrapper>
        </div>
        }

        {credits && (
          <div className={styles.credits}>
            <MarkdownWrapper>{'Источник: ' + credits}</MarkdownWrapper>
          </div>
        )}
      </div>
      <div className={styles.counter}>
        {isMobile && (
          <TransitionGroup className={styles.bullets}>
            {bullets.map((e) => {
              return (
                <CSSTransition
                  key={e.id}
                  timeout={100}
                  classNames='item'
                >
                  <div className={cx(styles.bullet, e.isActive && 'active')} />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
        <span className={styles.digit}>{activeItem + 1} / {totalCount}</span>
      </div>
    </div>
  );
}

ImageInfo.propTypes = {
  caption: PropTypes.node,
  credits: PropTypes.node,
  activeItem: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(ImageInfo));
