import cx from 'classnames';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import skip from 'core/resolver/skip';
import resolve from 'core/resolver/resolve';
import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';
import Button from 'core/components/Button';

import Input from 'site/components/Input';
import Datepicker from 'site/components/Datepicker';

import { Indent } from 'site/components/Wrappers';
import { ErrorMessage, LinkSmall } from 'site/components/Texts';
import withBatId from 'site/components/BatId/withBatId';

import { VERTICAL_INDENT } from 'site/constants';

import styles from './index.styl';

function EditForm({
  handleInputChange,
  handleBlur,
  handleSubmit,
  errors,
  touched,
  values,
  serverErrorName,
}) {
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='text'
          label='Имя'
          name='first_name'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.first_name}
          error={touched.first_name && errors.first_name ? errors.first_name : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Input
          type='text'
          label='Фамилия'
          name='last_name'
          onChange={handleInputChange}
          onBlur={handleBlur}
          value={values.last_name}
          error={touched.last_name && errors.last_name ? errors.last_name : ''}
        />
      </div>
      <div className={cx(styles.row, styles.field)}>
        <Datepicker
          value={values.birthdate}
          label='Дата рождения'
          name='birthdate'
          onChange={handleInputChange}
          onBlur={handleBlur}
          error={touched.birthdate && errors.birthdate ? errors.birthdate : ''}
          className={styles.date}
        />
      </div>
      <Indent top={VERTICAL_INDENT} />
      <div className={cx(styles.field, styles.footer)}>
        <Button typeAttribute='submit'>Сохранить</Button>
        <Link
          type='secondary'
          to='/profile'
        >
          <LinkSmall>Отмена</LinkSmall>
        </Link>
      </div>
      {serverErrorName && <ErrorMessage className={styles.errorMessage}>{serverErrorName}</ErrorMessage>}
    </form>
  );
}

EditForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
};

export default compose(
  skip,
  withBatId,
  resolve('profile', ({ batId }) => {
    return batId.getProfile();
  }),
  withRouter,
)(EditForm);
