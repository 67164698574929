import PropTypes from 'prop-types';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import { withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Rubric from 'core/components/Rubric';
import withTheme from 'core/components/theme';

import { Indent } from 'site/components/Wrappers';
import { MainTitle } from 'site/components/Texts';
import Pagination from 'site/components/Pagination';
import RubricOrTagTopics from 'site/components/RubricOrTagTopics';

import { Billboard } from 'site/components/Ad/AdByBreakpoint';

import styles from './index.styl';

import {
  RUBRICS_OR_TAGS_LIMIT,
  RUBRICS_OR_TAGS_LIMIT_MOBILE,
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import InfinityTopics from './InfinityTopics';


/**
 * Рубрики, на которых не нужен бесконечный скролл.
 */
const INFINITY_SCROLL_EXCLUDES = [
  'vse-materialy',
  'sovremennye-tekhnologii',
];

const PaginationBlock = (props) => {
  return (
    <>
      <Indent top={40} />
      <Pagination {...props} />
    </>
  );
};

const RubricPage = ({ rawRubric, rawTopics, isMobile, theme, location, match }) => {
  const limit = isMobile ? RUBRICS_OR_TAGS_LIMIT_MOBILE : RUBRICS_OR_TAGS_LIMIT;
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const withoutInfinityScroll = INFINITY_SCROLL_EXCLUDES
    .some(rubric => rubric === match.params.level_1);

  return (
    <>
      <Billboard />
      <Indent
        top={vertical}
        right={horizontal}
        bottom={40}
        left={horizontal}
      >
        <style jsx>{`
          .${styles.title}
            border-bottom-color ${theme.colors.primary}
        `}</style>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          limit={limit}
          pagination={withoutInfinityScroll
            ? PaginationBlock
            : () => null}
          titleComponent={({ children }) => (
            <MainTitle tag='h1' className={styles.title}>
              {children}
            </MainTitle>
          )}
          headerSpacing={20}
        >
          {({ topics }) => {
            return (
              <>
                {withoutInfinityScroll
                  ? <RubricOrTagTopics topics={topics} />
                  : (
                    <InfinityTopics
                      key={location.pathname}
                      limit={limit}
                      topics={topics}
                    />
                  )
                }
              </>
            );
          }}
        </Rubric>
      </Indent>
    </>
  );
};

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
    }),
  }).isRequired,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: props => rawTopicsFetcher({
    limit: props.isMobile ? RUBRICS_OR_TAGS_LIMIT_MOBILE : RUBRICS_OR_TAGS_LIMIT,
    include: 'image',
  })(props),
});

export default withPageHocs(dataProvider)(withTheme(withBreakpoint(RubricPage)));
