import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { topicsFetcher } from 'core/fetchers/topics';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';
import bindProps from 'core/components/bindProps';
import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { Indent } from 'site/components/Wrappers';
import Subscription from 'site/components/Subscription';
import TopicsGallery from 'site/components/TopicsGallery';
import TopicsGalleryBlue from 'site/components/TopicsGalleryBlue';
import { Slider } from 'site/components/Ad/mobile';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';
import AdsWrapper from 'site/components/Wrappers/AdsWrapper.js';

import { INDENT, SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import List2 from './List2';
import List4 from './List4';


function MainPage(props) {
  const {
    list1Topics,
    list2Topics,
    list3Topics,
    list4Topics,
    list5Topics,
    isMobile,
  } = props;

  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const BindedList3 = bindProps({ content: list3Topics })(TopicsGalleryBlue);

  return (
    <Page
      title='Yourchoice &mdash; новости и статьи о лайфстайле современного человека'
      description='Рассказываем о возможностях минимизировать вред от привычки и помогаем сделать осознанный выбор'
    >
      <Indent
        left={horizontal}
        right={horizontal}
        {...isMobile && { top: -20 }}
      >
        <TopicsGallery content={list1Topics} />
        <AdWrapper top={INDENT}>
          <Billboard />
        </AdWrapper>
        <Indent top={INDENT} />
        <List2 content={list2Topics} />
        <Indent top={INDENT} />
        <Desktop>
          <BindedList3 />
        </Desktop>
        <Mobile>
          <AdsWrapper>
            <Slider
              fallback={BindedList3}
              placeholder={BindedList3}
            />
          </AdsWrapper>
        </Mobile>
        <Indent bottom={INDENT} />
        <Subscription />
        <Indent top={INDENT} />
        <List4 content={list4Topics} />
        <Indent top={INDENT} />
        <Billboard />
        <Indent top={INDENT} />
        <TopicsGalleryBlue content={list5Topics} />
        <Indent top={INDENT} />
      </Indent>
    </Page>
  );
}

MainPage.propTypes = {
  list1Topics: PropTypes.array,
  list2Topics: PropTypes.array,
  list3Topics: PropTypes.array,
  list4Topics: PropTypes.array,
  list5Topics: PropTypes.array,
  isMobile: PropTypes.bool,
};

const commonApiProps = {
  sort: 'list',
  include: 'image,rubric',
};

const dataProvider = resolve({
  list1Topics: topicsFetcher({
    list: 'main_headshot_slider3',
    ...commonApiProps,
  }),

  list2Topics: topicsFetcher({
    list: 'main_8_top',
    sort: 'list',
    limit: 8,
    include: 'image,rubric,authors',
  }),

  list3Topics: topicsFetcher({
    list: 'main_middle_slider3',
    ...commonApiProps,
  }),

  list4Topics: topicsFetcher({
    list: 'main_8_bottom',
    sort: 'list',
    limit: 8,
    include: 'image,rubric,authors',
  }),

  list5Topics: topicsFetcher({
    list: 'main_bot_slider3',
    ...commonApiProps,
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(MainPage));
