import oneLine from 'common-tags/lib/oneLine';

import MerriweatherRegular from './Merriweather/merriweather-regular.woff';
import MerriweatherRegular2 from './Merriweather/merriweather-regular.woff2';
import MerriweatherItalic from './Merriweather/merriweather-italic.woff';
import MerriweatherItalic2 from './Merriweather/merriweather-italic.woff2';
import MerriweatherBoldItalic from './Merriweather/merriweather-bolditalic.woff';
import MerriweatherBoldItalic2 from './Merriweather/merriweather-bolditalic.woff2';

import MontserratMedium from './Montserrat/montserrat-medium.woff';
import MontserratMedium2 from './Montserrat/montserrat-medium.woff2';
import MontserratSemiBold from './Montserrat/montserrat-semibold.woff';
import MontserratSemiBold2 from './Montserrat/montserrat-semibold.woff2';
import MontserratBold from './Montserrat/montserrat-bold.woff';
import MontserratBold2 from './Montserrat/montserrat-bold.woff2';


const fonts = {
  text: 'Merriweather, Merriweather-fallback, serif',
  display: 'Montserrat, Montserrat-fallback, sans-serif',
  get nuance() {
    return this.text;
  },
  faces: [
    {
      fontFamily: 'Merriweather',
      src: oneLine`
        local('Merriweather-Regular'),
        url(${MerriweatherRegular2}) format('woff2'),
        url(${MerriweatherRegular}) format('woff')
      `,
    },
    {
      fontFamily: 'Merriweather',
      fontStyle: 'italic',
      src: oneLine`
        local('Merriweather-Italic'),
        url(${MerriweatherItalic2}) format('woff2'),
        url(${MerriweatherItalic}) format('woff')
      `,
    },
    {
      fontFamily: 'Merriweather',
      fontWeight: 700,
      src: oneLine`
        local('Merriweather-BoldItalic'),
        url(${MerriweatherBoldItalic2}) format('woff2'),
        url(${MerriweatherBoldItalic}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      src: oneLine`
        local('Montserrat-Medium'),
        url(${MontserratMedium2}) format('woff2'),
        url(${MontserratMedium}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      src: oneLine`
        local('Montserrat-SemiBold'),
        url(${MontserratSemiBold2}) format('woff2'),
        url(${MontserratSemiBold}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      src: oneLine`
        local('Montserrat-Bold'),
        url(${MontserratBold2}) format('woff2'),
        url(${MontserratBold}) format('woff')
      `,
    },
    {
      fontFamily: 'Montserrat-fallback',
      sizeAdjust: '113.4%',
      ascentOverride: '82%',
      src: 'local("Arial")',
    },
    {
      fontFamily: 'Merriweather-fallback',
      sizeAdjust: '122.8',
      ascentOverride: '76%',
      src: 'local("Times New Roman")',
    },
  ],
};

export default fonts;
