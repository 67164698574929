import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Logos from '../components/Logos';
import Buttons from '../components/Buttons';
import Agreement from '../components/Agreement';
import Description from '../components/Description';
import Title from '../components/Title';

import styles from './index.styl';

function ContentMain({ theme, yesAnswerHandler, noAnswerHandler }) {
  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.container}
          color ${theme.colors.primary}
      `}</style>
      <Desktop><Logos className={styles.logos} /></Desktop>
      <Title className={styles.title} />
      <Mobile><Description className={styles.description} /></Mobile>
      <Buttons
        className={styles.buttons}
        noAnswerHandler={noAnswerHandler}
        yesAnswerHandler={yesAnswerHandler}
      />
      <Desktop><Description className={styles.description} /></Desktop>
      <Agreement className={styles.agreement} />
      <Mobile><Logos /></Mobile>
    </div>
  );
}

ContentMain.propTypes = {
  theme: PropTypes.object,
  noAnswerHandler: PropTypes.func.isRequired,
  yesAnswerHandler: PropTypes.func.isRequired,
};

export default withTheme(ContentMain);
