import PropTypes from 'prop-types';
import cx from 'classnames';

import Image from 'core/components/Image';

import withTheme from 'core/components/theme';
import { Desktop } from 'core/components/breakpoint';

import styles from './index.styl';

const mapTitles = {
  1: {
    title: <span>Зарегистрируйся</span>,
  },
  2: {
    title: <span>Зарегистрируйся и получи 100 рублей на телефон</span>,
  },
  3: {
    title: <span>Подтверди почту<br /> и получи 100 рублей на телефон</span>,
  },
};

function Header({ theme, type }) {
  const { title } = mapTitles[type] || {};
  const isSuccessPage = type === 3;

  return (
    <div className={cx('header', isSuccessPage && styles._isSuccessPage)}>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}
      `}</style>
      <h1 className={styles.title}>{title}</h1>

      {type === 3 && (
        <div className={styles.envelope}>
          <Image
            url={require('./images/envelope.png')}
            width={124}
            height={80}
          />
        </div>
      )}
      {(type === 1 || type === 2) && (
        <Desktop>
          <div className={styles.phone}>
            <Image
              url={require('./images/phone_100.png')}
              width={390}
              height={297}
            />
          </div>
        </Desktop>
      )}
    </div>
  );
}

Header.propTypes = {
  theme: PropTypes.object,
  isSuccessPage: PropTypes.bool,
  type: PropTypes.oneOf([1, 2, 3]),
};

export default withTheme(Header);


