import { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import skip from 'core/resolver/skip';
import resolve from 'core/resolver/resolve';
import { withRouter } from 'core/libs/router';

import H2 from 'core/components/H2';

import { Indent } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';
import withBatId from 'site/components/BatId/withBatId';

import { VERTICAL_INDENT } from 'site/constants';
import batIdPropTypes from 'site/utils/prop-types/batId';
import { profilePropTypes } from 'site/utils/prop-types/model';
import { nameValidation, birthdateValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import EditForm from './Form';

const validate = {
  first_name: name => nameValidation('Имя', name),
  last_name: name => nameValidation('Фамилия', name),
  birthdate: birthdateValidation,
};

function Edit({ history, profile, batId }) {
  const [errorName, updateErrorName] = useState(null);
  const [fieldsErrors, updateFieldsErrors] = useState(null);

  const {
    first_name: firstName,
    last_name: lastName,
    birthdate,
  } = profile;

  const initialValues = {
    first_name: firstName,
    last_name: lastName,
    birthdate: new Date(birthdate),
  };

  const submitCallback = useCallback(values => {
    return batId.updateProfile(values)
      .then(() => history.push('/profile'))
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);

        updateErrorName(errors.error);
        updateFieldsErrors(errors.fieldsErrors);
      });
  }, [batId, history]);

  return (
    <>
      <H2 is='h1'>{firstName + ' ' + lastName}</H2>
      <Indent top={VERTICAL_INDENT} />

      <BaseForm
        form={EditForm}
        submitCallback={submitCallback}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </>
  );
}

Edit.propTypes = {
  /** @ignore */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  profile: profilePropTypes,
  batId: batIdPropTypes,
};

export default compose(
  skip,
  withBatId,
  resolve('profile', ({ batId }) => {
    return batId.getProfile();
  }),
  withRouter,
)(Edit);
