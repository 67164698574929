import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import withPageHocs from 'core/components/withPageHocs';
import AuthorPage from 'core/components/AuthorPage';

import { Indent, PageIndent } from 'site/components/Wrappers';
import Pagination from 'site/components/Pagination';
import MasonryFeed from 'site/components/MasonryFeed';
import { Billboard } from 'site/components/Ad/AdByBreakpoint';

const PaginationBlock = (props) => {
  return (
    <>
      <Billboard />
      <Indent top={40} />
      <Pagination {...props} />
    </>
  );
};

const Author = ({ author, topics }) => {
  return (
    <PageIndent>
      <AuthorPage
        author={author}
        topics={topics}
        interitemSpacing={20}
        columns={4}
        pagination={PaginationBlock}
        feedRenderer={MasonryFeed}
        grid
      />
    </PageIndent>
  );
};


Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: 8,
    include: 'rubric,image',
  }),
});

export default withPageHocs(dataProvider)(Author);
