import { get } from 'core/libs/lodash';

/**
 * Тут возращается объект с ошибками от сервера.
 */
export function handleResponseErrors(response) {
  const status = get(response, 'status');
  const message = get(response, 'data.error'); // сообщение об ошибке на сервере (Например: "Ошибка создания аккаунта")
  const fieldError = get(response, 'data.field_error'); // одно поле невалидно
  const fieldErrors = get(response, 'data.field_errors'); // несколько полей невалидно

  const attemptsCount = get(response, 'data.attempts_count');
  const bannedUntil = get(response, 'data.banned_until');
  let smsVerifyErrors;

  if (attemptsCount || bannedUntil) {
    smsVerifyErrors = {
      ...(attemptsCount && { attemptsCount }),
      ...(bannedUntil && { bannedUntil }),
    };
  }

  return {
    status,
    error: message || 'Произошла ошибка',
    ...(fieldError && { fieldsErrors: { [fieldError[0]]: fieldError[1] } }),
    ...(fieldErrors && { fieldsErrors: fieldErrors }),
    ...(smsVerifyErrors && { smsVerifyErrors }),
  };
}

function transformToDigitalClock(time) {
  if (String(time).length === 1) return '0' + time;
  return time;
}

export function secondsToTime(secs) {
  const divisorForMinutes = secs % (60 * 60);
  const minutes = transformToDigitalClock(Math.floor(divisorForMinutes / 60));

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = transformToDigitalClock(Math.ceil(divisorForSeconds));

  return `${minutes}:${seconds}`;
}
