import PropTypes from 'prop-types';

import Link from 'core/components/Link';

import { AgeWallAgreement } from 'site/components/Texts';

export default function Agreement({ className }) {
  return (
    <AgeWallAgreement className={className}>
      Сайт использует файлы cookie. Они позволяют узнавать вас и&nbsp;получать информацию о&nbsp;вашем пользовательском опыте.
      Продолжая просмотр сайта, я соглашаюсь с&nbsp;использованием файлов cookie владельцем сайта в&nbsp;соответствии <Link type='underlined' to='/cookies_policy'>с&nbsp;«Политикой cookie»</Link>
    </AgeWallAgreement>
  );
}

Agreement.propTypes = {
  className: PropTypes.string,
};
