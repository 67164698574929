import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { AgeWallDescription, AgeWallAgreement } from 'site/components/Texts';

import { checkisOzonSpec, checkIsPhoneSpec } from 'site/utils';

function Description({ className, location }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const TextWrapper = isOzonSpec || isPhoneSpec
    ? AgeWallAgreement
    : AgeWallDescription;

  return (
    <TextWrapper className={className}>
      Сайт содержит информацию, которая не&nbsp;рекомендована лицам, не&nbsp;достигшим совершеннолетия. Для&nbsp;входа на&nbsp;сайт, подтвердите&nbsp;свой&nbsp;возраст.
    </TextWrapper>
  );
}

Description.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(Description);
