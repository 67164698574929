import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { secondsToTime } from 'site/utils/formHelpers';

let intervalId;

export default function Timer({ delay, resetTimer }) {
  const [secondsLeft, setSecondsLeft] = useState(delay);

  useState(() => {
    setSecondsLeft(delay);
  }, [delay]);

  useEffect(() => {
    if (!secondsLeft) {
      resetTimer();
      intervalId && clearInterval(intervalId);
    }

    intervalId = setInterval(() => {
      setSecondsLeft(secondsLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [secondsLeft, resetTimer]);

  return <span>{secondsToTime(secondsLeft)}</span>;
}

Timer.defaultProps = {
  delay: 60,
};

Timer.propTypes = {
  resetTimer: PropTypes.func,
  delay: PropTypes.number,
};
