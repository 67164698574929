import Page from 'core/components/Page';
import Text from 'core/components/Text';
import H1 from 'core/components/H1';
import H3 from 'core/components/H3';

import { Indent, PageIndent } from 'site/components/Wrappers';

const title = 'Пресс-релиз';

function Policy() {
  return (
    <Page title={title}>
      <PageIndent>
        <Text>
          <H1>{title}</H1>
          <Indent top={30} />
          <H3>В РОССИИ ПОЯВИТСЯ ПЕРВОЕ ОБРАЗОВАТЕЛЬНОЕ И РАЗВЛЕКАТЕЛЬНОЕ ОНЛАЙН-МЕДИА О КУЛЬТУРЕ ПОТРЕБЛЕНИЯ НИКОТИНА.</H3>
          <Indent top={15} />
          <p>
            <b>
              <i>
                В апреле 2021 года стартует медиа YourChoice, которое расскажет совершеннолетним потребителям продуктов с никотином о возможностях минимизировать вред от привычки, поможет сделать осознанный выбор во многих сферах жизни и внести свой вклад в создание лучшего будущего для общества.
              </i>
            </b>
          </p>
          <p>
            Новое медиа будет соответствовать ключевым интересам потребителей продуктов с никотином, которых в Рунете более 10 миллионов*. Они не только изучают влияние привычки на здоровье, но и следят за гаджетами, средствами ухода, ходят в популярные бары и рестораны, интересуются автомобилями, тенденциями в моде, опытом других людей. YourChoice развеет мифы, окружающие совершеннолетних потребителей, а также расскажет, как с помощью применения современных технологий снизить вред от привычки и повысить качество жизни.
          </p>
          <p>
            В России нет тематических медиа о культуре потребления никотина – множество сайтов предлагают нерелевантный современному пользователю дизайн и контент. YourChoice планирует изменить этот сегмент и стать первым онлайн-медиа с действительно интересными материалами. Также запуск YourChoice.ru станет одной из инициатив в процессе трансформации группы компаний «ITMS» согласно глобальной стратегии «A Better Tomorrow» по созданию лучшего будущего.
          </p>
          <p>
            Елена Заварзина, директор по потребительскому маркетингу «БАТ» субрегиона Россия, Центральная Азия и Беларусь о запуске платформы:
          </p>
          <p>
            <i>
              В «БАТ Россия» мы стремимся построить лучшее будущее, снижая воздействие нашего бизнеса на здоровье потребителей. На уровне Группы «БАТ» мы поставили цель к 2030 году иметь 50 миллионов пользователей наших продуктов с потенциально меньшим риском для здоровья. Для достижения этой цели мы должны обеспечить взрослым потребителям доступ к фактам о существующих альтернативах. YourChoice.ru – это место, где потребители продуктов с никотином найдут ясную, простую, основанную на фактах информацию из хорошо известного источника. Я надеюсь, что это поможет им принимать более обоснованные решения о потреблении никотина.”
            </i>
          </p>
          <p>
            Материалы платформы будут разделены на тематические блоки, которые охватывают различные интересы аудитории. В рубрике «Образ жизни» читателям расскажут о самых важных навыках, знаниях и лайфхаках. В разделе «Герои» – интервью со спикерами и экспертами, которые поделятся профессиональным и личным опытом. О новейших системах нагревания табака и лабораториях, в которых они разрабатываются, – подробно в «Технологиях».
          </p>
          <p>
            На YourChoice будут также представлены и развлекательные форматы – галереи, тесты, квизы. Интерактивные карточки расскажут сложные вещи понятными словами: как меняется законодательство о курении и какие ограничения существуют в разных странах, а в рамках подборок, пользователям расскажут, какие существуют необычные девайсы для совершеннолетних потребителей продуктов с никотином.
          </p>
          <p style={{ textAlign: 'right' }}>
            <b>
              *TGI/Marketing index, Y2020, Russia, 100K+, Smokers + Internet users
            </b>
          </p>
        </Text>
      </PageIndent>
    </Page>
  );
}

export default Policy;
