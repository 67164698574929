import { getEnv, resolve } from 'core/utils/env';

import {
  dark,
  darkMobile,
  light,
  lightMobile,
  blue,
  blueMobile,
  purple,
  purpleMobile,
} from 'site/themes';

import { host, YA_CLICK_ID } from 'site/constants';

const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
      blue: {
        base: blue,
        mobile: blueMobile,
      },
      purple: {
        base: purple,
        mobile: purpleMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.yourchoice.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/yourchoice',
    }),

    wingnutApi: resolve({
      '*': getEnv('WINGNUT_API'),
      'dev': 'https://s1.yourchoice.ru/api/v1',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    isUnityProject: false,

    schemaOrg: {
      organizationName: host,
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 280,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: `%s - ${host}`,
      defaultTitle: host,
    },

    counterIds: {
      yandexMetrika: YA_CLICK_ID,
      googleTagManager: 'GTM-M3G78KG',
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
      disableAmp: true,
    },

    // pushWoosh: {
    //   applicationCode: resolve({
    //     '*': '3387E-C2226',
    //     'staging': 'C49A8-A8EFD',
    //   }),
    //   safariWebsitePushID: 'web.ru.yourchoice.unity',
    //   defaultNotificationTitle: 'Yourchoice.ru',
    //   defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    // },

    specialLinks: [
      '/exports/rss',
      '/about', // чтобы работала хеш навигация
      '/vse-materialy/reklamnyi-topik-1.htm',
      '/vse-materialy/reklamnyi-topik-2.htm',
      '/vse-materialy/reklamnyi-topik-3.htm',
      '/sovremennye-tekhnologii/ot-pervoi-sigarety-do-pervogo-stika.htm',
      '/sovremennye-tekhnologii/sekrety-para-kak-ustroeny-tekhnologii-nagrevaniya-tabaka.htm',
      '/sovremennye-tekhnologii/khroniki-tekhnorevolyucii-kak-vysokie-tekhnologii-pomogayut-snizit-vred-ot-nezdorovykh-privychek.htm',
      '/sovremennye-tekhnologii/ot-pervoi-sigarety-do-pervogo-stika-9024022.htm',
      '/sovremennye-tekhnologii/prostota-v-detalyakh-chem-otlichayutsya-sistemy-nagrevaniya-tabaka-ot-veipov.htm',
    ],

    socials: [
      { name: 'vkontakte', link: 'https://vk.com/yourchoice_media', shortName: 'vk' },
    ],
  };
}
