import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import Text from 'core/components/Text';
import H1 from 'core/components/H1';
import Link from 'core/components/Link';

import withTheme from 'core/components/theme';

import { PageIndent, Indent } from 'site/components/Wrappers';

import styles from './index.styl';

const title = 'Согласие на обработку персональных данных';

function Agreement({ theme }) {
  return (
    <Page title={title}>
      <style jsx>{`
        .${styles.agreement}
          ol
            li:before
              font ${theme.texts.body.font}
      `}</style>
      <PageIndent>
        <Text>
          <div className={styles.agreement}>
            <Indent top={40} bottom={40}>
              <H1>{title}</H1>
            </Indent>
            <p>Я в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» свободно, своей волей и в своем интересе предоставляю свое конкретное, информированное и сознательное согласие оператору персональных данных – Акционерному обществу «Международные услуги по маркетингу табака» (адрес местонахождения – 121614, г. Москва, ул. Крылатская, д. 17, корпус 2, <Link to='http://www.batrussia.ru'>www.batrussia.ru</Link>) <strong>на обработку и поручение обработки моих персональных данных</strong> (адрес электронной почты), любыми способами, как с использованием, так и без использования средств автоматизации, в том числе включающими сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу иным лицам в случаях, предусмотренных применимым законодательством, электронное копирование, блокирование, обезличивание, удаление и уничтожение, доступ персонала, с правом осуществлять трансграничную передачу данных на территорию стран, обеспечивающих адекватную защиту персональных данных, <strong>в целях</strong>:</p>
            <ol>
              <li>направления информации об обновлениях на сайте <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link>;</li>
              <li>направления информации об обновлениях статей на сайте <Link to='https://myglo.ru'>myglo.ru</Link>;</li>
              <li>формирования информационной базы данных регистраций посетителей сайта <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link>;</li>
              <li>проведения опросов, исследований и анализ данных для совершенствования сайта <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link>;</li>
              <li>рекламы товаров, не запрещённой действующим законодательством, в том числе по сети подвижной радиотелефонной связи, доведения релевантной рекламной информации посредством телефонного информирования, направления смс и mms сообщений, сообщений на электронный адрес почты, сообщений через интернет-мессенджеры;</li>
              <li>направления новостей общего характера, в том числе касательно изменений законодательного регулирования, в том числе планируемых</li>
            </ol>
            <p>Я также даю согласие на получение мной рассылки с целью указанной в пунктах 1 – 6 настоящего согласия на адрес электронной почты от АО «МУМТ», а также его аффилированных лиц и партнёров, указанных ниже.</p>
            <p>Я даю согласие на предоставление сообщённых мной персональных данных лицам, которым оператор поручает обработку персональных данных, <strong>среди которых</strong>:</p>
            <ol>
              <li>Агентства по коммуникациям с потребителями в целях: предоставление информации об обновлениях на сайте <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link>, направление информационно-технических сообщений, связанных с регистрацией на сайте <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link>, участие в опросах (в том числе по телефону) по работе и содержимому сайта <Link to='https://yourchoice.ru'>https://yourchoice.ru</Link> в целях направления информации об обновлениях статей на сайте <Link to='https://myglo.ru'>myglo.ru</Link>;</li>
              <li>Компании, оказывающие ИТ-сервисы, в целях оптимизации качества работы сайта;</li>
            </ol>
            <p>Настоящее согласие <strong>действует в течение 70 (семидесяти) лет и может быть отозвано одним из следующих способов</strong>:</p>
            <ol>
              <li>подача заявления, подписанного собственноручной подписью субъекта персональных данных и направленного по адресу местонахождения АО «МУМТ»;</li>
              <li>звонок на номер телефона, указанный на сайте <Link to='http://www.batrussia.ru'>www.batrussia.ru</Link>.</li>
            </ol>
          </div>
        </Text>
      </PageIndent>
    </Page>
  );
}

Agreement.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Agreement);
