import { colors } from './blue';

export default {
  colors,
  controls: {
    shapka: {
      backgroundOpened: colors.content,
      dropdown: {
        background: colors.content,
      },
    },
  },
};
