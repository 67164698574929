import { METRICS } from 'site/constants';

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getMetricValue(type, name) {
  const key = [type, name].join('_');
  return METRICS[key];
}

export function setMetric(eventName) {
  if (window && window.dataLayer) {
    window.dataLayer.push({ event: eventName });
  }
}

/**
 * Отправляем событие "event" в google analytics
 */
export function sendGaEvent(param1, param2) {
  if (window && window.ga) {
    window.ga('send', 'event', param1, param2);
  }
}

/**
 * Страницы спецпроекта /spec-registration-ozon100ftb в purple теме.
 */
export function checkisOzonSpec(pathname) {
  return !!~pathname.search(/^\/spec-registration-ozon100ftb/);
}

/**
 * Страницы спецпроекта /special-reg-phone100ftb в blue теме.
 * @param pathname
 * @returns {boolean}
 */
export function checkIsPhoneSpec(pathname) {
  return !!~pathname.search(/^\/special-reg-phone100ftb/);
}
