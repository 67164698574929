import H2 from 'core/components/H2';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import { Desktop, Mobile } from 'core/components/breakpoint';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import { FieldName } from 'site/components/Texts';

import styles from './index.styl';

export default function ResetPasswordCheckEmail() {
  const title = <H2 is='h1'>Проверьте почту!</H2>;

  return (
    <MaxWidthWrapper>
      <Mobile>{title}</Mobile>
      <div className={styles.success}>
        <Desktop>{title}</Desktop>
        <FieldName className={styles.text}>
          Мы отправили на указанную вами почту письмо, с инструкциями по сбросу пароля.
          <br />
          Эту страницу можно закрыть.
        </FieldName>
        <Link to='/' className={styles.link}>
          <Button>На главную</Button>
        </Link>
      </div>
    </MaxWidthWrapper>
  );
}
