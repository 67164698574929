import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import Modal from 'core/components/Modal';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { checkisOzonSpec, checkIsPhoneSpec } from 'site/utils';

const WallModalBase = (props) => {
  const {
    theme,
    children,
    location,
    isMobile,
    ...otherProps
  } = props;

  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const isSpecProject = isOzonSpec || isPhoneSpec;

  let windowBg;
  if (isOzonSpec) {
    const bgOzon = require('./images/bgOzon.jpg');
    const bgOzonMobile = require('./images/bgOzonMobile.jpg');
    windowBg = `#440262 url(${isMobile ? bgOzonMobile : bgOzon})`;
  } else if (isPhoneSpec) {
    const bgOzon = require('./images/bgPhone.jpg');
    const bgOzonMobile = require('./images/bgPhoneMobile.jpg');
    windowBg = `#1b3476 url(${isMobile ? bgOzonMobile : bgOzon})`;
  } else {
    windowBg = `${theme.colors.modalBaseBg}`;
  }

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .modalOverlay
          background-color ${theme.colors[isSpecProject ? 'modalSpecOverlay' : 'modalBaseOverlay']}
    
        .modalWindow
          background ${windowBg}
      `}</style>
      <style jsx>{`
        .modalOverlay
          backdrop-filter blur(10px)
    
        .modalWindow
          width 800px
          outline none
          border-radius 20px
          box-shadow 0 4px 12px rgba(0, 0, 0, 0.15)
          background-size cover
    
          :global(.mobile) &
            width 95% 
      `}</style>
    </scope>
  );

  return (
    <Modal {...otherProps} scopedStyles={scopedStyles}>
      {children}
    </Modal>
  );
};

WallModalBase.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};


export default withRouter(withTheme(withBreakpoint(WallModalBase)));
