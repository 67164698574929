import { Fragment, useCallback, useState } from 'react';
import { withRouter } from 'core/libs/router';

import skip from 'core/resolver/skip';

import H2 from 'core/components/H2';

import BaseForm from 'site/components/BaseForm';

import batIdPropTypes from 'site/utils/prop-types/batId';
import { emailValidation } from 'site/utils/formValidations';
import { handleResponseErrors } from 'site/utils/formHelpers';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import withBatId from 'site/components/BatId/withBatId';
import Result from 'site/components/Result';

import Form from './Form';
import PropTypes from 'prop-types';

const validate = {
  login: emailValidation,
};

function VerifyAccountResend({ batId, location }) {
  const login = location.state && location.state.login;

  const initialValues = {
    login,
  };

  const [errorName, setErrorName] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const submitCallback = useCallback(values => {
    return batId.verifyAccountResend(values)
      .then(() => setIsSuccess(true))
      .catch(({ response }) => {
        setIsSuccess(false);
        const errors = handleResponseErrors(response);

        setErrorName(errors.error);
        setFieldsErrors(errors.fieldsErrors);
      });
  }, [batId]);


  return (
    <MaxWidthWrapper>
      <H2 is='h1'>Подтверждение email</H2>

      {isSuccess ? (
        <Result
          title='Письмо отправлено'
          subTitle={(
            <>
              Мы отправили на указанную вами почту письмо для подтверждения <span style={{ whiteSpace: 'nowrap' }}>e-mail</span>
              <br />
              Эту страницу можно закрыть.
            </>
          )}
        />
      ) : (
        <BaseForm
          form={Form}
          submitCallback={submitCallback}
          initialValues={initialValues}
          validate={validate}
          serverErrorName={errorName}
          serverFieldsErrors={fieldsErrors}
        />
      )}
    </MaxWidthWrapper>
  );
}

VerifyAccountResend.propTypes = {
  batId: batIdPropTypes,
  /** @ignore */
  location: PropTypes.object,
};

export default skip(withBatId(withRouter(VerifyAccountResend)));
