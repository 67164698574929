import PropTypes from 'prop-types';

import { callYaMetrikaMethod } from 'core/utils/counters-helper';

import { YA_CLICK_ID } from 'site/constants';

const goalParamsMap = {
  'myglo.ru': {
    id: 'GloClick',
    level2Key: 'glo_click',
  },
  'vuse.ru': {
    id: 'VuseClick',
    level2Key: 'vuse_click',
  },
  'dunhilltobacco.ru': {
    id: 'DunhillClick',
    level2Key: 'dunhill_click',
  },
};


function sendEvent(e, goalType, customClickType) {
  const closestLink = e.target.closest('a');

  if (!closestLink) return;

  const isContentLink = goalType === 'click_to_websites';
  const goalParams = isContentLink
    ? goalParamsMap[closestLink.host]
    : { level2Key: customClickType };
  const { id, level2Key } = goalParams || {};

  if (!level2Key) return;

  const level3Key = isContentLink
    ? 'link'
    : closestLink.text;
  const level4Key = isContentLink
    ? closestLink.href
    : 'null';

  const goalValue = {
    [goalType]: {
      [level2Key]: {
        [level3Key]: {
          [level4Key]: {
            page_url: document.URL,
          },
        },
      },
    },
  };

  callYaMetrikaMethod(YA_CLICK_ID, goalType, ...[id, goalValue].filter(Boolean));
}


function YaMetrikaClickSender(props) {
  const {
    goalType,
    clickType,
    children,
  } = props;

  // TODO: обернуть хендлер в useCallback после переезда на новый резолвинг,
  // Пока useCallback не заюзать без skip, а он ломает SSR
  return <div onClickCapture={e => sendEvent(e, goalType, clickType)}>{children}</div>;
}

YaMetrikaClickSender.propTypes = {
  goalType: PropTypes.oneOf(['click_to_websites', 'navigation']).isRequired,
  clickType: (props, propName, componentName) => {
    if (props.goalType !== 'click_to_websites' && typeof props[propName] !== 'string') {
      return new Error(
        'Invalid prop `' + propName + '` supplied to' +
        ' `' + componentName + '`. ' +
        'You must provide `' + propName + '` when `goalType` is not `click_to_websites`. ' +
        'Events will not be sent.'
      );
    }
    return null;
  },
};

export default YaMetrikaClickSender;
