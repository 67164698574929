import PropTypes from 'prop-types';

import { useRef, useEffect } from 'react';

import SearchInput from 'core/components/SearchInput';

import skip from 'core/resolver/skip';

import { withRouter } from 'core/libs/router';

import { buildSearchLink } from 'core/utils/url-helper';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import SearchModalBase from 'site/components/Modals/Search';

function submitHandler(history, toggleModal, event) {
  event.preventDefault();
  history.push(buildSearchLink('', event.target.query.value));
  toggleModal();
}

function SearchModal({ isOpen, toggleModal, history }) {
  // создаем ссылку на инпут, для того, чтобы сделать автофокус
  // стандартный autoFocus не отработает в модалке, потому что не будет события загрузки страницы
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [isOpen]);

  return (
    <SearchModalBase
      isOpen={isOpen}
      onClose={toggleModal}
    >
      <MaxWidthWrapper>
        <form onSubmit={submitHandler.bind(this, history, toggleModal)}>
          <SearchInput name='query' innerRef={inputRef} />
        </form>
      </MaxWidthWrapper>
    </SearchModalBase>
  );
}

SearchModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  history: PropTypes.object,
};

export default skip(withRouter(SearchModal));
