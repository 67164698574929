import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'core/components/Button';

import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';

export default function Buttons({ className, noAnswerHandler, yesAnswerHandler }) {
  return (
    <div className={cx(styles.buttons, className)}>
      <Button onClick={yesAnswerHandler}>Да</Button>
      <Indent left={20} />
      <Button type='secondary' onClick={noAnswerHandler}>Нет</Button>
    </div>
  );
}

Buttons.propTypes = {
  className: PropTypes.string,
  noAnswerHandler: PropTypes.func.isRequired,
  yesAnswerHandler: PropTypes.func.isRequired,
};
