import PropTypes from 'prop-types';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Indent from './Indent';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

function PageIndent(props) {
  const {
    children,
    isMobile,
    left,
    right,
    top,
    bottom,
  } = props;

  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Indent
      tag='main'
      left={left || horizontal}
      right={right || horizontal}
      top={top || vertical}
      bottom={bottom || vertical}
    >
      {children}
    </Indent>
  );
}

PageIndent.propTypes = {
  isMobile: PropTypes.bool,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  bottom: PropTypes.number,
};

export default withBreakpoint(PageIndent);
