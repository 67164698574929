import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Error from 'site/components/Error';
import { Description, FieldName } from 'site/components/Texts';

import styles from './index.styl';

function Input(props) {
  const {
    theme,
    size,
    label,
    type,
    className,
    icon: Icon,
    iconPosition,
    hint,
    success,
    ...otherProps
  } = props;

  const { disabled, error } = otherProps;

  const {
    colors: colorAtoms,
    controls: {
      input: inputAtoms,
      input: {
        const: {
          sizes: {
            [size]: inputSizeAtoms,
          },
        },
        basic: inputStateAtoms,
      },
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.name}
        .${styles.hint}
          color ${colorAtoms.primary}

        .${styles.success}
          color ${colorAtoms.success}

        .${styles.input}
          color ${inputAtoms.const.color}
          font ${inputSizeAtoms.font}
          height ${inputSizeAtoms.height}
          padding ${inputSizeAtoms.padding}
          border-radius ${inputAtoms.const.radius}
          border ${inputStateAtoms.idle.border}
          background-color ${inputStateAtoms.idle.background}
          transition all ${theme.animations.hover}
          box-shadow ${inputStateAtoms.idle.boxShadow}

          @media screen and (-webkit-min-device-pixel-ratio: 0)
            // disable page zoom with input:focus on iOS devices
            font-size 16px

          &::placeholder
            color ${inputStateAtoms.idle.placeholderColor}
          &:-ms-input-placeholder
            color ${inputStateAtoms.placeholderColor}

          &:hover
            color ${inputStateAtoms.hover.color || inputAtoms.const.color}
            border ${inputStateAtoms.hover.border}
            background-color ${inputStateAtoms.hover.background}

          &:focus
          &:active
            color ${inputStateAtoms.focused.color || inputAtoms.const.color}
            border ${inputStateAtoms.focused.border}
            background-color ${inputStateAtoms.focused.background}

          ._error &
            color ${inputStateAtoms.error.color || inputAtoms.const.color}
            border ${inputStateAtoms.error.border}
            background-color ${inputStateAtoms.error.background}
            box-shadow ${inputStateAtoms.error.boxShadow}

          ._disabled &
            color ${inputStateAtoms.disabled.color || inputAtoms.const.color}
            border ${inputStateAtoms.disabled.border}
            background-color ${inputStateAtoms.disabled.background}

            &::placeholder
              color ${inputStateAtoms.disabled.color || inputStateAtoms.placeholderColor}
            &:-ms-input-placeholder
              color ${inputStateAtoms.disabled.color || inputStateAtoms.placeholderColor}

          .${styles.withIconBefore} &
            padding-left 40px

        .${styles.icon}
          color ${theme.colors.grey1}
          height ${inputSizeAtoms.height}
      `}</style>
    </scope>
  );

  return (
    <label
      className={scope.wrapClassNames(
        styles.label,
        disabled && '_disabled',
        error && '_error',
      )}
    >
      {label && (
        <FieldName className={scope.wrapClassNames(styles.name)}>
          {label}
        </FieldName>
      )}
      <div
        className={scope.wrapClassNames(
          iconPosition === 'before' && styles.withIconBefore,
          iconPosition === 'after' && styles.withIconAfter,
        )}
      >
        {Icon && (
          <div className={scope.wrapClassNames(styles.icon)}>
            <Icon />
          </div>
        )}
        <div className={styles.box}>
          <input
            type={type}
            className={scope.wrapClassNames(styles.input, className)}
            {...otherProps}
          />
        </div>
      </div>
      {hint && <Description className={scope.wrapClassNames(styles.hint)}>{hint}</Description>}
      {success && <Description className={scope.wrapClassNames(styles.success)}>{success}</Description>}
      {error && <Error error={error} />}
      <scope.styles />
    </label>
  );
}

Input.defaultProps = {
  size: 'medium',
  type: 'text',
};

Input.propTypes = {
  theme: PropTypes.object,
  size: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  icon: PropTypes.func,
  iconPosition: PropTypes.string,
  /**
   * Подсказка, что делает это поле
   */
  hint: PropTypes.string,
  /**
   * Сообщение подсказка.
   *
   * Например: Телефон подтвержден
   */
  success: PropTypes.string,
};

export default withTheme(Input);
