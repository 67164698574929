import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import withTheme from 'core/components/theme';

import styles from './index.styl';

/**
 * Компонент для вставки заглавной картинки в спецпроект "100 рублей на телефон".
 */
function NeonDecoration({ location }) {
  const isSignedPage = !!~location.pathname.search(/^\/special-reg-phone100ftb\/signed\/?/);

  if (isSignedPage) return null;

  return <div className={styles.neonDecoration} />;
}

NeonDecoration.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(withRouter(NeonDecoration));
