import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { Description } from 'site/components/Texts';

import styles from './index.styl';

const FoundTextBlock = ({ total, theme }) => {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.description}
          color ${theme.colors.grey2}
      `}</style>
    </scope>
  );

  return (
    <div>
      <Description className={scope.wrapClassNames(styles.description)}>Найдено {total}:</Description>
      <scope.styles />
    </div>
  );
};

FoundTextBlock.propTypes = {
  /** Количество публикаций */
  total: PropTypes.number,
  /** @ignore */
  theme: PropTypes.object,
};

const FoundTextBlockWithHOCs = withTheme(FoundTextBlock);
FoundTextBlockWithHOCs.displayName = 'FoundTextBlock';

export default FoundTextBlockWithHOCs;
export { FoundTextBlock as StorybookComponent };
