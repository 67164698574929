import PropTypes from 'prop-types';

import { ErrorMessageSmall } from 'site/components/Texts';

import { capitalize } from 'site/utils';

import styles from './index.styl';

/**
 * Сообщение об ошибке для полей формы.
 * Checkbox, Input.
 */
export default function Error({ error }) {
  if (!error) return null;

  const errors = typeof(error) === 'string' ? [error] : error;

  return (
    <>
      {errors.map((err, index) => (
        <ErrorMessageSmall key={index} className={styles.errorMessage}>
          {capitalize(err)}
        </ErrorMessageSmall>
      ))}
    </>
  );
}

Error.propTypes = {
  /** Сообщение об ошибке */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
