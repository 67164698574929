import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { checkIsPhoneSpec, checkisOzonSpec } from 'site/utils';

import BaseFooter from './BaseFooter';
import SpecFooter from './SpecFooter';

function Footer({ location }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);

  if (isOzonSpec || isPhoneSpec) return <SpecFooter />;

  return <BaseFooter />;
}

Footer.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Footer);
