import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { withRouter, Redirect } from 'core/libs/router';
import cx from 'classnames';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import NeonHeaderOzon from 'site/components/NeonHeaderOzon';
import NeonHeaderPhone from 'site/components/NeonHeaderPhone';
import NeonDecoration from 'site/components/NeonDecoration';
import { FieldName } from 'site/components/Texts';

import { checkisOzonSpec, checkIsPhoneSpec, sendGaEvent } from 'site/utils';

import styles from './index.styl';

function Success({ location, theme }) {
  const isOzonSpec = checkisOzonSpec(location.pathname);
  const isPhoneSpec = checkIsPhoneSpec(location.pathname);
  const { success } = location.state || {};
  /** Флаг для удобства тестирования страницы `/spec-registration-ozon100ftb/success` */
  const showOnlyForTesting = location.search.indexOf('show_success') !== -1;

  useEffect(() => {
    if (!success) return;

    if (isOzonSpec) {
      sendGaEvent('OZON_form_1', 'thank_you_page_ozon_form_1');
    }
    if (isPhoneSpec) {
      sendGaEvent('OZON_form_2', 'thank_you_page_ozon_form_2');
    }
  }, [success, isOzonSpec, isPhoneSpec]);

  if (!success && !showOnlyForTesting) {
    if (isOzonSpec) {
      return <Redirect to='/spec-registration-ozon100ftb' />;
    }
    if (isPhoneSpec) {
      return <Redirect to='/special-reg-phone100ftb' />;
    }
  }

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.success}
          color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <>
      {isPhoneSpec && <NeonDecoration />}
      <div className={cx(styles.wrapper, isPhoneSpec && styles._isPhoneSpec)}>
        {isOzonSpec && <NeonHeaderOzon type={2} />}
        {isPhoneSpec && <NeonHeaderPhone type={3} />}
        <FieldName className={scope.wrapClassNames(styles.success)}>Cпасибо за регистрацию!</FieldName>
        <scope.styles />
      </div>
    </>
  );
}

Success.propTypes = {
  location: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(withRouter(withBreakpoint(Success)));
