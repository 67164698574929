export default function AdsWrapper({ children }) {
  return (
    <div className='wrapper'>
      <style jsx>{`
        .wrapper
          background #FFCBCC
      `}</style>
      {children}
    </div>
  );
}
