import { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'core/libs/recompose';

import { withRouter } from 'core/libs/router';

import skip from 'core/resolver/skip';
import H2 from 'core/components/H2';

import withBatId from 'site/components/BatId/withBatId';
import { Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';

import { handleResponseErrors } from 'site/utils/formHelpers';
import { emailValidation } from 'site/utils/formValidations';
import batIdPropTypes from 'site/utils/prop-types/batId';

import { VERTICAL_INDENT } from 'site/constants';

import Form from './Form';

const validate = { login: emailValidation };

const initialValues = { login: '' };

function ResetPasswordByEmail({ history, batId }) {
  const [errorName, setErrorName] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState(null);

  const handleSubmit = values => {
    return batId.resetPasswordRequest(values)
      .then(() => {
        history.push('/login/reset_password_check_email');
      })
      .catch(({ response }) => {
        const errors = handleResponseErrors(response);
        setErrorName(errors.error);
        setFieldsErrors(errors.fieldsErrors);
      });
  };

  return (
    <MaxWidthWrapper>
      <H2 is='h1'>Восстановление пароля</H2>
      <Indent top={VERTICAL_INDENT} />
      <BaseForm
        form={Form}
        submitCallback={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        serverErrorName={errorName}
        serverFieldsErrors={fieldsErrors}
      />
    </MaxWidthWrapper>
  );
}

ResetPasswordByEmail.propTypes = {
  /** @ignore */
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  batId: batIdPropTypes,
};

export default compose(
  skip,
  withRouter,
  withBatId,
)(ResetPasswordByEmail);
