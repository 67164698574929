import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Close from 'site/icons/Close';

import styles from './index.styl';

function LoginHeader({ theme, title, onClose }) {
  return (
    <div className={styles.loginHeader}>
      <style jsx>{`
        .${styles.loginHeader}
          :global(svg)
            transition color ${theme.animations.hover}

            &:hover
              color ${theme.colors.grey1}

        .loginTitle
          font 700 19px/27px ${theme.fonts.display}
      `}</style>

      <div className='loginTitle'>{title}</div>
      <Close
        width={16}
        height={16}
        onClick={onClose}
      />
    </div>
  );
}

LoginHeader.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withTheme(LoginHeader);
