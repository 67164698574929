import PropTypes from 'prop-types';
import cx from 'classnames';

import H2 from 'core/components/H2';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import { Desktop, Mobile } from 'core/components/breakpoint';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { MaxWidthWrapper } from 'site/components/Wrappers';
import { FieldName, ErrorMessage } from 'site/components/Texts';

import styles from './index.styl';

function Result(props) {
  const {
    title,
    subTitle,
    errorMessage,
    centered,
    theme,
    withoutBackButton,
  } = props;

  const heading = <H2 is='h1'>{title}</H2>;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .text
          color ${theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <MaxWidthWrapper>
      <Mobile>{heading}</Mobile>
      <div className={cx(styles.result, centered && styles._centered)}>
        <Desktop>{heading}</Desktop>
        {subTitle && (
          <FieldName className={scope.wrapClassNames(styles.text, 'text')}>{subTitle}</FieldName>
        )}
        {errorMessage && (
          <ErrorMessage className={scope.wrapClassNames(styles.text, 'text')}>{errorMessage}</ErrorMessage>
        )}
        {!withoutBackButton && (
          <Link to='/' className={styles.link}>
            <Button>На главную страницу</Button>
          </Link>
        )}
      </div>
      <scope.styles />
    </MaxWidthWrapper>
  );
}

Result.propTypes = {
  /** Заголовок блока */
  title: PropTypes.node,
  /** Подзаголовок */
  subTitle: PropTypes.node,
  /** Сообщение об ошибке */
  errorMessage: PropTypes.node,
  /** Свойство, отвечающее центрирование всех элементов по вертикали */
  centered: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** Флаг, позволяющий скрыть кнопку "На главную страницу" */
  withoutBackButton: PropTypes.bool,
};

const ResultWithHOCs = withTheme(Result);
ResultWithHOCs.displayName = 'Result';

export default ResultWithHOCs;
export { Result as StorybookComponent };
