import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { topicDateFormat } from 'core/utils/dates';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import { RubricName, CardTitle } from 'site/components/Texts';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric'];

const relationships = resolveRelationships(['image', 'rubric'], {}, {
  image: {
    versions: {},
  },
});

function Card2(props) {
  const {
    content,
    type,
    breakpoint,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: published,
  } = content.attributes;

  const {
    link_attrs: linkAttrs,
  } = content.extra || {};

  const {
    showTime,
    imageHeight,
  } = (mapTypes[type] || {})[breakpoint] || mapTypes[type] || {};

  const {
    image: {
      versions: {
        '8_big': cover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      title: rubricTitle,
    },
  } = relationships(content);

  return (
    <div className={styles.cardContainer}>
      <style jsx>{`
        .${styles.cardContainer}
          height ${imageHeight}px
      `}</style>
      <Link
        to={link}
        type='card'
        className={styles.link}
        {...linkAttrs && {
          innerRef: node => node && linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value)),
        }}
      >
        <div className={styles.overlay} />
        {cover ? (
          <Image
            src={cover}
            previewSrc={previewCover}
            height={imageHeight}
          />
        ) : (
          <div style={{ height: imageHeight + 'px' }} />
        )}
        <div className={styles.cardTextContent}>
          <CardTitle>
            {headline}
          </CardTitle>
          <RubricName className={styles.rubric}>
            {!showTime && rubricTitle}
            {showTime && topicDateFormat(published)}
          </RubricName>
        </div>
      </Link>
    </div>
  );
}

Card2.defaultProps = {
  type: 0,
};

Card2.propTypes = {
  /** Данные для карточки */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** @ignore */
  breakpoint: PropTypes.string,
};

const Card = cardHOC(withBreakpoint(Card2));

Card.requiredPayloadImports = requiredPayloadImports;
Card.displayName = 'Card2';

export const Card2Type1 = bindProps({ type: 1 })(Card);

export default Card;
export { Card2 as StorybookComponent };
