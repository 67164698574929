import stylesAtomsFactory from 'core/themes/styles-atoms';
import stylesAtomsMobileFactory from 'core/themes/styles-atoms-mobile.js';

import baseTheme from './base';
import baseMobileTheme from './mobile';

import darkProperties from './dark';
import lightProperties from './light';
import blueProperties from './blue';
import purpleProperties from './purple';

import blueMobileProperties from './blue-mobile';
import lightMobileProperties from './light-mobile';
import darkMobileProperties from './dark-mobile';
import purpleMobileProperties from './purple-mobile';


const base = atoms => stylesAtomsFactory(baseTheme(atoms));

export const light = base(lightProperties);
export const dark = base(darkProperties);
export const blue = base(blueProperties);
export const purple =  base(purpleProperties);

export const lightMobile = stylesAtomsMobileFactory(light, baseMobileTheme(lightMobileProperties));
export const darkMobile = stylesAtomsMobileFactory(dark, baseMobileTheme(darkMobileProperties));
export const blueMobile = stylesAtomsMobileFactory(blue, baseMobileTheme(blueMobileProperties));
export const purpleMobile = stylesAtomsMobileFactory(purple, baseMobileTheme(purpleMobileProperties));

