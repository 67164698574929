import PropTypes from 'prop-types';

import MasonryFeed from 'site/components/MasonryFeed';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';

import { Billboard } from 'site/components/Ad/mobile';


const cards = [
  { component: Card2, margin: false },
  { component: Card1, margin: false },
  { component: Card2, margin: false },
  { component: Card1, margin: false },
  { component: Card1, margin: false },
  { component: Card2, margin: true },
  { component: Card1, margin: false },
  { component: Card2, margin: true },
];

function List4({ content }) {
  return (
    <MasonryFeed
      content={content}
      columns={4}
      interitemSpacing={20}
      cards={cards}
      cardsMobileAdditional={{
        4: Billboard,
      }}
    />
  );
}

List4.propTypes = {
  content: PropTypes.array,
};

export default List4;
