import Layout, { Header, Content, Footer } from 'core/components/Layout';

import { StickyLine } from 'site/components/Ad/AdByBreakpoint';

import Shapka from '../Shapka';
import SiteFooter from '../Footer';
import YaMetrikaClickSender from 'site/components/YaMetrikaClickSender';


export default function SiteLayout({ children }) {
  return (
    <Layout>
      <Header>
        <YaMetrikaClickSender goalType='navigation' clickType='menu_click'>
          <Shapka />
        </YaMetrikaClickSender>
      </Header>
      <Content>
        <YaMetrikaClickSender goalType='click_to_websites'>
          {children}
        </YaMetrikaClickSender>
      </Content>
      <Footer>
        <YaMetrikaClickSender goalType='navigation' clickType='menu_click'>
          <SiteFooter />
        </YaMetrikaClickSender>
      </Footer>
      <StickyLine />
    </Layout>
  );
}
