import PropTypes from 'prop-types';

import coreSnippet from 'core/components/TopicContent/blocks/snippet';
import { VIDEO_PROVIDERS } from 'core/site/constants';

import { Indent } from 'site/components/Wrappers';

export default function Snippet(props) {
  const {
    block,
    parentProps: {
      isDesktop,
    },
  } = props;

  const isNotVideo = VIDEO_PROVIDERS.indexOf(block.attributes.provider) === -1;
  const indent = isNotVideo && isDesktop ? 70 : 0;

  return (
    <Indent left={indent} right={indent}>
      {coreSnippet(block)}
    </Indent>
  );
}

Snippet.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
