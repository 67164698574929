import PropTypes from 'prop-types';

import { AgeWallTitle } from 'site/components/Texts';

export default function Title({ className }) {
  return (
    <AgeWallTitle className={className}>
      Вам исполнилось 18 лет?
    </AgeWallTitle>
  );
}

Title.propTypes = {
  className: PropTypes.string,
};
