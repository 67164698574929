import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import Image from 'core/components/Image';
import Swipe, { Slide } from 'core/components/Swipe';
import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';

import resolveRelationships from 'core/utils/relationships';

import { MainTitle, RubricName } from 'site/components/Texts';
import { NegativeMobile } from 'site/components/Wrappers';

import ArrowLeft from 'site/icons/ArrowLeft.svg';
import ArrowRight from 'site/icons/ArrowRight.svg';

import styles from './index.styl';


const relationships = resolveRelationships(['image', 'rubric'], {}, { image: { versions: {} } });

const TopicsGallery = (props) => {
  const {
    content,
    isMobile,
    theme,
  } = props;

  const swipeEl = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = ({ index }) => setActiveIndex(index);
  const goToHandler = index => {
    if (index < 0) return swipeEl.current.goto(content.length - 1);
    if (index === content.length) return swipeEl.current.goto(0);
    return swipeEl.current.goto(index);
  };

  if (!content.length) {
    return null;
  }

  return (
    <div className={styles.swiperContainer}>
      <style jsx>{`
        .${styles.control}
          &:hover
            :global(.desktop) &
              color ${theme.colors.active1}

        .${styles.line}
          background ${theme.colors.primary}

        .${styles.dot}
          background ${theme.colors.grey1}

          &._active
            background ${theme.colors.active1}
        `}</style>
      <Desktop>
        <div className={styles.line} />
      </Desktop>
      <NegativeMobile>
        <div className={styles.slideContainer}>
          <div className={styles.controls}>
            <div
              className={styles.control}
              onClick={() => goToHandler(activeIndex - 1)}
            >
              <ArrowLeft />
            </div>
            <div
              className={styles.control}
              onClick={() => goToHandler(activeIndex + 1)}
            >
              <ArrowRight />
            </div>
          </div>
          <Swipe
            ref={swipeEl}
            onActiveIndexChange={onActiveIndexChange}
            effect='fade'
            speed={150}
          >
            {content.map((topic, index) => {
              const {
                rubric: {
                  title: rubricTitle,
                },
                image: {
                  versions: {
                    headshot_slider: cover,
                  },
                },
              } = relationships(topic);

              const {
                link,
              } = topic.attributes;

              return (
                <Slide width='100%' key={index}>
                  <Link
                    to={link}
                    className={cx(styles.link)}
                    type='card'
                  >
                    <div className={styles.slideContent}>
                      <div className={styles.titleContainer}>
                        <div>
                          <RubricName className={styles.rubric}>
                            {rubricTitle}
                          </RubricName>
                        </div>
                        <MainTitle
                          className={styles.title}
                          tag='span'
                          // это нужно, чтобы пофиксить проблемы с выносными элементами шрифта при наличии фона у блока
                          style={isMobile ? { lineHeight: '34px' } : {}}
                        >
                          {topic.attributes.headline}
                        </MainTitle>
                      </div>
                      <div className={styles.imageContainer}>
                        <Image
                          src={cover}
                          maxWidth={650}
                          height={isMobile ? 360 : 490}
                        />
                      </div>
                    </div>
                  </Link>
                </Slide>
              );
            })}
          </Swipe>
          <Mobile>
            <div className={styles.dots}>
              {content.map((topic, index) => (
                <div key={index} className={cx(
                  styles.dot,
                  index === activeIndex ? '_active' : null
                )}
                />
              ))}
            </div>
          </Mobile>
        </div>
      </NegativeMobile>
    </div>
  );
};

TopicsGallery.propTypes = {
  /** Список топиков для отображения */
  content: PropTypes.array.isRequired,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

TopicsGallery.defaultProps = {
  content: [],
};

const TopicsGalleryWithHOCs = withBreakpoint(withTheme(TopicsGallery));
TopicsGalleryWithHOCs.displayName = 'TopicsGallery';

export default TopicsGalleryWithHOCs;
export { TopicsGallery as StorybookComponent };

