import BaseApi from 'core/api/BaseApi';


export default class Wingnut extends BaseApi {
  constructor(options) {
    super(options);
    this.client.defaults.headers['Content-Type'] = 'application/json';
    this.client.interceptors.response.use(this.getData);
  }

  get accessToken() {
    return this._accessToken;
  }

  set accessToken(token) {
    this._accessToken = token;
  }

  setAuthorizationHeader = (options = {}) => {
    if (!this.accessToken) {
      console.error(
        '[Wingnut API] Cannot set \'Authorization\' header: set accessToken value on my instance before using such requests'
      );
    }
    const { headers = {} } = options;
    headers.Authorization = this.accessToken;
    return { ...options, headers };
  };


  getProfile = () => {
    return this.client
      .get(
        '/profile',
        this.setAuthorizationHeader()
      );
  };

  updateProfile = data => {
    return this.client
      .put(
        '/profile',
        data,
        this.setAuthorizationHeader(),
      );
  };

  login = data => {
    return this.client
      .post(
        '/auth/login',
        data
      );
  };

  logout = () => {
    return this.client
      .post(
        '/auth/logout',
        {},
        this.setAuthorizationHeader()
      );
  };

  /**
   * Аутентификация пользователя по refreshToken.
   * Токен хранится в httpOnly куке.
   */
  jwtRefresh = () => {
    return this.client
      .post(
        '/auth/jwt_refresh',
        {},
        this.setAuthorizationHeader()
      );
  };

  createAccount = data => {
    return this.client
      .post(
        '/auth/create_account',
        data
      );
  };

  changePassword = data => {
    return this.client
      .post(
        '/auth/change_password',
        data,
        this.setAuthorizationHeader()
      );
  };

  changeLogin = data => {
    return this.client
      .post(
        '/auth/change_login',
        data,
        this.setAuthorizationHeader()
      );
  };

  verifyLoginChange = key => {
    return this.client
      .post(
        '/auth/verify_login_change',
        {},
        { params: { key } }
      );
  };

  verifyAccount = key => {
    return this.client
      .post(
        '/auth/verify_account',
        {},
        { params: { key } }
      );
  };

  verifyPhone = data => {
    return this.client
      .post(
        '/auth/verify_phone',
        data
      );
  };

  verifyPhoneResend = data => {
    return this.client
      .post(
        '/auth/verify_phone_resend',
        data
      );
  };

  verifyAccountResend = data => {
    return this.client
      .post(
        '/auth/verify_account_resend',
        data
      );
  };

  resetPassword = (data, key) => {
    return this.client
      .post(
        '/auth/reset_password',
        { ...data, key }
      );
  };

  resetPasswordRequest = data => {
    return this.client
      .post(
        '/auth/reset_password_request',
        data
      );
  };

  createSubscription = data => {
    return this.client
      .post(
        '/subscribe',
        data
      );
  };

  verifySubscription = key => {
    return this.client
      .post(
        '/verify_subscription',
        { key }
      );
  };

  createTravelAskSubscription = data => {
    return this.client
      .post(
        '/special_subscriptions/travelask2',
        data
      );
  };

  verifyTravelAskSubscription = data => {
    return this.client
      .post(
        '/special_subscriptions/travelask2_verify',
        data
      );
  };

  resendPhoneTravelAskSubscription = data => {
    return this.client
      .post(
        '/special_subscriptions/travelask2_phone_resend',
        data
      );
  };
}
