import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'core/components/Button';
import Link from 'core/components/Link';
import Checkbox from 'core/components/Checkbox';
import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';

import Input from 'site/components/Input';
import Error from 'site/components/Error';
import { ErrorMessage, FieldName, LinkSmall } from 'site/components/Texts';

import { SUBSCRIPTION_FORM_ID } from 'site/constants';

import Letter from 'site/icons/Letter.svg';
import User from 'site/icons/User.svg';

import styles from './index.styl';

const SubscriptionForm = (props) => {
  const {
    handleInputChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    serverErrorName,
    isInsideNotification,
  } = props;

  const TermsWrapper = isInsideNotification ? LinkSmall : FieldName;

  return (
    <form
      className={cx(styles.form, isInsideNotification && styles.notificationForm)}
      onSubmit={handleSubmit}
      id={isInsideNotification && SUBSCRIPTION_FORM_ID}
    >
      <div className={styles.firstRow}>
        <div className={styles.inputContainer}>
          <Input
            type='email'
            name='email'
            placeholder='Ваш e-mail'
            icon={Letter}
            iconPosition='before'
            onChange={handleInputChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email ? errors.email : ''}
          />
        </div>
        <div className={styles.inputContainer}>
          <Input
            type='text'
            name='first_name'
            placeholder='Ваше имя'
            icon={User}
            iconPosition='before'
            onChange={handleInputChange}
            onBlur={handleBlur}
            value={values.first_name}
            error={touched.first_name && errors.first_name ? errors.first_name : ''}
          />
        </div>
        {!isInsideNotification && (
          <Desktop>
            <Button className={styles.btn} typeAttribute='submit'>Подписаться</Button>
          </Desktop>
        )}
      </div>
      <div className={styles.secondRow}>
        <Checkbox
          name='terms_and_conditions'
          defaultChecked={values.terms_and_conditions}
          value={values.terms_and_conditions}
          onChange={handleInputChange}
          onBlur={handleBlur}
        >
          <TermsWrapper>Подтверждаю&nbsp;
            <Link
              type='secondary'
              target='_blank'
              to='/agreement'
            >
              согласие на обработку персональных данных
            </Link>
          </TermsWrapper>
        </Checkbox>
        <Error error={errors.terms_and_conditions} />
      </div>
      {!isInsideNotification && (
        <Mobile>
          <div className={styles.buttonContainerOnMobile}>
            <Button typeAttribute='submit'>Подписаться</Button>
          </div>
        </Mobile>
      )}
      {serverErrorName && <ErrorMessage className={styles.errorMessage}>{serverErrorName}</ErrorMessage>}
    </form>
  );
};

SubscriptionForm.propTypes = {
  handleBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  serverErrorName: PropTypes.string,
  isInsideNotification: PropTypes.bool,
};

export default withBreakpoint(SubscriptionForm);
